import styled from "styled-components";
import rectangle from "../../imgs/rectangle.svg";

export const Container = styled.main`
  width: 100vw;
  background-image: url(${rectangle});
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  .header__back-button {
    button {
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.darkBlue};
      &:hover {
        background-color: ${({ theme }) => theme.darkBlue};
        color: ${({ theme }) => theme.white};
      }
    }
  }
  main {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h1,
  p {
    color: ${({ theme }) => theme.white};
    margin: 0;
    font-size: 2.5rem;
  }
  h1 {
  }
  p {
    font-size: 12rem;
  }
`;
