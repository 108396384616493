export const htmlBasicoAulas = [
  {
    name: "",
    video: "",
    introduction: "",
    Content: () => {
      return <></>;
    },
  },
  {
    name: "Apresentação do Html - Tags, elementos, linguagem de marcação...",
    video: "https://youtu.be/LHfdel0MgjQ",
    introduction: "Vamos começar o HTML!",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Anatomia e semântica do HTML",
    video: "https://youtu.be/uX-CeY-BIgQ",
    introduction:
      "Nesse vídeo veremos a anatomia, semântica e outras informações preciosas na hora de montar a estrutura da sua página, como aninhamento.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Titulos e cabeçalhos no HTML - Entenda as tags e funcionamentos.",
    video: "https://youtu.be/c-wkc3a_b_Q",
    introduction:
      "Falei sobre as tags títulos e cabeçalhos do HTML. Aprenda sobre h1,h2,h3,h4,h5,h6 e seus devidos usos. Expliquei o momento em que deve usar e porque deve usar cada tag.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Paragrafos e texto no HTML - A melhor de escrever seu codigo.",
    video: "https://youtu.be/b4MH9m0HPps",
    introduction:
      "O que você precisa saber sobre o os paragrafos, textos, aninhamentos no HTML eu falo nesse vídeo, falei sobre diversas tags e como usa-las da forma correta.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Listas ordenadas e não ordenadas no HTML",
    video: "https://youtu.be/gkV8WHDaer0",
    introduction:
      "Nesse vídeo você vai aprender como criar listas, seja ordenadas ou não ordenadas, usando tags como OL, UL e tambem LI.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Links, tag de hyperlink, atributos específicos - Parte 1",
    video: "https://youtu.be/cSDxvNtF7y4",
    introduction:
      "Nesse vídeo mostro para vocês o uso correto da tag de link 'a', e seu principal atributo 'href', você vai aprender como usar a tag de hyperlink e também vários outros atributos.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Links, tag de hyperlink, atributos específicos - Parte 2",
    video: "https://youtu.be/EM3dUEWiC9c",
    introduction:
      "Nesse vídeo mostro para vocês o uso correto da tag de link 'a', e seu principal atributo 'href', você vai aprender como usar a tag de hyperlink e também vários outros atributos.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Como usar mídia da forma correta no HTML",
    video: "https://youtu.be/I2iMsy-1ZLk",
    introduction:
      "Nesse vídeo explico a forma correta de usar as tags no HTML para inserir mídias, você vai aprender a forma semântica e sobre as tags necessárias para isso acontecer.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Tudo sobre como adicionar vídeo em seu site",
    video: "https://youtu.be/YZk88bRiHAg",
    introduction:
      "Você vai aprender a usar a tag vídeo da forma correta, os atributos necessários e também atributos para deixar seu site ainda melhor.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Como colocar Audio no HTML - Tudo sobre a tag de audio",
    video: "https://youtu.be/ti2ciaMl1wc",
    introduction:
      "Nesse vídeo ensino como colocar a tag áudio e fazer funcionar da melhor forma, além de ser correta também. Tudo de forma semântica para seu navegador ser otimizado e tem um site com áudio apresentável.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Site indispensáveis na carreira como desenvolvedor",
    video: "https://youtu.be/VnjFAnHm7F0",
    introduction:
      'Trazendo uma "aula" diferente quero apresentar para vocês 2 sites indispensáveis na carreira como programador.',
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Sites indispensáveis na carreira como programador</h2>
            <ul>
              <li>
                <h3>
                  <a
                    href="https://developer.mozilla.org/en-US/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    MDN Web Docs
                  </a>
                </h3>
                <p>
                  Esse site é top demais para quem desenvolve para a web! Lá tem
                  tudo: documentação, guias, tutoriais e muito mais. É mantido
                  pela Mozilla, galera conhecida por ser fera em código aberto e
                  tecnologias web. Se liga nessa fonte confiável e sempre
                  atualizada!
                </p>
                <p>Por que você precisa disso na sua vida de dev?</p>
                <p>
                  Referência maneira: No MDN Web Docs, você acha tudo sobre
                  HTML, CSS, JavaScript, APIs de navegador e mais. Dá para
                  consultar propriedades, métodos e exemplos de código fácil
                  fácil!
                </p>
                <p>
                  Aprenda com estilo: Tem vários tutoriais irados no MDN, dos
                  básicos aos avançados. Ideal para devs de todas as fases!
                </p>
              </li>
              <li>
                <h3>
                  <a
                    href="https://www.w3schools.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    W3Schools
                  </a>
                </h3>
                <p>
                  Aí, outro site massa para quem é dev web é o W3Schools. A
                  galera ama esse site por causa dos tutoriais práticos, os
                  exemplos de código que você testa na hora e a referência
                  rápida de tecnologias web.
                </p>
                <p>Por que é bom ter o W3Schools na sua lista?</p>
                <p>
                  Mão na massa: Os tutoriais interativos do W3Schools são do
                  incriveis! Você aprende na prática, manja?
                </p>
                <p>
                  Código na mão: Eles têm exemplos de código para tudo! De
                  iniciante a ninja, você acha soluções prontinhas para os
                  problemas mais comuns.
                </p>
                <p>
                  Refresca a memória: Precisa dar um "up" na memória? O
                  W3Schools é rápido e fácil para consultar propriedades,
                  atributos e métodos das tecnologias web.
                </p>
              </li>
            </ul>
            <p>
              Esses sites são essenciais para devs, então se joga e aproveita o
              conteúdo que eles têm para oferecer!
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Estrutura semântica e as tags corretas para um boa pratica",
    video: "https://youtu.be/oLrxJv-915c",
    introduction:
      "Criar uma estrutura HTML semântica e seguir boas práticas é essencial para garantir a acessibilidade, usabilidade e facilidade de manutenção do seu site...",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Estrutura semântica e as tags corretas para um boa pratica</h2>
          <p>
            Criar uma estrutura HTML semântica e seguir boas práticas é
            essencial para garantir a acessibilidade, usabilidade e facilidade
            de manutenção do seu site.
          </p>
          <ol>
            <li>
              <span className="bold">Declaração do Documento</span>: Comece
              sempre com a declaração do tipo de documento (doctype) e do
              conjunto de caracteres a ser utilizado.
            </li>
            <li>
              <span className="bold">Elemento header</span>: O elemento header é
              usado para o cabeçalho do site, geralmente contendo o logotipo,
              menu de navegação e outros elementos relevantes.
            </li>
            <li>
              <span className="bold">Elemento main</span>: O elemento main é
              usado para o conteúdo principal da página.
            </li>
            <li>
              <span className="bold">Elemento section</span>: O elemento section
              é usado para agrupar conteúdos relacionados.
            </li>
            <li>
              <span className="bold">Elemento article</span>: O elemento article
              é usado para representar um conteúdo independente e distribuível,
              como uma postagem de blog.
            </li>
            <li>
              <span className="bold">Elemento aside</span>: O elemento aside é
              usado para conteúdos relacionados, como barras laterais, que não
              fazem parte do fluxo principal do documento.
            </li>
            <li>
              <span className="bold">Elemento footer</span>: O elemento footer é
              usado para o rodapé do site, contendo informações como direitos
              autorais, links úteis etc.
            </li>
            <li>
              <span className="bold">Elemento nav</span>: O elemento nav é usado
              para agrupar links de navegação.
            </li>
            <li>
              <span className="bold">Uso adequado de títulos</span>: Use os
              elementos de cabeçalho h1 a h6 de forma hierárquica para
              estruturar o conteúdo e indicar a importância dos títulos.
            </li>
          </ol>
          <p>
            Lembre-se sempre de manter o código limpo, bem-indentado e utilizar
            nomenclaturas claras e significativas em classes e IDs. Evite o uso
            excessivo de divs e spans não semânticos, pois eles podem dificultar
            a compreensão do código.
          </p>
          <p>
            Essas são apenas algumas das boas práticas para criar uma estrutura
            HTML semântica. O desenvolvimento web é uma área em constante
            evolução, então é sempre bom ficar atualizado com as últimas
            tendências e recomendações.
          </p>
        </section>
      );
    },
  },
  {
    name: "Como incorporar o CSS ao meu arquivo HTML",
    video: "https://youtu.be/erryqTKB7EA",
    introduction:
      "Criar uma estrutura HTML semântica e seguir boas práticas é essencial para garantir a acessibilidade, usabilidade e facilidade de manutenção do seu site...",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Como incorporar o CSS ao meu arquivo HTML</h2>
          <p>
            Existem várias maneiras possíveis para implementar o CSS em uma
            página HTML. Abaixo estão as principais formas de incorporar o CSS:
          </p>
          <ol>
            <li>
              Inline CSS: Essa abordagem é aplicada diretamente nos elementos
              HTML usando o atributo style. É geralmente recomendado evitar o
              uso excessivo de estilos inline, pois pode dificultar a manutenção
              e reutilização do código.
            </li>
            <li>
              CSS Interno: Nesta abordagem, você insere o CSS diretamente na
              seção head do seu HTML, usando a tag style. Essa técnica é útil
              quando você precisa estilizar uma única página sem criar um
              arquivo CSS separado.
            </li>
            <li>
              CSS Externo: Essa é a abordagem mais comum e recomendada. Você
              cria um arquivo CSS separado contendo todas as regras de estilo e
              o vincula ao seu HTML usando a tag link no elemento head. Isso
              permite que o CSS seja reutilizado em várias páginas e facilita a
              manutenção do estilo.
            </li>
          </ol>
        </section>
      );
    },
  },
  {
    name: "Como usar a tag iFrame da forma correta!!!",
    video: "https://youtu.be/MTMGVcNhF4Y",
    introduction:
      "A tag `iframe` em HTML é usada para incorporar um documento HTML, uma página da web ou outro conteúdo dentro de uma página HTML. Ela permite que você exiba conteúdo de outras fontes em uma área específica da sua página. ",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Como usar a tag iFrame da forma correta!!!</h2>
          <p>
            A tag <span className="bold">iframe</span> em HTML é usada para
            incorporar um documento HTML, uma página da web ou outro conteúdo
            dentro de uma página HTML. Ela permite que você exiba conteúdo de
            outras fontes em uma área específica da sua página.
          </p>
          <p>
            Aqui estão os principais atributos que você pode usar com a tag{" "}
            <span className="bold">iframe</span>:
          </p>
          <ul>
            <li>
              <span className="bold">iframe</span>: Este é o atributo
              obrigatório que especifica o URL da página ou conteúdo que você
              deseja incorporar.
            </li>
            <li>
              <span className="bold">width</span>: Define a largura do iframe em
              pixels ou em uma unidade relativa, como porcentagem.
            </li>
            <li>
              <span className="bold">height</span>: Define a altura do iframe em
              pixels ou em uma unidade relativa.
            </li>
            <li>
              <span className="bold">frameborder</span>: Define se uma borda
              deve ser exibida ao redor do iframe. Geralmente, você pode definir
              como "0" para ocultar a borda ou "1" para mostrá-la.
            </li>
            <li>
              <span className="bold">allowfullscreen</span>: Permite que o
              conteúdo do iframe seja exibido em tela cheia quando o usuário
              ativa essa opção.
            </li>
            <li>
              <span className="bold">sandbox</span>: Define um conjunto de
              restrições de segurança para o iframe, limitando o que o conteúdo
              incorporado pode fazer.
            </li>
          </ul>
          <p>
            Lembre-se de que o conteúdo que você está incorporando através do
            <span className="bold">iframe</span> deve permitir a incorporação,
            ou seja, o site de origem deve permitir que seu conteúdo seja
            exibido em um iframe em outro domínio. Caso contrário, você pode
            encontrar restrições de segurança ou o conteúdo pode não ser exibido
            corretamente.
          </p>
          <p>
            <a
              href="https://developer.mozilla.org/pt-BR/docs/Learn/HTML/Multimedia_and_embedding/Other_embedding_technologies"
              target="_blank"
              rel="noreferrer"
            >
              Info completa sobre incorporação de objetos e techs
            </a>
          </p>
          <p>
            <a
              href="https://developer.mozilla.org/pt-BR/docs/Web/HTML/Element/iframe"
              target="_blank"
              rel="noreferrer"
            >
              Tag iframe
            </a>
          </p>
        </section>
      );
    },
  },
  {
    name: "Formulário no HMTL",
    video: "https://youtu.be/-aDeYlopVKQ",
    introduction:
      "Um formulário em HTML é um conjunto de elementos que permite aos usuários inserir e enviar dados para um servidor web. Isso é usado para coletar informações como texto, números, opções selecionadas, entre outros.",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Introdução aos Formulários</h2>
          <p>
            Um formulário em HTML é um conjunto de elementos que permite aos
            usuários inserir e enviar dados para um servidor web. Isso é usado
            para coletar informações como texto, números, opções selecionadas,
            entre outros.
          </p>
          <ul>
            <h2>Elementos Básicos de um Formulário</h2>
            <li>
              <h3>form</h3>
              <p>
                O elemento form é o recipiente principal para todo o conteúdo do
                formulário. Ele envolve todos os outros elementos do formulário.
                O atributo action especifica para onde os dados do formulário
                serão enviados quando o usuário o submeter. O atributo method
                define o método de envio, que pode ser "GET" (para anexar dados
                à URL) ou "POST" (para enviar os dados no corpo da requisição
                HTTP).
              </p>
            </li>
            <li>
              <h3>input</h3>
              <p>
                O elemento input é usado para criar campos interativos nos quais
                os usuários podem inserir dados. O atributo type define o tipo
                de campo, como texto, senha, número, etc.
              </p>
            </li>
            <li>
              <h3>textarea</h3>
              <p>
                O elemento textarea cria uma área de texto multi-linhas onde os
                usuários podem digitar textos mais longos, como comentários ou
                descrições.
              </p>
            </li>
            <li>
              <h3>select e option</h3>
              <p>
                O elemento select é usado para criar listas suspensas. Os
                elementos option dentro de um select representam as opções da
                lista.
              </p>
            </li>
            <li>
              <h3>button</h3>
              <p>
                O elemento button cria um botão clicável que os usuários podem
                usar para enviar o formulário ou executar outras ações.
              </p>
            </li>
            <li>
              <h3>label</h3>
              <p>
                O elemento label é usado para associar um rótulo a um campo de
                entrada, o que melhora a usabilidade para os usuários.
              </p>
            </li>
            <li>
              <h3>Elementos Avançados</h3>
              <p>
                input type="radio" e input type="checkbox": Esses elementos são
                usados para criar botões de opção (radio) e caixas de seleção
                (checkbox), respectivamente.
              </p>
            </li>
            <li>
              <h3>input type="file"</h3>
              <p>
                Esse elemento permite que os usuários selecionem arquivos para
                enviar ao servidor.
              </p>
            </li>
            <li>
              <h3>Atributo "pattern"</h3>
              <p>
                O atributo pattern permite que você especifique uma expressão
                regular para validar o formato dos dados inseridos.
              </p>
            </li>
          </ul>
        </section>
      );
    },
  },
  {
    name: "Tipos de inputs no HTML",
    video: "https://youtu.be/vo0jwiOK9BU",
    introduction:
      "No HTML5, existem vários tipos de inputs que podem ser utilizados para criar formulários interativos e coletar informações dos usuários.",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Tipos de inputs no HTML</h2>
          <p>
            No HTML5, existem vários tipos de inputs que podem ser utilizados
            para criar formulários interativos e coletar informações dos
            usuários. Alguns dos tipos mais comuns de inputs são:
          </p>
          <ol>
            <li>
              <h3>Text</h3>
              <p>
                Permite que os usuários insiram texto. Pode ser usado para
                coletar informações curtas, como nomes, endereços de e-mail e
                mensagens.
              </p>
            </li>
            <li>
              <h3>Password</h3>
              <p>
                Semelhante ao input de texto, mas os caracteres inseridos são
                ocultados, útil para coletar senhas.
              </p>
            </li>
            <li>
              <h3>Email</h3>
              <p>
                Projetado para coletar endereços de e-mail e normalmente inclui
                validação para garantir que o formato do e-mail seja correto.
              </p>
            </li>
            <li>
              <h3>Number</h3>
              <p>
                Usado para coletar números. Pode ser acompanhado por atributos
                como `min`, `max` e `step` para definir limites e incrementos.
              </p>
            </li>
            <li>
              <h3>Checkbox</h3>
              <p>
                Permite que os usuários selecionem uma ou várias opções de um
                conjunto de escolhas.
              </p>
            </li>
            <li>
              <h3>Radio</h3>
              <p>
                Semelhante ao checkbox, mas os usuários podem selecionar apenas
                uma opção de um conjunto de escolhas.
              </p>
            </li>
            <li>
              <h3>Textarea</h3>
              <p>
                Oferece uma área de texto maior para que os usuários insiram
                texto mais longo, como comentários ou descrições.
              </p>
            </li>
            <li>
              <h3>Select</h3>
              <p>
                Cria uma lista suspensa (dropdown) de opções onde os usuários
                podem selecionar uma única escolha.
              </p>
            </li>
            <li>
              <h3>Date/Time</h3>
              <p>Diferentes tipos de inputs para coletar datas e horários.</p>
            </li>
          </ol>
          <p>
            Estes são apenas alguns exemplos dos tipos de inputs mais comuns em
            HTML5. Cada tipo de input tem seus próprios atributos e
            características específicas que podem ser utilizadas para
            personalizar sua aparência e comportamento.
          </p>
        </section>
      );
    },
  },
  {
    name: "Usando Select e Datalist no form",
    video: "https://youtu.be/b7BcLye4jw4",
    introduction:
      "O elemento `datalist` em HTML é uma estrutura que permite criar uma lista pré-definida de opções para serem utilizadas em conjunto com um campo de entrada (`input`). Essa lista de opções oferece sugestões ao usuário conforme ele digita algo no campo de entrada, facilitando a seleção e inserção de dados.",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Usando Select e Datalist no form</h2>
          <p>
            O elemento `datalist` em HTML é uma estrutura que permite criar uma
            lista pré-definida de opções para serem utilizadas em conjunto com
            um campo de entrada (`input`). Essa lista de opções oferece
            sugestões ao usuário conforme ele digita algo no campo de entrada,
            facilitando a seleção e inserção de dados.
          </p>
          <p>Aqui está uma breve descrição do elemento `datalist`:</p>
          <p>
            O elemento `datalist` em HTML é usado para criar uma lista de opções
            que podem ser sugeridas a um usuário enquanto ele preenche um campo
            de entrada. É frequentemente utilizado em conjunto com o elemento
            `input` para criar campos de entrada de texto que possuam um
            conjunto de opções predefinidas. O navegador exibirá as opções do
            `datalist` conforme o usuário digita no campo de entrada, permitindo
            que o usuário escolha uma das opções ou continue digitando
            livremente. Essa funcionalidade é especialmente útil quando se
            deseja simplificar a entrada de dados e melhorar a experiência do
            usuário.
          </p>
          <p>
            Em resumo, o elemento `datalist` é uma ferramenta útil para melhorar
            a usabilidade de formulários web, oferecendo sugestões interativas
            aos usuários enquanto eles inserem informações.
          </p>
        </section>
      );
    },
  },
  {
    name: "Projeto prático - Criando Formulário",
    video: "https://youtu.be/N3IofFsfRkA",
    introduction:
      "O projeto prático em HTML5 consiste em um formulário de pagamento que coleta informações de contato e dados de pagamento.",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Projeto prático - Criando Formulário</h2>
          <p>
            O projeto prático em HTML5 consiste em um formulário de pagamento
            que coleta informações de contato e dados de pagamento.
          </p>
          <ul>
            <li>
              O formulário coleta informações de contato e dados de pagamento.
            </li>
            <li>
              Usa a estrutura básica do HTML5 com as tags `!DOCTYPE html`,
              `html`, `head`, e `body`.
            </li>
            <li>Define o título da página usando a tag `title`.</li>
            <li>Divide o formulário em seções usando a tag `section`.</li>
          </ul>
          <ul>
            <h2>Seção de Informações de Contato:</h2>
            <li>Inclui um título de nível 3 "Contact Information".</li>
            <li>Usa a tag `fieldset` para agrupar elementos relacionados.</li>
            <li>
              Oferece opções de título usando botões de opção (`input
              type="radio"`).
            </li>
            <li>
              Coleta nome, email e senha do usuário usando as tags `label` e
              `input`.
            </li>
          </ul>
          <ul>
            <h2>Seção de Informações de Pagamento:</h2>
            <li>Inclui um título de nível 3 "Payment Information".</li>
            <li>
              Usa `select` para permitir ao usuário escolher o tipo de cartão.
            </li>
            <li>
              Coleta número do cartão e data de validade usando as tags `label`
              e `input`.
            </li>
          </ul>
          <ul>
            <h2>Botão de Envio:</h2>
            <li>Inclui um botão de envio para validar o pagamento.</li>
          </ul>
        </section>
      );
    },
  },
  {
    name: "Projeto pratico - Criando um Quiz",
    video: "https://youtu.be/0mt_rahaH9E",
    introduction: "Um formulário de quiz simples.",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Projeto pratico - Criando um Quiz</h2>
          <p>Um formulário de quiz simples.</p>
          <ol>
            <li>
              <span className="bold">
                Declaração do Tipo de Documento (`!DOCTYPE html`):
              </span>
              Especifica que o documento é um documento HTML5.
            </li>
            <li>
              <span className="bold">HTML (`html`)</span> O elemento raiz do
              documento HTML.
            </li>
            <li>
              <span className="bold">Cabeçalho (`head`):</span> Contém metadados
              e links para recursos externos.
              <ul>
                <li>
                  <span className="bold">
                    Codificação de Caracteres (`meta charset="UTF-8"`):
                  </span>{" "}
                  Define a codificação de caracteres para UTF-8, que é comumente
                  usada para suportar vários caracteres.
                </li>
                <li>
                  <span className="bold">
                    Viewport (`meta name="viewport" content="width=device-width,
                    initial-scale=1.0"`):
                  </span>{" "}
                  Define a escala inicial e a largura da viewport para design
                  responsivo.
                </li>
                <li>
                  <span className="bold">Título (`title`):</span> Define o
                  título da página da web que aparece na barra de título do
                  navegador.
                </li>
              </ul>
            </li>
            <li>
              <span className="bold">Corpo (`body`):</span> Contém o conteúdo
              visível da página da web.
              <ul>
                <li>
                  <span className="bold">Formulário (`form`):</span> Representa
                  um formulário que pode ser enviado para o servidor.
                  <ul>
                    <li>
                      <span className="bold">Título 1 (`h1`):</span> Exibe o
                      título do quiz.
                    </li>
                    <li>
                      <span className="bold">Linha Horizontal (`hr`):</span>{" "}
                      Representa uma quebra temática ou separação.
                    </li>
                    <li>
                      <span className="bold">Lista Ordenada (`ol`):</span>{" "}
                      Contém uma lista de perguntas do quiz.
                      <ul>
                        <li>
                          <span className="bold">Item da Lista (`li`):</span>{" "}
                          Representa um item/pergunta na lista do quiz.
                          <ul>
                            <li>
                              <span className="bold">Título 3 (`h3`):</span>{" "}
                              Exibe o texto da pergunta.
                            </li>
                            <li>
                              <span className="bold">
                                Botões de Rádio (`input type="radio"`):
                              </span>{" "}
                              Representa uma opção de botão de rádio para a
                              pergunta.
                              <ul>
                                <li>
                                  <span className="bold">Atributo `name`:</span>{" "}
                                  Agrupa os botões de rádio dentro da mesma
                                  pergunta.
                                </li>
                                <li>
                                  <span className="bold">Atributo `id`:</span>{" "}
                                  Identifica de forma única cada botão de rádio.
                                </li>
                                <li>
                                  <span className="bold">`label`:</span> Fornece
                                  um rótulo para o botão de rádio.
                                  <ul>
                                    <li>
                                      <span className="bold">
                                        Atributo `for`:
                                      </span>{" "}
                                      Associa o rótulo a um botão de rádio
                                      específico usando o `id` dele.
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <span className="bold">
                    Botão de Envio (`input type="submit"`):
                  </span>{" "}
                  Permite que os usuários enviem suas respostas.
                </li>
              </ul>
            </li>
          </ol>
          <p>
            Em resumo, este projeto HTML cria um quiz sobre HTML com quatro
            perguntas. Cada pergunta possui opções de escolha múltipla
            apresentadas como botões de rádio. Os usuários podem selecionar uma
            opção para cada pergunta e enviar suas respostas usando o botão
            "Enviar" dentro do formulário.
          </p>
        </section>
      );
    },
  },
  {
    name: "Projeto prático - Criando Blog",
    video: "https://youtu.be/JlkAdIr4Ak4",
    introduction: "Um blog simples",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Projeto prático - Criando Blog</h2>
          <p>
            Certamente! Aqui está o resumo do código HTML sem os sinais de maior
            e menor, pronto para ser usado na descrição do vídeo do YouTube:
          </p>
          <p>
            Neste vídeo, vou explicar as principais tags presentes no código
            HTML fornecido:
          </p>
          <ul>
            <li>DOCTYPE html: Define o tipo de documento como HTML5.</li>
            <li>
              html lang="en": A tag html envolve todo o conteúdo da página.
            </li>
            <li>
              head: Contém informações sobre o documento, como metadados e links
              para arquivos externos.
              <ul>
                <li>
                  meta charset="UTF-8": Define a codificação de caracteres da
                  página como UTF-8.
                </li>
                <li>
                  meta name="viewport" content="width=device-width,
                  initial-scale=1.0": Torna a página responsiva em dispositivos
                  móveis.
                </li>
                <li>title: Define o título da página.</li>
              </ul>
            </li>
            <li>body: Contém todo o conteúdo visível da página.</li>
            <li>
              header: Usado para incluir elementos introdutórios, como títulos e
              menus de navegação.
              <ul>
                <li>h1: Define o título principal da página.</li>
                <li>
                  nav: Define uma seção de navegação com links para outras
                  partes do site.
                </li>
              </ul>
            </li>
            <li>
              main: Envolve o conteúdo principal da página.
              <ul>
                <li>h3: Define o título do post.</li>
                <li>
                  section: Cria uma seção para organizar o conteúdo do post.
                </li>
                <li>
                  figure: Usado para incluir uma figura (geralmente uma imagem)
                  com uma legenda.
                </li>
                <li>img: Define uma imagem e seus atributos.</li>
                <li>article: Define o conteúdo do post.</li>
              </ul>
            </li>
            <li>
              hr: Insere uma linha horizontal para separar o conteúdo do post do
              rodapé.
            </li>
            <li>
              footer: Contém informações adicionais, como a data da publicação,
              informações do autor e um breve texto de rodapé.
              <ul>
                <li>
                  p: Define parágrafos com informações adicionais no rodapé.
                </li>
              </ul>
            </li>
          </ul>
        </section>
      );
    },
  },
  {
    name: "Meta tags na prática",
    video: "https://youtu.be/3JYj5kkOaMk",
    introduction:
      'As "meta tags" são elementos HTML usados principalmente para fornecer informações sobre uma página da web. Elas não são visíveis para os usuários que visitam a página, mas são lidas pelos motores de busca e outros serviços que analisam o conteúdo da web.',
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Meta tags na prática</h2>
          <p>
            As "meta tags" são elementos HTML usados principalmente para
            fornecer informações sobre uma página da web. Elas não são visíveis
            para os usuários que visitam a página, mas são lidas pelos motores
            de busca e outros serviços que analisam o conteúdo da web. Aqui
            estão algumas das meta tags mais comuns e como usá-las:
          </p>
          <ol>
            <li>
              <span className="bold">Meta Tag de Título (Title)</span>:
              <p>
                Esta é uma das meta tags mais importantes, pois define o título
                da página exibido na barra de título do navegador e é
                frequentemente usado pelos motores de busca como o título da
                página nos resultados da pesquisa.
              </p>
            </li>
            <li>
              <span className="bold">Meta Tag de Descrição (Description)</span>:
              <p>
                A meta tag de descrição fornece uma breve descrição do conteúdo
                da página. Muitos motores de busca exibem essa descrição nos
                resultados da pesquisa.
              </p>
            </li>
            <li>
              <span className="bold">
                Meta Tag de Palavras-Chave (Keywords)
              </span>
              :
              <p>
                Essa meta tag costumava ser usada para listar palavras-chave
                relacionadas ao conteúdo da página, mas hoje em dia, os motores
                de busca geralmente não a levam muito em consideração.
              </p>
            </li>
            <li>
              <span className="bold">Meta Tag de Autor (Author)</span>:
              <p>
                Esta meta tag pode ser usada para indicar o autor da página.
              </p>
            </li>
            <li>
              <span className="bold">
                Meta Tag de Charset (Codificação de Caracteres)
              </span>
              :<p>Define a codificação de caracteres usada na página.</p>
            </li>
            <li>
              <span className="bold">
                Meta Tag de Redirecionamento (Refresh)
              </span>
              :
              <p>
                Pode ser usada para redirecionar automaticamente o navegador
                para outra página após um determinado período de tempo.
              </p>
            </li>
            <li>
              <span className="bold">Meta Tag de Ícone (Favicon)</span>:
              <p>Define o ícone exibido na guia do navegador.</p>
            </li>
          </ol>
          <p>
            É importante observar que nem todas as meta tags são igualmente
            relevantes para os motores de busca atualmente. Os algoritmos dos
            motores de busca evoluíram ao longo do tempo, e eles agora dependem
            mais do conteúdo real da página e de outros fatores, como a
            qualidade dos links que apontam para a página, do que das meta tags.
            No entanto, ainda é uma boa prática usar meta tags relevantes e
            descritivas para ajudar os motores de busca e os usuários a
            entenderem o conteúdo da sua página.
          </p>
        </section>
      );
    },
  },
  {
    name: "Citações no HTML",
    video: "https://youtu.be/DyEu5vSQDuY",
    introduction:
      "Existe algumas formas de sinalizar citações e seu uso é interessante para aperfeiçoar o nosso HTML",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Citações no HTML</h2>
          <p>Claro, aqui está o mesmo texto sem os sinais de maior e menor:</p>
          <ol>
            <li>
              <p>Citações Curtas (Inline):</p>
              <p>
                As citações curtas são usadas para destacar pequenos trechos de
                texto dentro de um parágrafo ou de um bloco de texto. Você pode
                usar a tag "q" para criar citações curtas. Aqui está um exemplo:
              </p>
              <p>
                A citação curta está contida na tag "q", e o navegador
                geralmente adicionará automaticamente as aspas ao redor do
                texto.
              </p>
            </li>
            <li>
              <p>Citações Longas (Block):</p>
              <p>
                As citações longas são usadas para destacar trechos de texto
                mais extensos, geralmente em um formato de bloco separado. Você
                pode usar a tag "blockquote" para criar citações longas. Aqui
                está um exemplo:
              </p>
              <p>
                A tag "blockquote" é usada para criar uma seção de texto
                destacada, e a tag "cite" é usada para indicar a fonte da
                citação.
              </p>
            </li>
          </ol>
          <p>
            Além disso, você pode usar a tag "cite" em qualquer tipo de citação
            para especificar a fonte. Isso ajuda a indicar claramente de onde a
            citação foi tirada, como mostrado no exemplo acima.
          </p>
          <p>
            Certifique-se de que o conteúdo da citação esteja de acordo com as
            diretrizes de uso de citações e direitos autorais da fonte original,
            e cite corretamente as fontes sempre que usar citações em seu
            conteúdo da web.
          </p>
        </section>
      );
    },
  },
  {
    name: "Comentários no HTML",
    video: "https://youtu.be/_GZeTXLrddA",
    introduction: "Em HTML, você pode adicionar comentários",
    isAvailable: true,
    Content: () => {
      return (
        <section>
          <h2>Comentários no HTML</h2>
          <p>
            Em HTML, você pode adicionar comentários usando a seguinte sintaxe:
          </p>
          <p>&lt;!-- Seu comentário aqui --&gt;</p>
          <p>
            Qualquer texto dentro dos `&lt;!--` e `--&gt;` será tratado como um
            comentário e não será exibido na página da web. Comentários são
            úteis para incluir notas, explicações ou marcar partes do código que
            você deseja temporariamente desativar sem removê-las completamente.
            Por exemplo:
          </p>
          <p>
            ```html
            <br />
            &lt;!-- Este é um comentário explicativo --&gt;
            <br />
            &lt;p&gt;Este é um parágrafo de texto.&lt;/p&gt;
            <br />
            ```
          </p>
          <p>
            Os navegadores ignoram os comentários, então eles não afetam o
            funcionamento da página, mas podem ser úteis para outros
            desenvolvedores que leiam seu código ou para você mesmo quando
            precisar lembrar o propósito de uma parte específica do código.
          </p>
        </section>
      );
    },
  },
  {
    name: "Formatações com as tags de texto",
    video: "https://youtu.be/VCSf9RNrEIQ",
    introduction:
      "No HTML, você pode usar tags para formatar o texto de várias maneiras. Aqui estão algumas das tags mais comuns para formatação de texto",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Formatações com as tags de texto</h2>
            <p>
              No HTML, você pode usar tags para formatar o texto de várias
              maneiras. Aqui estão algumas das tags mais comuns para formatação
              de texto:
            </p>
            <ol>
              <li>
                `&lt;b&gt;`: Negrito - Use `&lt;b&gt; Seu texto aqui &lt;/b&gt;`
                para tornar o texto em negrito.
              </li>
              <li>
                `&lt;i&gt;`: Itálico - Use `&lt;i&gt;Seu texto aqui&lt;/i&gt;`
                para tornar o texto em itálico.
              </li>
              <li>
                `&lt;u&gt;`: Sublinhado - Use `&lt;u&gt;Seu texto
                aqui&lt;/u&gt;` para sublinhar o texto.
              </li>
              <li>
                `&lt;strong&gt;`: Ênfase forte - Semelhante ao negrito, mas com
                uma ênfase mais forte. `&lt;strong&gt;Seu texto
                aqui&lt;/strong&gt;`.
              </li>
              <li>
                `&lt;em&gt;`: Ênfase - Semelhante ao itálico, mas com ênfase.
                `&lt;em&gt;Seu texto aqui&lt;/em&gt;`.
              </li>
              <li>
                `&lt;del&gt;`: Tachado - Use `&lt;del&gt;Seu texto
                aqui&lt;/del&gt;` para aplicar uma linha no meio do texto,
                indicando que está apagado o conteúdo.
              </li>
              <li>
                `&lt;sup&gt;`: Sobrescrito - Use `&lt;sup&gt;Seu texto
                aqui&lt;/sup&gt;` para colocar o texto acima da linha de base.
              </li>
              <li>
                `&lt;sub&gt;`: Subscrito - Use `&lt;sub&gt;Seu texto
                aqui&lt;/sub&gt;` para colocar o texto abaixo da linha de base.
              </li>
            </ol>
          </section>
          <section>
            <h2>Links citados no vídeo</h2>
            <p>
              Referência sobre a tag `&lt;i&gt;` e `&lt;em&gt;` no w3schools:
            </p>
            <ul>
              <li>
                <a
                  href="https://www.w3schools.com/tags/tag_i.asp"
                  target="_blank"
                  rel="noreferrer"
                >
                  `&lt;i&gt;` w3schools
                </a>
              </li>
              <li>
                <a
                  href="https://www.w3schools.com/tags/tag_em.asp"
                  target="_blank"
                  rel="noreferrer"
                >
                  `&lt;em&gt;` w3schools
                </a>
              </li>
            </ul>
          </section>
        </>
      );
    },
  },
  {
    name: "Favicon - Como utilizar essa funcionalidade",
    video: "https://youtu.be/kvbZkuf2AYc",
    introduction:
      "Para adicionar um favicon em uma página HTML, você precisa usar a tag link no elemento head do seu documento HTML. Eis como fazer isso...",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Favicon - Como utilizar essa funcionalidade</h2>
            <p>
              Para adicionar um favicon em uma página HTML, você precisa usar a
              tag link no elemento head do seu documento HTML. Eis como fazer
              isso:
            </p>
            <p>
              <span className="bold">
                &lt;head&gt;
                <br />
                &lt;!-- Outras tags head aqui --&gt;
              </span>
              <br />
              <span className="bold">
                link rel="icon" href="caminho-para-o-seu-favicon.ico"
                type="image/x-icon"
              </span>
              <br />
              <span className="bold">
                link rel="shortcut icon" href="caminho-para-o-seu-favicon.ico"
                type="image/x-icon"
              </span>
              <br />
              <span className="bold">&lt;/head&gt;</span>
            </p>
            <p>
              Certifique-se de substituir "caminho-para-o-seu-favicon.ico" pelo
              caminho relativo ou absoluto para o arquivo de ícone que você
              deseja usar. O ícone deve estar no formato .ico, mas a maioria dos
              navegadores modernos também aceita outros formatos de imagem, como
              .png.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Trechos de código no HTML",
    video: "https://youtu.be/RbhWkr9-lm0",
    introduction: "Tags para trechos de código no HTML",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Trechos de código no HTML</h2>
            <ol>
              <li>
                <span className="bold">pre</span> e{" "}
                <span className="bold">code</span>:
                <ul>
                  <li>
                    <span className="bold">pre</span> (Preformatted Text): Essa
                    tag é usada para exibir texto pré-formatado, como código de
                    programação, preservando espaços em branco e quebras de
                    linha.
                  </li>
                  <li>
                    <span className="bold">code</span> (Code): Esta tag é usada
                    para definir um trecho de texto como código, e pode ser
                    usada dentro de pre ou em outros elementos.
                  </li>
                </ul>
              </li>
              <li>
                <span className="bold">samp</span> (Sample Output):
                <ul>
                  <li>
                    <span className="bold">samp</span> (Sample Output): Essa tag
                    é usada para representar a saída de um programa de
                    computador. Ela pode ser usada para destacar saídas de
                    programas.
                  </li>
                </ul>
              </li>
              <li>
                <span className="bold">kbd</span> (Keyboard Input):
                <ul>
                  <li>
                    <span className="bold">kbd</span> (Keyboard Input): Esta tag
                    é usada para representar entradas do teclado ou comandos que
                    um usuário pode digitar.
                  </li>
                </ul>
              </li>
              <li>
                <span className="bold">var</span> (Variable):
                <ul>
                  <li>
                    <span className="bold">var</span> (Variable): Essa tag é
                    usada para representar variáveis ou valores que podem ser
                    substituídos por valores reais.
                  </li>
                </ul>
              </li>
            </ol>
          </section>
        </>
      );
    },
  },
  {
    name: "Entidades no HTML",
    video: "https://youtu.be/V9qyaG_ojbE",
    introduction:
      "Entender as entidades em HTML é fundamental para criar páginas da web que exibam caracteres especiais, como símbolos, caracteres acentuados e outros que, de outra forma, seriam interpretados como código HTML. As entidades HTML são códigos especiais que representam esses caracteres especiais.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Trechos de código no HTML</h2>
            <p>
              Entender as entidades em HTML é fundamental para criar páginas da
              web que exibam caracteres especiais, como símbolos, caracteres
              acentuados e outros que, de outra forma, seriam interpretados como
              código HTML. As entidades HTML são códigos especiais que
              representam esses caracteres especiais. Aqui estão algumas
              informações essenciais para explicar entidades HTML aos seus
              alunos:
            </p>
            <ol>
              <li>
                <h3>O que são entidades HTML?</h3>
                <p>
                  {" "}
                  Entidades HTML são códigos especiais que representam
                  caracteres especiais ou caracteres reservados em uma página da
                  web. Elas são usadas para que os navegadores interpretem esses
                  caracteres corretamente, em vez de processá-los como parte do
                  código HTML.
                </p>
              </li>
              <li>
                <h3>Quando usar entidades HTML?</h3>
                <p>
                  {" "}
                  As entidades HTML são usadas sempre que você deseja exibir um
                  caractere especial ou um caractere reservado em uma página da
                  web. Por exemplo, você deve usar entidades HTML quando quiser
                  exibir ampersand (&), símbolos como © (copyright) e entre
                  outros.
                </p>
              </li>
              <li>
                <h3>Sintaxe das entidades HTML:</h3>
                <p>As entidades HTML geralmente têm a seguinte sintaxe:</p>
                <code>"&entidade;"</code>
                <p>
                  {" "}
                  Onde "entidade" é o nome da entidade ou o código numérico que
                  representa o caractere especial.
                </p>
              </li>
              <li>
                <h2>Entidades HTML comuns:</h2>
                <ul>
                  <li>
                    <code>{"&amp;"}</code> para representar o símbolo '&'.
                  </li>
                  <li>
                    <code>{"&lt;"}</code> para representar o símbolo 'menor
                    que'.
                  </li>
                  <li>
                    <code>{"&gt;"}</code> para representar o símbolo 'maior
                    que'.
                  </li>
                  <li>
                    <code>{"&quot;"}</code> para representar as aspas duplas
                    (").
                  </li>
                  <li>
                    <code>{"&copy;"}</code> para representar o símbolo de
                    copyright ©.
                  </li>
                  <li>
                    <code>{"&reg;"}</code> para representar o símbolo de marca
                    registrada ®.
                  </li>
                  <li>
                    <code>{"&euro;"}</code> para representar o símbolo do Euro
                    €.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Entidades numéricas:</h3>
                <p>
                  Além das entidades nomeadas, você também pode usar entidades
                  numéricas para representar caracteres especiais com base em
                  seu valor Unicode. Por exemplo:
                </p>
                <ul>
                  <li>
                    <code>{"&#169;"}</code> representa o símbolo de copyright ©.
                  </li>
                  <li>
                    <code>{"&#8364;"}</code> representa o símbolo do Euro €.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              {" "}
              Lembre-se de que as entidades HTML são sensíveis a maiúsculas e
              minúsculas, então <code>{"&COPY;"}</code> e{" "}
              <code>{"&copy;"}</code> não são a mesma coisa.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Símbolos no HTML",
    video: "https://youtu.be/rpXgUvhTfIg",
    introduction:
      "Símbolos caracteres especiais ou caracteres reservados usados para Elas são usadas para que os navegadores interpretem esses caracteres corretamente, em vez de processá-los como parte do código HTML.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Símbolos no HTML</h2>
            <p>
              Símbolos caracteres especiais ou caracteres reservados usados para
              Elas são usadas para que os navegadores interpretem esses
              caracteres corretamente, em vez de processá-los como parte do
              código HTML.
            </p>
            <p>Usando Simbolos HTML:</p>
            <p>
              Os simbolos podem ser inseridos em seu código HTML usando
              entidades HTML especiais. Cada simbolo tem uma entidade HTML
              associada que você pode usar.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Emojis no HTML",
    video: "https://youtu.be/SJLiHhpabqw",
    introduction: "Usando Entidades HTML",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Emojis no HTML</h2>
            <p>
              Os emojis podem ser inseridos em seu código HTML usando entidades
              HTML especiais. Cada emoji tem uma entidade HTML associada que
              você pode usar.
            </p>
            <p>
              Por exemplo, o emoji de um rosto sorridente pode ser adicionado
              usando a seguinte entidade HTML: &#128512;.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Tabelas no HTML - Parte 1",
    video: "https://youtu.be/SGgrD2NxE0o",
    introduction:
      "Tabelas são elementos HTML usados para organizar dados em linhas e colunas. Elas são amplamente utilizadas para exibir informações tabulares, como listas, grids de dados, calendários e muito mais. Aqui está tudo o que você precisa saber sobre tabelas no HTML",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Tabelas no HTML</h2>
            <p>
              Tabelas são elementos HTML usados para organizar dados em linhas e
              colunas. Elas são amplamente utilizadas para exibir informações
              tabulares, como listas, grids de dados, calendários e muito mais.
              Aqui está tudo o que você precisa saber sobre tabelas no HTML:
            </p>
            <h3>Estrutura básica da tabela:</h3>
            <p>
              Uma tabela no HTML é definida dentro do elemento "table". A
              estrutura básica de uma tabela consiste em:
            </p>
            <ul>
              <li>
                <span className="bold">table</span>: O elemento raiz da tabela.
              </li>
              <li>
                <span className="bold">tr</span>: Define uma linha na tabela
                (table row).
              </li>
              <li>
                <span className="bold">th</span>: Define um cabeçalho de coluna.
                Geralmente usado na primeira linha da tabela para rotular as
                colunas.
              </li>
              <li>
                <span className="bold">td</span>: Define uma célula de dados
                (table data). Contém o conteúdo real da tabela.
              </li>
            </ul>
            <h3>Cabeçalhos de coluna:</h3>
            <p>
              Os cabeçalhos de coluna são definidos usando o elemento "th"
              dentro da primeira linha da tabela. Isso ajuda a identificar o
              conteúdo das colunas. Por padrão, o texto nos elementos "th" é
              exibido em negrito e centralizado.
            </p>
            <h3>Estilização de tabelas:</h3>
            <p>
              Você pode estilizar tabelas usando CSS para definir cores, bordas,
              espaçamento e outras propriedades visuais. Classes e IDs podem ser
              usados para direcionar tabelas específicas.
            </p>
            <h3>Mesclando células:</h3>
            <p>
              Você pode mesclar células horizontal e verticalmente usando os
              atributos "rowspan" e "colspan" nos elementos "td" ou "th". Isso é
              útil quando você deseja combinar células para criar cabeçalhos ou
              exibir dados agrupados.
            </p>
            <h3>Atributos importantes:</h3>
            <p>Alguns atributos comuns usados em tabelas incluem:</p>
            <ul>
              <li>
                <span className="bold">border</span>: Define a largura da borda
                da tabela (atributo obsoleto, recomenda-se usar CSS para
                estilização).
              </li>
              <li>
                <span className="bold">cellpadding</span>: Define o espaço
                interno nas células.
              </li>
              <li>
                <span className="bold">cellspacing</span>: Define o espaço entre
                as células.
              </li>
              <li>
                <span className="bold">summary</span>: Fornecer uma descrição
                resumida da tabela para acessibilidade.
              </li>
            </ul>
            <h3>Elementos relacionados:</h3>
            <p>
              Além dos elementos principais mencionados, você pode usar
              elementos como "caption" para adicionar um título à tabela e
              "tfoot" para definir o rodapé da tabela.
            </p>
            <p>
              Lembre-se de que tabelas são frequentemente usadas para apresentar
              dados, mas você também deve considerar a acessibilidade ao usar
              tabelas e garantir que elas sejam compreensíveis por leitores de
              tela e outros dispositivos de assistência. O uso de atributos como
              "scope", "headers", e marcando as tabelas com informações
              semânticas apropriadas é essencial para garantir uma experiência
              acessível.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Tabelas no HTML - Parte 2",
    video: "https://youtu.be/TlTYTp_OWa8",
    introduction:
      "Tabelas são elementos HTML usados para organizar dados em linhas e colunas. Elas são amplamente utilizadas para exibir informações tabulares, como listas, grids de dados, calendários e muito mais. Aqui está tudo o que você precisa saber sobre tabelas no HTML",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Tabelas no HTML</h2>
            <p>
              Tabelas são elementos HTML usados para organizar dados em linhas e
              colunas. Elas são amplamente utilizadas para exibir informações
              tabulares, como listas, grids de dados, calendários e muito mais.
              Aqui está tudo o que você precisa saber sobre tabelas no HTML:
            </p>
            <h3>Estrutura básica da tabela:</h3>
            <p>
              Uma tabela no HTML é definida dentro do elemento "table". A
              estrutura básica de uma tabela consiste em:
            </p>
            <ul>
              <li>
                <span className="bold">table</span>: O elemento raiz da tabela.
              </li>
              <li>
                <span className="bold">tr</span>: Define uma linha na tabela
                (table row).
              </li>
              <li>
                <span className="bold">th</span>: Define um cabeçalho de coluna.
                Geralmente usado na primeira linha da tabela para rotular as
                colunas.
              </li>
              <li>
                <span className="bold">td</span>: Define uma célula de dados
                (table data). Contém o conteúdo real da tabela.
              </li>
            </ul>
            <h3>Cabeçalhos de coluna:</h3>
            <p>
              Os cabeçalhos de coluna são definidos usando o elemento "th"
              dentro da primeira linha da tabela. Isso ajuda a identificar o
              conteúdo das colunas. Por padrão, o texto nos elementos "th" é
              exibido em negrito e centralizado.
            </p>
            <h3>Estilização de tabelas:</h3>
            <p>
              Você pode estilizar tabelas usando CSS para definir cores, bordas,
              espaçamento e outras propriedades visuais. Classes e IDs podem ser
              usados para direcionar tabelas específicas.
            </p>
            <h3>Mesclando células:</h3>
            <p>
              Você pode mesclar células horizontal e verticalmente usando os
              atributos "rowspan" e "colspan" nos elementos "td" ou "th". Isso é
              útil quando você deseja combinar células para criar cabeçalhos ou
              exibir dados agrupados.
            </p>
            <h3>Atributos importantes:</h3>
            <p>Alguns atributos comuns usados em tabelas incluem:</p>
            <ul>
              <li>
                <span className="bold">border</span>: Define a largura da borda
                da tabela (atributo obsoleto, recomenda-se usar CSS para
                estilização).
              </li>
              <li>
                <span className="bold">cellpadding</span>: Define o espaço
                interno nas células.
              </li>
              <li>
                <span className="bold">cellspacing</span>: Define o espaço entre
                as células.
              </li>
              <li>
                <span className="bold">summary</span>: Fornecer uma descrição
                resumida da tabela para acessibilidade.
              </li>
            </ul>
            <h3>Elementos relacionados:</h3>
            <p>
              Além dos elementos principais mencionados, você pode usar
              elementos como "caption" para adicionar um título à tabela e
              "tfoot" para definir o rodapé da tabela.
            </p>
            <p>
              Lembre-se de que tabelas são frequentemente usadas para apresentar
              dados, mas você também deve considerar a acessibilidade ao usar
              tabelas e garantir que elas sejam compreensíveis por leitores de
              tela e outros dispositivos de assistência. O uso de atributos como
              "scope", "headers", e marcando as tabelas com informações
              semânticas apropriadas é essencial para garantir uma experiência
              acessível.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Exercicios de HTML",
    video: "https://youtu.be/SWmzbNMbq38",
    introduction:
      "Segue o link para o site da W3Schools: https://www.w3schools.com/",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Exercicios de HTML</h2>
            <p>Segue o link para o site da W3Schools:</p>
            <a
              href="https://www.w3schools.com/"
              target="_blank"
              rel="noreferrer"
            >
              Clique Aqui
            </a>
          </section>
        </>
      );
    },
  },
];
