//style
import { Container } from "./style";

// components
import { HeaderMenu } from "../header_menu";

export const Header = ({ children, hasPerfil, ...rest }) => {
  return (
    <Container hasPerfil={hasPerfil} {...rest}>
      {children}
      {hasPerfil ? <HeaderMenu /> : <div></div>}
    </Container>
  );
};
