import styled from "styled-components";
import codes from "../../imgs/codes.jpg";

export const Container = styled.div`
  background: linear-gradient(to right, black, 70%, transparent), url(${codes});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .create-account {
    background-color: ${({ theme }) => theme.blue};
    padding: 10px 40px;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
  }
`;

export const FirstSection = styled.section`
  padding: 200px 50px;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    font-family: "Poppins", sans-serif;
    margin: 0;
  }
  p {
    color: ${({ theme }) => theme.white};
    font-size: 1.4rem;
    font-weight: lighter;
    width: 540px;
    margin: 0 0 50px;
  }
`;

export const CourseSection = styled.section`
  padding: 200px 50px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
  p {
    color: ${({ theme }) => theme.blue};
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  h1 {
    color: ${({ theme }) => theme.black};
    font-size: 3rem;
    font-weight: 600;
    margin: 0 0 70px;
    width: 830px;
  }
`;

export const LearnSection = styled.section`
  padding: 200px 50px;
  background-color: ${({ theme }) => theme.white};
  font-family: "Poppins", sans-serif;
  .main-text {
    .main-text__subtitle {
      color: ${({ theme }) => theme.blue};
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
    }
    h1 {
      color: ${({ theme }) => theme.black};
      font-size: 3rem;
      font-weight: 600;
      margin: 0 0;
      width: 830px;
    }
    .main-text__text {
      font-weight: lighter;
    }
  }
`;
