// react
import { useEffect, useState } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import { signOutThunk } from "../../store/modules/user/thunks";

// react router dom
import { useLocation } from "react-router-dom";

// component
import { TopMenuItem } from "../top_menu_item";
import fullLogo from "../../imgs/fullLogo.svg";

// style
import {
  faGraduationCap,
  faPersonRunning,
  faPersonChalkboard,
  faCircleQuestion,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { Container } from "./style";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "../../styles/global";

// utils
import { addInDiscord } from "../../service/util/util";

export const MenuTop = ({ contractible, ...rest }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { program } = useSelector((state) => state.user);
  const [regexCourse, setRegexCourse] = useState(new RegExp(`${program}\/*`));

  useEffect(() => {
    setRegexCourse(new RegExp(`${program}\/*`));
  }, [program]);

  return (
    <Container {...rest}>
      <div>
        <img alt="" src={fullLogo} className="logo" />
      </div>
      <nav>
        <ul>
          {!pathname.match(regexCourse) && (
            <TopMenuItem icon={faPersonChalkboard} to={"/" + program}>
              Curso
            </TopMenuItem>
          )}
          {!pathname.match(/ajuda\/*/) && false && (
            <TopMenuItem icon={faCircleQuestion} to="/ajuda">
              Ajuda
            </TopMenuItem>
          )}
          {pathname.match(/area_do_usuario\/*/) && (
            <TopMenuItem icon={faPersonRunning}>
              <button
                className="head__menu-button"
                onClick={() => dispatch(signOutThunk())}
              >
                Sair
              </button>
            </TopMenuItem>
          )}
        </ul>
      </nav>
    </Container>
  );
};
