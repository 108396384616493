import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.white};
  text-shadow: 1px 1px 1px #000;
  padding: 0.2rem;
  border-radius: 3rem;
  width: 20rem;
  height: 3.2rem;
  display: flex;
  position: relative;
  justify-content: center;
  margin-bottom: 1rem;

  &::after {
    content: "";
    position: absolute;
    border: 2px solid #74e4e5;
    top: -7px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 3rem;
  }

  .card_description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    h3 {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: bold;
    }

    p {
      margin: 0;
      font-size: 0.8rem;
    }
  }
  .card_description_links {
    font-size: 1.1rem;
    position: absolute;
    top: 0.2rem;
    left: 1rem;
    z-index: 10;
    display: flex;
    flex-direction: column;

    #linkedin_icon,
    #github_icon {
      color: #ffffff;
    }

    a:hover {
      cursor: pointer;
      font-size: 1.3rem;
      #linkedin_icon,
      #github_icon {
        color: #1497ff;
      }
    }
  }
  .hidden {
    display: none;
  }
`;
