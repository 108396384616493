import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  label {
    font-weight: bold;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.darkBlue};
  }

  .error {
    color: ${({ theme }) => theme.errorMsg};
    font-size: 0.8rem;
  }
`;

export const InputStyled = styled.input`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  width: 20rem;
  height: 2.5rem;
  color: ${({ theme }) => theme.darkBlue};
  padding: 0 0 0 16px;
  margin: 8px 0;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.blue};
    outline: 1px solid ${({ theme }) => theme.blue};
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }

  &::placeholder {
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }
`;
