import { Container } from "./style";
import { TextInput } from "../text_input";
import { Button } from "../button";

// others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserPassword } from "../../service/userManagement";
import { toast } from "react-toastify";

export const FormPassword = () => {
  const formSchema = yup.object().shape({
    currentPassword: yup.string().required("Senha obrigatória"),
    password: yup
      .string()
      .required("Senha obrigatória")
      .min(8, "Senha deve ter mais de 8 caractéres")
      .matches(/^.*[A-Z]+.*$/, "Senha deve ter pelo menos uma letra maiúscula")
      .matches(/^.*[a-z]+.*$/, "Senha deve ter pelo menos uma letra minúscula")
      .matches(/^.*[$*&@#]+.*$/, "Senha deve ter pelo menos um símbolo")
      .matches(/^.*[0-9]+.*$/, "Senha deve ter pelo menos um número"),
    repeatPassword: yup
      .string()
      .required("Confimação obrigatória")
      .oneOf([yup.ref("password")], "Deve ser igual ao campo Senha"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmitFunction = ({ currentPassword, password }) => {
    updateUserPassword(currentPassword, password)
      .then(() => {
        toast("Senha alterada", { theme: "dark" });
      })
      .catch((error) => {
        console.log(error);
        toast("Falha, tente mais tarde", { theme: "dark" });
      });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmitFunction)}>
        <h2>Alterar Senha</h2>
        <TextInput
          type="password"
          placeholder="Senha atual"
          name="currentPassword"
          register={register}
          errors={errors}
          className="input"
        />
        <TextInput
          type="password"
          placeholder="Nova senha"
          name="password"
          register={register}
          errors={errors}
          className="input"
        />
        <TextInput
          type="password"
          placeholder="Confirmar senha"
          name="repeatPassword"
          register={register}
          errors={errors}
          className="input"
        />
        <Button className="confirm">Confirma</Button>
      </form>
    </Container>
  );
};
