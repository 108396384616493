import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Container,Radio,Rating } from "./style";

export const Rate = () => {
    
  const [rate, setRate] = useState(0);
  return (
    <Container>
      {[...Array(5)].map((item,index) => {
        let givenRating = index + 1;
        return (
            <label key={index} className="star">
                
                <Radio
                type="radio"
                value={givenRating}
                onClick={() => {setRate(givenRating)}}
                />
            
                <Rating>
                
                    <FontAwesomeIcon icon={faStar} 
                    color={
                    givenRating < rate || givenRating === rate
                        ? "#ffd233"
                        : "rgba(255, 210, 51, 0.2)"
                    }
                    />
                </Rating>
            </label>
        );
      })}
    </Container>
  );
};
  
