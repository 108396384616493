// react redux
import { useSelector } from "react-redux";

// components
import { Header } from "../../components/header";
import { PerformanceContainer } from "./style";
import { BackButton } from "../../components/back_button";
import { ModuleNav } from "../../components/module_nav";

// style

import { Container } from "./style";
import { motion } from "framer-motion";
import { containerVariants } from "../../styles/global";

// mocks
import { programs } from "../../service/mocks";

import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, PerformanceContainer);

export const PagPerformance = ({ ...rest }) => {
  const { program } = useSelector((state) => state.user);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header hasPerfil>
            <BackButton />
          </Header>
          <main>
            <h1 className="main__title">Seu desempenho</h1>
            <PerformanceContainer>
              <motion.ul
                variants={containerVariants}
                initial="hidden"
                animate="visible"
              >
                {Object.keys(programs[program].modules).map((moduleKey, i) => (
                  <div class="progress">
                    <li>
                      <ModuleNav
                        key={i}
                        title={`Módulo ${i + 1}`}
                        classes={programs[program].modules[moduleKey].classes}
                      />
                    </li>
                    <div class="progress__current-module">
                      {programs[program].modules[moduleKey].percentage_complete}
                    </div>
                  </div>
                ))}
              </motion.ul>
            </PerformanceContainer>
          </main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
