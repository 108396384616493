export const conceitosGeraisDaProgramacaoAulas = [
  {
    name: "",
    video: "",
    introduction: "",
    Content: () => {
      return <></>;
    },
  },
  {
    name: "História da programação",
    video: "https://youtu.be/7dQlJJ1tr40",
    introduction:
      "Neste cativante vídeo, mergulhamos na rica história da programação, desvendando as origens e a evolução dessa disciplina fundamental que moldou nosso mundo digital.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
];
