import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (container) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.verticalTablet`

                main {
                    h1{
                        font-size: 2rem;
                    }
                    p{
                        font-size: 9rem;
                    }
                }
            `}

      ${media.mobileG`

                main {
                    h1{
                        font-size: 1.5rem;
                    }
                    p{
                        font-size: 7rem;
                    }
                }
            `}
    }
  `;
  return mediaContainer;
};
