// redux
import { useSelector } from "react-redux";

// router dom
import { useParams, Navigate } from "react-router-dom";

// components
import { ModuleNav } from "../../components/module_nav";
import { Header } from "../../components/header";

// style
import { Container } from "./style";
import { motion } from "framer-motion";
import { containerVariants } from "../../styles/global";

// mocks
import { programs } from "../../service/mocks";

//responsibility
import { mediaQueries } from "./media";
import { MenuTop } from "../../components/menu_top";
const MediaContainer = mediaQueries(Container);

export const ModulesDashboard = ({ ...rest }) => {
  const { program } = useSelector((state) => state.user);
  let { courseName } = useParams();

  return program === courseName ? (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Header hasPerfil>
          <MenuTop />
        </Header>
        <Container>
          <h1>Trilha de ensino | {programs[courseName]["title"]}</h1>
          <motion.ul
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            {Object.values(programs[courseName].modules).map(
              (module, index) => (
                <li>
                  <ModuleNav
                    key={index}
                    title={module.title}
                    classes={module.classes}
                    courseName={courseName}
                    moduleName={module.key}
                  />
                </li>
              )
            )}
          </motion.ul>
        </Container>
      </MediaContainer>
    </motion.div>
  ) : (
    program && <Navigate to={"/" + program} replace />
  );
};
