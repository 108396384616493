// router-dom
import { useNavigate } from "react-router-dom";

// icons
import {
  faHtml5,
  faCss3Alt,
  faSquareJs,
  faReact,
  faNode,
  faGitAlt,
  faLinkedinIn,
  faDiscord,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { faLaptopCode } from "@fortawesome/free-solid-svg-icons";

// others
import { htmlBasicoAulas } from "./htmlBasicoAulas";
import { logicaIAulas } from "./logicaIAulas";
import { cssBasicoAulas } from "./cssBasicoAulas";
import { logicaIIAulas } from "./logicaIIAulas";
import { conceitosGeraisDaProgramacaoAulas } from "./conceitosGeraisDaProgramacaoAulas";
import { matematicaDiscretaAulas } from "./matematicaDiscretaAulas";

export const projects = [
  {
    id: "1",
    title: "Criando Formulário",
    course_id: "1",
    icon: faHtml5,
    iconColor: "#E65100",
    textButton: "Ir para projeto",
    description: "Criando um formulário básico",
    level: 1,
    tag: "HTML",
    path: "/curso_fullstack/htmlBasico/18",
  },
  {
    id: "2",
    title: "Criando um Quiz",
    course_id: "1",
    icon: faHtml5,
    iconColor: "#E65100",
    textButton: "Ir para projeto",
    description: "Criando quiz com conceitos de form",
    level: 1,
    tag: "HTML",
    path: "/curso_fullstack/htmlBasico/19",
  },
  {
    id: "3",
    title: "Criando Blog",
    course_id: "1",
    icon: faHtml5,
    iconColor: "#E65100",
    textButton: "Ir para projeto",
    description: "Criando blog simples com HTML básico",
    level: 1,
    tag: "HTML",
    path: "/curso_fullstack/htmlBasico/20",
  },
];

export const programs = {
  curso_fullstack: {
    title: "Curso fullstack",
    modules: {
      htmlBasico: {
        title: "HTML básico",
        icon: faHtml5,
        iconColor: "#E65100",
        textButton: "Iniciar Módulo",
        description:
          "HTML básico consiste em elementos e tags que permitem a criação de uma estrutura simples para uma página web, incluindo texto, imagens e hiperlinks.",
        key: "htmlBasico",
        classes: htmlBasicoAulas,
      },
      logicaI: {
        title: "Lógica I",
        icon: faYoutube,
        iconColor: "red",
        textButton: "Iniciar Módulo",
        description:
          "Treinamento para melhorar raciocínio, pensamento crítico e habilidades de resolução de problemas, com foco em princípios de validade e argumentação.",
        key: "logicaI",
        classes: logicaIAulas,
      },
      cssBasico: {
        title: "CSS básico",
        icon: faCss3Alt,
        iconColor: "#2196F3",
        textButton: "Iniciar Módulo",
        description:
          "Aulas introdutórias de CSS ensinam princípios de formatação, permitindo a personalização da aparência de elementos HTML para melhorar o design das páginas web.",
        key: "cssBasico",
        classes: cssBasicoAulas,
      },
      logicaII: {
        title: "Lógica II",
        icon: faYoutube,
        iconColor: "red",
        textButton: "Iniciar Módulo",
        description:
          "HTML básico consiste em elementos e tags que permitem a criação de uma estrutura simples para uma página web, incluindo texto, imagens e hiperlinks.",
        key: "logicaII",
        classes: logicaIIAulas,
      },
      conceitosGeraisDaProgramacao: {
        title: "Conceitos de programação",
        icon: faLaptopCode,
        iconColor: "#4AF626",
        textButton: "Iniciar Módulo",
        description:
          "Fornece uma visão da evolução da computação, incluindo linguagens de programação, paradigmas, algoritmos e marcos históricos.",
        key: "conceitosGeraisDaProgramacao",
        classes: conceitosGeraisDaProgramacaoAulas,
      },
      matematicaDiscreta: {
        title: "Matemática Discreta",
        icon: faYoutube,
        iconColor: "red",
        textButton: "Iniciar Módulo",
        description:
          "A lógica proposicional clássica é um ramo da lógica que lida com a manipulação e análise de proposições ou declarações simples, representadas por variáveis proposicionais e conectivos lógicos.",
        key: "matematicaDiscreta",
        classes: matematicaDiscretaAulas,
      },
    },
  },
};

// --------------------  TERMOS DE USO --------------------------------

export const termsOfUse = [
  {
    title: "Definições",
    Description: () => {
      return (
        <>
          <li>
            <span>1.1.</span> "Plataforma": Refere-se ao site e serviços
            oferecidos pela SkCoders
          </li>
          <li>
            <span>1.2.</span> "Usuário": Indica qualquer pessoa que acesse ou
            utilize a plataforma SkCoders.
          </li>
          <li>
            <span>1.3.</span> "Conteúdo": Inclui, mas não se limita a, vídeos,
            textos, imagens, materiais didáticos,recursos e quaisquer outros
            materiais disponíveis na plataforma.
          </li>
        </>
      );
    },
  },

  {
    title: "Uso da Plataforma",
    Description: () => {
      return (
        <>
          <li>
            <span>2.1.</span> Acesso e Credenciais: Para utilizar a plataforma
            SkCoders, você deve criar uma conta e fornecer informações precisas
            e atualizadas. As credenciais de acesso são de responsabilidade
            exclusiva do usuário, e este deve mantê-las em sigilo.Qualquer
            atividade realizada em sua conta é de sua responsabilidade.
          </li>
          <li>
            <span>2.2.</span> Uso Adequado: Você concorda em utilizar a
            plataforma SkCoders de forma ética e respeitosa, sem violar leis,
            regulamentos ou direitos de terceiros. Não é permitido utilizar a
            plataforma para qualquer propósito ilegal, fraudulento ou
            prejudicial.
          </li>
          <li>
            <span>2.3.</span> Propriedade Intelectual: Todo o conteúdo
            disponível na plataforma SkCoders é protegido por direitos autorais
            e outras leis de propriedade intelectual. É proibida a reprodução,
            distribuição, venda ou modificação do conteúdo sem a devida
            autorização.
          </li>
        </>
      );
    },
  },

  {
    title: "Privacidade",
    Description: () => {
      return (
        <>
          <li>
            <span>3.1.</span> Coleta de Dados: Ao utilizar a plataforma
            SkCoders, podemos coletar informações pessoais para fornecer nossos
            serviços de forma adequada. As informações coletadas incluem, mas
            não se limitam a, nome, e-mail, informações de pagamento e dados de
            acesso.
          </li>
          <li>
            <span>3.2.</span> Uso de Dados: As informações coletadas são
            utilizadas para melhorar nossos serviços, personalizar conteúdos,
            processar pagamentos e entrar em contato com os usuários quando
            necessário. Seus dados serão tratados com segurança e não serão
            compartilhados com terceiros, exceto quando necessário para o
            cumprimento de obrigações legais.
          </li>
          <li>
            <span>3.3.</span> Cookies e Tecnologias de Rastreamento: Utilizamos
            cookies e outras tecnologias de rastreamento para melhorar a
            experiência do usuário na plataforma SkCoders. Você pode configurar
            seu navegador para bloquear ou eliminar cookies, mas isso pode
            afetar o funcionamento de algumas partes do site.
          </li>
        </>
      );
    },
  },

  {
    title: "Modificações nos Termos",
    Description: () => {
      return (
        <>
          <li>
            <span>4.1.</span> Reserva de Direitos: A SkCoders reserva-se o
            direito de modificar estes Termos de Uso e Privacidade a qualquer
            momento, sem aviso prévio. Recomendamos que você verifique
            periodicamente esta página para estar ciente de eventuais alterações
          </li>
          <li>
            <span>4.2.</span> Aceitação das Modificações: O uso contínuo da
            plataforma após qualquer modificação nos termos constitui sua
            aceitação das mudanças efetuadas.
          </li>
        </>
      );
    },
  },

  {
    title: "Contato",
    Description: () => {
      return (
        <>
          <li>
            <span>5.1.</span> Se tiver alguma dúvida ou preocupação relacionada
            aos Termos de Uso e Privacidade da plataforma SkCoders, entre em
            contato conosco pelo e-mail:suporte@skcoders.com.br
          </li>
        </>
      );
    },
  },

  {
    title: "Responsabilidades do Usuário",
    Description: () => {
      return (
        <>
          <li>
            <span>6.1.</span> Conduta Adequada: Ao utilizar a plataforma
            SkCoders, você concorda em não se envolver em qualquer comportamento
            que possa ser considerado ofensivo, difamatório, discriminatório ou
            prejudicial a outros usuários
          </li>
          <li>
            <span>6.2.</span> Conteúdo do Usuário: Ao enviar ou compartilhar
            qualquer conteúdo na plataforma, você é o único responsável por sua
            veracidade e legalidade. A SkCoders não se responsabiliza por
            qualquer conteúdo fornecido pelos usuários.
          </li>
          <li>
            <span>6.3.</span> Respeito aos Direitos Autorais: Ao compartilhar
            conteúdo na plataforma SkCoders, você declara que possui os direitos
            autorais ou permissão para distribuição daquele material.
          </li>
        </>
      );
    },
  },

  {
    title: " Limitações de Responsabilidade",
    Description: () => {
      return (
        <>
          <li>
            <span>7.1.</span> Conteúdo e Precisão: A SkCoders se esforça para
            fornecer informações precisas e atualizadas na plataforma, mas não
            garante a completude ou a exatidão do conteúdo. O uso do material
            disponível é de sua responsabilidade
          </li>
          <li>
            <span>7.2.</span> Interrupções e Falhas: A plataforma SkCoders pode
            estar sujeita a interrupções temporárias para manutenção ou
            atualizações. A empresa não se responsabiliza por eventuais falhas
            técnicas que possam ocorrer durante o uso da plataforma.
          </li>
        </>
      );
    },
  },

  {
    title: "Encerramento da Conta",
    Description: () => {
      return (
        <>
          <li>
            <span>8.1.</span> Rescisão por Usuário: Você pode encerrar sua conta
            na plataforma SkCoders a qualquer momento, seguindo os procedimentos
            estabelecidos na própria plataforma.
          </li>
          <li>
            <span>8.2.</span> Rescisão por SkCoders: A SkCoders reserva-se o
            direito de encerrar contas de usuários que violem estes Termos de
            Uso e Privacidade ou que apresentem comportamento inadequado ou
            prejudicial.
          </li>
        </>
      );
    },
  },

  {
    title: "Disposições Gerais",
    Description: () => {
      return (
        <>
          <li>
            <span>9.1.</span> Lei Aplicável: Estes Termos de Uso e Privacidade
            são regidos pelas leis vigentes no Brasil.
          </li>
          <li>
            <span>9.2.</span> Jurisdição: Qualquer disputa relacionada a estes
            termos será resolvida no foro da cidade de Curitiba, Estado do
            Paraná, Brasil.
          </li>
          <li>
            <span>9.3.</span> Lei Aplicável: Estes Termos de Uso e Privacidade
            são regidos pelas leis vigentes no Brasil.
          </li>
          <li>
            <span>9.4.</span> Jurisdição: Qualquer disputa relacionada a estes
            termos será resolvida no foro da cidade de Curitiba, Estado do
            Paraná, Brasil.
          </li>
          <li>
            Ao utilizar a plataforma SkCoders, você concorda e aceita todos os
            termos aqui apresentados. Caso não concorde com alguma parte destes
            termos, recomendamos que não continue a utilizar nossos serviços.
          </li>

          <p>
            Agradecemos por escolher a SkCoders e esperamos que você tenha uma
            experiência enriquecedora em nossa plataforma de EAD.
          </p>
          <p>Data da última atualização: 31/07/2023</p>
        </>
      );
    },
  },
];

// ---------------------- TIME --------------------
export const teamSkcoders = [
  {
    id: "1",
    title: "Eliézer Silva",
    description: "CEO Skill Labs/Co-founder SK  Coders",
    linkedin: "https://www.linkedin.com/in/edps/",
    github: "",
    showIcon: "hidden",
  },
  {
    id: "2",
    title: "Iago Vinícius Souza",
    description: "Líder técnico/Head de ensino",
    linkedin: "https://www.linkedin.com/in/iago-vinicius-souza/",
    github: "https://github.com/Iago-pixel",
    showIcon: "show",
  },
  {
    id: "3",
    title: " Aline Rabech",
    description: "UX/UI Design",
    linkedin: "https://www.linkedin.com/in/alinerabech",
    github: "",
    showIcon: "hidden",
  },
  {
    id: "4",
    title: "Alexandre Chaves",
    description: "Desenvolvedor Back-end",
    linkedin: "https://www.linkedin.com/in/alexandredchaves/",
    github: "https://github.com/AlexandreDChaves",
    showIcon: "show",
  },
  {
    id: "5",
    title: "Ana Caroline Oliveira",
    description: "Desenvolvedora Front-end",
    linkedin: "https://www.linkedin.com/in/carolftoliveira/",
    github: "https://github.com/CarolFOliveira",
    showIcon: "show",
  },
  {
    id: "6",
    title: "Angélica Shigematsu",
    description: "Desenvolvedora Back-end",
    linkedin: "https://www.linkedin.com/in/angelica-shigematsu",
    github: "https://github.com/angelica-shigematsu",
    showIcon: "show",
  },
  {
    id: "7",
    title: "Guilherme de Medeiros",
    description: "Desenvolvedor Full-stack",
    linkedin: "https://www.linkedin.com/in/guilhermedemedeiros/",
    github: "https://github.com/medeirosdev",
    showIcon: "show",
  },
  {
    id: "8",
    title: "Gustavo Osorio Franco",
    description: "Desenvolvedor Back-end",
    linkedin: "https://www.linkedin.com/in/gustavoosoriofranco/",
    github: "https://github.com/gustavofranco88 ",
    showIcon: "show",
  },
  {
    id: "9",
    title: "Leidiane Silva",
    description: "UX/UI Design/Desenvolvedora Front-end",
    linkedin: "https://www.linkedin.com/in/leidiane-silva-a5b11136/",
    github: "https://github.com/Profleide/",
    showIcon: "show",
  },
  {
    id: "10",
    title: "Lucas Araujo de Paulus",
    description: "Desenvolvedor Front-end",
    linkedin: "https://www.linkedin.com/in/lucas-paulus-dev/",
    github: "https://github.com/lucaspaulus",
    showIcon: "show",
  },
  {
    id: "11",
    title: "Willian Siqueira Nunes",
    description: "Desenvolvedor Back-end",
    linkedin: "https://www.linkedin.com/in/willian-siqueira-nunes/",
    github: "https://github.com/williansiqueiranunes",
    showIcon: "show",
  },
];
