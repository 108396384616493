import { Container } from "./style";
import { itemVariants } from "../../styles/global";
import { motion } from "framer-motion";

export const CheckboxInput = ({
  name,
  children,
  register,
  errors,
  checked,
  onChange,
  ...rest
}) => {
  return (
    <motion.div variants={itemVariants} {...rest}>
      <Container errorsName={errors[name]}>
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
          {...register(name)}
        />
        <label htmlFor={name}>{children}</label>
        <span className="error">{errors[name]?.message}</span>
      </Container>
    </motion.div>
  );
};
