import styled from "styled-components";
import { media } from "../../service/util/util";
import { theme } from "../../styles/global";

export const mediaQueries = (container, inputStyled) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.tablet`
        .input-box {
          height: 2rem;
        }
        button.btn-eye {
          right: 0.5rem;
          top: 0.3rem;
          width: 2rem;
          height: 1.5rem;
          color: ${theme.lightGray};
        }
        .background {
          display: none;
        }
        ${inputStyled} {
          color: ${theme.lightGray};
          &::-webkit-input-placeholder {
            /* Edge */
            color: ${theme.lightGray};
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: ${theme.lightGray};
          }

          &::placeholder {
            color: ${theme.lightGray};
          }
        }
      `}
    }
  `;
  return mediaContainer;
};
