import styled, { keyframes } from "styled-components";
import { media } from "../../service/util/util";
const moveDown = keyframes`
  from {
    transform: translateY(-2rem)
  }
  to {
    transform: translateY(0)
  }
`;

export const mediaQueries = (container) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
        .main_cards{
          grid-template-columns:1fr 1fr;
          justify-items: center;
          margin-left:1rem;
        }
      `}
      ${media.tablet`
            padding:0 1rem;
            animation: ${moveDown} 1s linear;
                main{
                    width:100%;
                    padding:0;
                }
                header{
                    padding:0 1rem;
                }
                .main_cards{
                  grid-template-columns:1fr 1fr;
                  justify-items: center;
                  margin-left:1rem;
                }
                .main_text{
                  font-size:1.2rem;
                }
                
            
            `}
      ${media.mobile`
                padding:1rem;
                animation: ${moveDown} 1s linear;
                main{
                    width:100%;
                    padding:0;
                }
                header{
                    padding:0 1rem;
                }
           
       `}
    }
  `;
  return mediaContainer;
};
