import { createGlobalStyle } from "styled-components";

export const theme = {
  lighterBlue: "#F1F5F9",
  lightGray: "#E2E8F0",
  gray: "#94a3b8",
  darkBlue: "#1e293b",
  lightBlue: "#eff6ff",
  blue: "#2563eb",
  white: "rgb(255, 255, 255)",
  ice: "rgb(240,240,240)",
  boxGray: "#121010",
  black: "rgb(0,0,0)",
  darkGray: "rgb(50, 50, 50)",
  defaultBorderRadius: "7px",
  errorMsg: "red",
  title: {
    fontSize: "1.5rem",
  },
};

export const containerVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0,
      staggerChildren: 0.1,
    },
  },
};

export const itemVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

export const GlobalStyle = createGlobalStyle`
  :root {
    --toastify-color-progress-dark: linear-gradient(
      to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55
    );
  }
  html {
    scroll-behavior: smooth;
  }
  body {
      padding: 0;
      margin: 0 auto;
      max-width: 1440px;
      height: 100vh;
      background-color: ${theme.darkBlue};
      position: relative;
      overflow-x: hidden;
  }

  .logo {
    height: 45px;
    width: auto;
    @media only screen and (max-width: 480px) {
      height: 35px;
    }
  }

  figure {
      margin: 0;
  }

  a {
    text-decoration: none;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${theme.blue};
    border-radius: ${theme.defaultBorderRadius};
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${theme.white}; 
  }
`;
