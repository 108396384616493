import { SIGN_IN, SIGN_OUT, SET_USER, SET_IS_LOADING } from "./actionsTypes";

export const signIn = (token) => ({
  type: SIGN_IN,
  token,
});

export const signOut = () => ({
  type: SIGN_OUT,
});

export const setUser = (userData) => ({
  type: SET_USER,
  userData,
});

export const setUserIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  isLoading,
});
