import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0 0 4rem;

  main {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    color: ${({ theme }) => theme.white};
    font-size: 2.5rem;
  }
  .main__title {
    padding: 0 0 0 1rem;
  }
`;

export const PerformanceContainer = styled.section`
  margin: 0;
  padding: 1rem 3rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.blue};
  width: fit-content;

  .progress {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .progress li {
    list-style: none;
  }

  .progress__current-module {
    height: 1.2rem;
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    width: 3rem;
    padding: 1rem;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    justify-content: center;
  }

  .module-nav__top {
    height: 1.2rem;
  }
  li:hover {
    background-color: #172e2e;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};

    > a {
      color: ${({ theme }) => theme.white};
    }
  }
`;
