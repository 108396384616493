// redux
import { useSelector } from "react-redux";

// router dom
import { Routes, Route, Outlet, Navigate, useParams } from "react-router-dom";

// pages
import { ModulesDashboard } from "../pages/modules_dashboard";
import { Login } from "../pages/login";
import { Register } from "../pages/register";
import { PagLesson } from "../pages/pag_lesson";
import { PagLessonContent } from "../pages/pag_lesson_content";
import { Profile } from "../pages/profile";
import { PagPerformance } from "../pages/pag_performance";
import { PagCertificate } from "../pages/pag_certificate";
import { PagSuport } from "../pages/pag_suport";
import { PasswordRecover } from "../pages/pag_passwordRecover";
import { PasswordReset } from "../pages/pag_passwordReset";
import { SentEmail } from "../pages/pag_sent_email";
import { PagRatingContent } from "../pages/pag_rating_content";
import { PagNotFound } from "../pages/pag_not_found";
import { LandingPage } from "../pages/landing_page";
import { PagFinal } from "../pages/pag_final";
import { PagRating } from "../pages/pag_rating";
import { PagAboutUs } from "../pages/pag_aboutUs";
import { PagDownloads } from "../pages/pag_downloads";
import { StudentDashboard } from "../pages/student_dashboard";
import { LandingPageCourses } from "../pages/landing_page_courses";

// style
import { AnimatePresence } from "framer-motion";

const PrivateRoute = () => {
  const user = useSelector((state) => state.user);
  const { token } = user;

  if (!!process.env.REACT_APP_NO_LOGIN) {
    return <Outlet />;
  }

  if (!token) {
    return <Navigate to="/entrar" replace />;
  }

  return <Outlet />;
};

const VisitorRoute = () => {
  const { token, program } = useSelector((state) => state.user);

  if (!!process.env.REACT_APP_NO_LOGIN) {
    return <Outlet />;
  }

  if (!token) {
    return <Outlet />;
  }

  if (program) {
    return <Navigate to={"/" + program} replace />;
  }

  return null;
};

const PrivateCourse = () => {
  const { program, token } = useSelector((state) => state.user);
  let { courseName } = useParams();

  return program && token ? (
    program === courseName ? (
      <Outlet />
    ) : (
      <Navigate to={"/" + program} replace />
    )
  ) : null;
};

export const Router = () => {
  const { userIsLoading } = useSelector((state) => state.user);
  if (userIsLoading) {
    return null;
  }

  return (
    <AnimatePresence>
      <Routes>
        <Route element={<PrivateRoute />}>
          <Route path="/:courseName" element={<PrivateCourse />}>
            <Route path="/:courseName" element={<ModulesDashboard />} />
            <Route
              path="/:courseName/:moduleName/:lessonIndex"
              element={<PagLesson />}
            />
            <Route
              path="/:courseName/:moduleName/:lessonIndex/conteudo"
              element={<PagLessonContent />}
            />
          </Route>
          <Route path="/area_do_usuario" element={<StudentDashboard />} />
          <Route path="/perfil" element={<Profile />} />
          <Route path="/desempenho" element={<PagPerformance />} />
          <Route path="/certificado" element={<PagCertificate />} />
          <Route path="/ajuda" element={<PagSuport />} />
          <Route path="/comentario" element={<PagRating />} />
          <Route path="/avaliar_conteudo" element={<PagRatingContent />} />
          <Route path="/downloads" element={<PagDownloads />} />
        </Route>
        <Route element={<VisitorRoute />}>
          <Route index element={<LandingPage />} />
          <Route path="/entrar" element={<Login />} />
          <Route path="/cadastro" element={<Register />} />
          <Route path="/email_enviado" element={<SentEmail />} />
          <Route path="/recuperar_senha" element={<PasswordRecover />} />
          <Route path="/redefinir_senha" element={<PasswordReset />} />
          <Route path="/final" element={<PagFinal />} />
          <Route path="/cursos" element={<LandingPageCourses />} />
          <Route path="/sobre_nos" element={<PagAboutUs />} />
        </Route>
        <Route path="*" element={<PagNotFound />} />
      </Routes>
    </AnimatePresence>
  );
};
