import { motion } from "framer-motion";
import { Container } from "./style";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

export const HeaderMenuItem = ({ children, selected, ...rest }) => {
  return (
    <motion.li variants={itemVariants} {...rest}>
      <Container selected={selected}>{children}</Container>
    </motion.li>
  );
};
