// style
import { Container, InputStyled } from "./style";
import { motion } from "framer-motion";
import { itemVariants } from "../../styles/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

// responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, InputStyled);

export const PasswordInput = ({
  title,
  name,
  register,
  errors,
  placeholder,
  isVisible,
  toggleOpen,
  ...rest
}) => {
  return (
    <MediaContainer>
      <motion.div variants={itemVariants} {...rest}>
        <Container errorsName={errors[name]}>
          <label for={name}>{title}</label>
          <div className="input-box">
            <InputStyled
              id={title}
              name={name}
              placeholder={placeholder}
              isVisible={isVisible}
              type={isVisible ? "text" : "password"}
              {...register(name)}
            />
            <button
              type="button"
              onClick={() => toggleOpen()}
              className="btn-eye"
            >
              {isVisible ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEyeSlash} />
              )}
            </button>
          </div>
          <span className="error">{errors[name]?.message}</span>
        </Container>
      </motion.div>
    </MediaContainer>
  );
};
