import styled from "styled-components";

export const Container = styled.section`
  background-color: transparent;
  max-width: 26rem;
  width: 30vw;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  h1 {
    padding-left: 1rem;
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.title.fontSize};
  }
  ul {
    padding: 0;
  }
`;

export const ItemContainer = styled.li`
  border-radius: 7px;
  padding: 50px 25px;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme, selected }) =>
    selected ? theme.boxGray : "transparent"};
  display: flex;
  align-items: center;
  .thumb-box {
    background-color: ${({ theme }) => theme.lightGray};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    height: 80px;
    width: auto;
    margin-right: 20px;
  }
  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 80px;
    p {
      color: ${({ theme }) => theme.white};
      margin: 0;
    }
    a {
      color: ${({ theme }) => theme.blue};
    }
  }
`;
