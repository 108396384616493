import styled from "styled-components";

export const Container = styled.div`
  padding: 0;
  main {
    padding: 0 1rem;
    display: flex;
    justify-content: center;
  }
  h1 {
    color: ${({ theme }) => theme.white};
    font-size: 2.3rem;
    margin: 1rem 0 1.5rem;
    padding-left: 1rem;
  }
  p {
    color: ${({ theme }) => theme.white};
    font-size: 1.1rem;
    margin: 3rem 0 4rem;
    padding-left: 1rem;
  }
`;
export const RecoverContent = styled.section`
  padding: 2rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.darkGray};
  height: 25rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
