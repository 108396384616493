import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  main {
    display: flex;
  }
`;

export const TextContainer = styled.section`
  padding: 50px;
  width: calc(50vw - 100px);
  height: calc(100vh - 100px);
  border-radius: 0 15px 15px 0;
  background-color: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    margin: 0 0 25px;
  }
  p {
    margin: 10px 0 25px;
  }
`;

export const FormContainer = styled.section`
  padding: 40px 90px;
  width: calc(50vw - 180px);
  height: calc(100vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
  .steps {
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    display: flex;
    .bar {
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      height: 10px;
      width: 25%;
    }
    .step1-bar {
      background-color: ${({ theme, step }) =>
        step > 0 ? theme.darkBlue : theme.gray};
      transition: all 0.2s ease-out 100ms;
    }
    .step2-bar {
      background-color: ${({ theme, step }) =>
        step > 1 ? theme.darkBlue : theme.gray};
      transition: all 0.2s ease-out 100ms;
    }
    .step3-bar {
      background-color: ${({ theme, step }) =>
        step > 2 ? theme.darkBlue : theme.gray};
      transition: all 0.2s ease-out 100ms;
    }
    .step4-bar {
      background-color: ${({ theme, step }) =>
        step > 3 ? theme.darkBlue : theme.gray};
      transition: all 0.5s ease-out 100ms;
    }
  }

  .logo {
    height: 1.7rem;
    width: auto;
  }
  h1 {
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 550;
    margin: 10px 0 0;
    color: ${({ theme }) => theme.darkBlue};
  }
  .form-container__subtitle {
    margin: 5px 0 25px;
    color: ${({ theme }) => theme.gray};
  }
  input {
    width: 100%;
    box-sizing: border-box;
  }
  .btn-submit {
    width: 100%;
    height: 2.5rem;
    margin: 30px 0 0;
    button {
      width: 100%;
      height: 100%;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .toLogin {
    text-align: center;
    width: calc(100% + 16px);
  }
  .toLogin__question {
    margin: 15px 0 5px;
    color: ${({ theme }) => theme.blue};
  }
  .toLogin__link {
    color: ${({ theme }) => theme.darkBlue};
    text-decoration: underline;
  }
  .need-computer-box {
    display: flex;
    margin: 0 0 15px;
  }
  .terms_of_use {
    margin: 30px 0 15px;
    button {
      border: none;
      background-color: transparent;
      color: ${({ theme }) => theme.darkBlue};
      margin: 0%;
      padding: 0;
      font-size: 1rem;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .phone-box {
    display: flex;
    justify-content: space-between;
    .ddi {
      width: 45%;
    }
    .phone {
      width: 45%;
    }
  }
`;

export const FieldsetStyled = styled.fieldset`
  border: 1px solid
    ${({ theme, fieldsetError }) => (fieldsetError ? "red" : theme.gray)};
  border-radius: ${({ theme, fieldsetError }) =>
    fieldsetError ? "red" : theme.defaultBorderRadius};

  legend {
    color: ${({ theme, fieldsetError }) =>
      fieldsetError ? "red" : theme.darkBlue};
  }

  .fieldset-options {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .no-free {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    label {
      margin-right: 5px;
    }
  }

  .visitor-option,
  .recommendation-option,
  .is-free-option,
  .formation-option {
    margin-bottom: 16px;
  }
`;

export const ContentModel = styled.section`
  padding: 2rem;
  max-height: 50vh;
  overflow-y: scroll;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.white};

  .content_description {
    color: ${({ theme }) => theme.darkBlue};
    font-size: 1rem;
    text-align: justify;
    word-spacing: 0.1rem;
    list-style: none;
    font-weight: lighter;
    line-height: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    span {
      font-weight: bolder;
    }
  }

  .content_title {
    color: ${({ theme }) => theme.darkBlue};
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

export const ButtonGroups = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 30px 0 0;
  .back-button {
    height: 2.5rem;
    width: 45%;
    button {
      background-color: ${({ theme }) => theme.gray};
      width: 100%;
      height: 100%;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .btn-submit {
    width: 45%;
    margin: 0;
  }
`;

export const PasswordRequirements = styled.div`
  ul {
    margin: 0.5rem 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  li {
    color: red;
    padding: 8px 16px;
    width: 90px;
  }
  .password-box__min {
    text-decoration: ${({ currentPassword }) =>
      currentPassword.length >= 8 ? "line-through" : "none"};
    color: ${({ currentPassword }) =>
      currentPassword.length >= 8 ? "#676767" : "red"};
  }
  .password-box__uppercase {
    text-decoration: ${({ currentPassword }) =>
      currentPassword.match(/[A-Z]/) ? "line-through" : "none"};
    color: ${({ currentPassword }) =>
      currentPassword.match(/[A-Z]/) ? "#676767" : "red"};
  }
  .password-box__lowercase {
    text-decoration: ${({ currentPassword }) =>
      currentPassword.match(/[a-z]/) ? "line-through" : "none"};
    color: ${({ currentPassword }) =>
      currentPassword.match(/[a-z]/) ? "#676767" : "red"};
  }
  .password-box__symbol {
    text-decoration: ${({ currentPassword }) =>
      currentPassword.match(/[$*&@#]/) ? "line-through" : "none"};
    color: ${({ currentPassword }) =>
      currentPassword.match(/[$*&@#]/) ? "#676767" : "red"};
  }
  .password-box__number {
    text-decoration: ${({ currentPassword }) =>
      currentPassword.match(/[0-9]/) ? "line-through" : "none"};
    color: ${({ currentPassword }) =>
      currentPassword.match(/[0-9]/) ? "#676767" : "red"};
  }
`;
