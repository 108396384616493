// router dom
import { useNavigate, Link } from "react-router-dom";

// style
import { Container, MainText } from "./style";
import { motion } from "framer-motion";

// components
import { Header } from "../../components/header";
import { Button } from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

// images
import logo from "../../imgs/logo.webp";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, MainText);

export const PagFinal = ({ ...rest }) => {
  const navigate = useNavigate();

  const handleConcluirClick = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header>
            <Link to="/">
              <img
                src={logo}
                alt=""
                className="logo"
                width="244px"
                height="72px"
              />
            </Link>
          </Header>
          <main>
            <MainText>
              <div className="access-the-community">
                <h1>
                  Acesse nossa comunidade no discord e aguarde o inicio do
                  programa.
                </h1>
                <div className="redes-sociais">
                  <a href="https://discord.gg/xgm8268feH" target="blank">
                    <FontAwesomeIcon icon={faDiscord} />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/skcoders/"
                    target="blank"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>

                <p className="information">
                  Em breve você poderá logar em nossa plataforma e ter acesso ao
                  conteúdo. Até lá nos companhe nas redes sociais!
                </p>
              </div>
              <p className="start-of-classes">
                Início das aulas <span className="cyan">dia 31/07</span>
              </p>

              <Button
                size={0}
                type={0}
                className="button"
                onClick={handleConcluirClick}
              >
                Concluir
              </Button>
            </MainText>
          </main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
