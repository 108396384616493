export const skillsList = [
  { name: "Ada", value: "Ada" },
  { name: "Administrador de sistema", value: "Administrador de sistema" },
  { name: "AdonisJS", value: "AdonisJS" },
  { name: "ADVPL", value: "ADVPL" },
  { name: "ADVPL ASP", value: "ADVPL ASP" },
  { name: "Agavi", value: "Agavi" },
  { name: "Agda", value: "Agda" },
  { name: "AgilePHP", value: "AgilePHP" },
  { name: "AGS Script", value: "AGS Script" },
  { name: "Ajax", value: "Ajax" },
  { name: "Akelos", value: "Akelos" },
  { name: "Alloy", value: "Alloy" },
  { name: "Alpine Abuild", value: "Alpine Abuild" },
  { name: "AMPL", value: "AMPL" },
  { name: "Android", value: "Android" },
  { name: "Android Studio", value: "Android Studio" },
  { name: "Angular", value: "Angular" },
  { name: "Ant Build System", value: "Ant Build System" },
  { name: "ANTLR", value: "ANTLR" },
  { name: "Apache", value: "Apache" },
  { name: "Apex", value: "Apex" },
  { name: "API", value: "API" },
  { name: "APL", value: "APL" },
  { name: "Apollo Guidance Computer", value: "Apollo Guidance Computer" },
  { name: "AppleScript", value: "AppleScript" },
  { name: "Arc", value: "Arc" },
  { name: "Arduino", value: "Arduino" },
  { name: "ARKit", value: "ARKit" },
  { name: "AsciiDoc", value: "AsciiDoc" },
  { name: "ASN.1", value: "ASN.1" },
  { name: "ASP", value: "ASP" },
  { name: "AspectJ", value: "AspectJ" },
  { name: "ASP.NET", value: "ASP.NET" },
  { name: "ASP.NET Core", value: "ASP.NET Core" },
  { name: "ASP.NET MVC", value: "ASP.NET MVC" },
  { name: "Assembly", value: "Assembly" },
  { name: "Atomik", value: "Atomik" },
  { name: "ATS", value: "ATS" },
  { name: "Augeas", value: "Augeas" },
  { name: "Aura", value: "Aura" },
  { name: "Aurelia", value: "Aurelia" },
  { name: "AutoHotkey", value: "AutoHotkey" },
  { name: "AutoIt", value: "AutoIt" },
  { name: "Awk", value: "Awk" },
  {
    name: "AWS EC2 (Elastic Compute Cloud)",
    value: "AWS EC2 (Elastic Compute Cloud)",
  },
  {
    name: "AWS RDS (Relational Database Service)",
    value: "AWS RDS (Relational Database Service)",
  },
  { name: "AWS S3", value: "AWS S3" },
  { name: "Azure", value: "Azure" },
  { name: "Backbone.js", value: "Backbone.js" },
  { name: "Backendless", value: "Backendless" },
  { name: "Bash", value: "Bash" },
  { name: "Batchfile", value: "Batchfile" },
  { name: "Beego", value: "Beego" },
  { name: "Befunge", value: "Befunge" },
  { name: "Big Data", value: "Big Data" },
  { name: "Bison", value: "Bison" },
  { name: "BitBake", value: "BitBake" },
  { name: "Blade", value: "Blade" },
  { name: "BlitzBasic", value: "BlitzBasic" },
  { name: "BlitzMax", value: "BlitzMax" },
  { name: "Blockchain", value: "Blockchain" },
  { name: "Bluespec", value: "Bluespec" },
  { name: "Boo", value: "Boo" },
  { name: "Bootstrap", value: "Bootstrap" },
  { name: "Bottle", value: "Bottle" },
  { name: "Brainfuck", value: "Brainfuck" },
  { name: "Brightscript", value: "Brightscript" },
  { name: "Bro", value: "Bro" },
  { name: "Brutos", value: "Brutos" },
  { name: "C", value: "C" },
  { name: "C#", value: "C#" },
  { name: "C++", value: "C++" },
  { name: "C2hs Haskell", value: "C2hs Haskell" },
  { name: "Cairngorm", value: "Cairngorm" },
  { name: "CakePHP", value: "CakePHP" },
  { name: "Camping", value: "Camping" },
  { name: "Cap'n Proto", value: "Cap'n Proto" },
  { name: "Cappuccino", value: "Cappuccino" },
  { name: "CartoCSS", value: "CartoCSS" },
  { name: "Catalyst", value: "Catalyst" },
  { name: "Ceylon", value: "Ceylon" },
  { name: "CGI::Application", value: "CGI::Application" },
  { name: "CGI::Prototype", value: "CGI::Prototype" },
  { name: "Chapel", value: "Chapel" },
  { name: "Charity", value: "Charity" },
  { name: "CherryPy", value: "CherryPy" },
  { name: "ChucK", value: "ChucK" },
  { name: "Circumflex", value: "Circumflex" },
  { name: "Cirru", value: "Cirru" },
  { name: "Clarion", value: "Clarion" },
  { name: "Clean", value: "Clean" },
  { name: "Clean Code", value: "Clean Code" },
  { name: "ClearPress", value: "ClearPress" },
  { name: "Click", value: "Click" },
  { name: "CLIPS", value: "CLIPS" },
  { name: "Clojure", value: "Clojure" },
  { name: "Cloud", value: "Cloud" },
  { name: "CMake", value: "CMake" },
  { name: "C-ObjDump", value: "C-ObjDump" },
  { name: "COBOL", value: "COBOL" },
  { name: "Cocoon", value: "Cocoon" },
  { name: "CodeIgniter", value: "CodeIgniter" },
  { name: "CoffeeScript", value: "CoffeeScript" },
  { name: "ColdFusion", value: "ColdFusion" },
  { name: "ColdFusion CFC", value: "ColdFusion CFC" },
  { name: "COLLADA", value: "COLLADA" },
  { name: "Common Lisp", value: "Common Lisp" },
  { name: "Compojure", value: "Compojure" },
  { name: "Component Pascal", value: "Component Pascal" },
  { name: "CompoundJS", value: "CompoundJS" },
  { name: "Conjure", value: "Conjure" },
  { name: "Continuous Delivery", value: "Continuous Delivery" },
  { name: "Continuous Integration", value: "Continuous Integration" },
  { name: "Cool", value: "Cool" },
  { name: "Coq", value: "Coq" },
  { name: "Cordova", value: "Cordova" },
  { name: "Cpp-ObjDump", value: "Cpp-ObjDump" },
  { name: "Creole", value: "Creole" },
  { name: "Crystal", value: "Crystal" },
  { name: "CSON", value: "CSON" },
  { name: "Csound", value: "Csound" },
  { name: "Csound Document", value: "Csound Document" },
  { name: "Csound Score", value: "Csound Score" },
  { name: "CSS", value: "CSS" },
  { name: "CSV", value: "CSV" },
  { name: "Cuba", value: "Cuba" },
  { name: "Cucumber", value: "Cucumber" },
  { name: "Cuda", value: "Cuda" },
  { name: "Cutelyst", value: "Cutelyst" },
  { name: "Cyclone3", value: "Cyclone3" },
  { name: "Cycript", value: "Cycript" },
  { name: "Cypress.io", value: "Cypress.io" },
  { name: "Cython", value: "Cython" },
  { name: "D", value: "D" },
  { name: "Darcs Patch", value: "Darcs Patch" },
  { name: "Dart", value: "Dart" },
  { name: "Data science", value: "Data science" },
  { name: "DB2", value: "DB2" },
  { name: "Delphi", value: "Delphi" },
  { name: "Demandware", value: "Demandware" },
  { name: "Design Pattern", value: "Design Pattern" },
  { name: "desktop", value: "desktop" },
  { name: "DevOps", value: "DevOps" },
  { name: "Diff", value: "Diff" },
  { name: "DIGITAL Command Language", value: "DIGITAL Command Language" },
  { name: "Django", value: "Django" },
  { name: "DM", value: "DM" },
  { name: "DNS Zone", value: "DNS Zone" },
  { name: "D-ObjDump", value: "D-ObjDump" },
  { name: "Docker", value: "Docker" },
  { name: "Dockerfile", value: "Dockerfile" },
  { name: "Doctrine", value: "Doctrine" },
  { name: "Dogescript", value: "Dogescript" },
  { name: "Dojo", value: "Dojo" },
  { name: "Dropwizard", value: "Dropwizard" },
  { name: "Drupal", value: "Drupal" },
  { name: "DTrace", value: "DTrace" },
  { name: "Durandal", value: "Durandal" },
  { name: "Dylan", value: "Dylan" },
  { name: "DynamoDB", value: "DynamoDB" },
  { name: "E", value: "E" },
  { name: "Eagle", value: "Eagle" },
  { name: "EBNF", value: "EBNF" },
  { name: "eC", value: "eC" },
  { name: "Ecere Projects", value: "Ecere Projects" },
  { name: "echo", value: "echo" },
  { name: "ECL", value: "ECL" },
  { name: "ECLiPSe", value: "ECLiPSe" },
  { name: "edn", value: "edn" },
  { name: "Eiffel", value: "Eiffel" },
  { name: "EJB", value: "EJB" },
  { name: "EJS", value: "EJS" },
  { name: "Elasticsearch", value: "Elasticsearch" },
  { name: "Eliom", value: "Eliom" },
  { name: "Elixir", value: "Elixir" },
  { name: "Elm", value: "Elm" },
  { name: "Emacs Lisp", value: "Emacs Lisp" },
  { name: "Ember.js", value: "Ember.js" },
  { name: "EmberScript", value: "EmberScript" },
  { name: "Entity framework", value: "Entity framework" },
  { name: "EQ", value: "EQ" },
  { name: "Erlang", value: "Erlang" },
  { name: "Erlang Web", value: "Erlang Web" },
  { name: "ErlyWeb", value: "ErlyWeb" },
  { name: "ERP", value: "ERP" },
  { name: "ES6", value: "ES6" },
  { name: "Excel", value: "Excel" },
  { name: "Express", value: "Express" },
  { name: "eXtreme Programming", value: "eXtreme Programming" },
  { name: "F#", value: "F#" },
  { name: "Factor", value: "Factor" },
  { name: "Fancy", value: "Fancy" },
  { name: "Fantom", value: "Fantom" },
  { name: "Feathers", value: "Feathers" },
  { name: "Figma", value: "Figma" },
  { name: "Filebench WML", value: "Filebench WML" },
  { name: "Filterscript", value: "Filterscript" },
  { name: "Firebase", value: "Firebase" },
  { name: "Firebird", value: "Firebird" },
  { name: "fish", value: "fish" },
  { name: "Flask", value: "Flask" },
  { name: "Flatiron", value: "Flatiron" },
  { name: "Flex", value: "Flex" },
  { name: "Flight", value: "Flight" },
  { name: "Flutter", value: "Flutter" },
  { name: "FLUX", value: "FLUX" },
  { name: "Formatted", value: "Formatted" },
  { name: "Forth", value: "Forth" },
  { name: "FORTRAN", value: "FORTRAN" },
  { name: "FreeMarker", value: "FreeMarker" },
  { name: "Frege", value: "Frege" },
  { name: "FuelPHP", value: "FuelPHP" },
  { name: "Game Maker Language", value: "Game Maker Language" },
  { name: "GAMS", value: "GAMS" },
  { name: "Gantry", value: "Gantry" },
  { name: "GAP", value: "GAP" },
  { name: "GAS", value: "GAS" },
  { name: "GCC Machine Description", value: "GCC Machine Description" },
  { name: "G-code", value: "G-code" },
  { name: "GDB", value: "GDB" },
  { name: "GDScript", value: "GDScript" },
  { name: "Genshi", value: "Genshi" },
  { name: "Gentoo Ebuild", value: "Gentoo Ebuild" },
  { name: "Gentoo Eclass", value: "Gentoo Eclass" },
  { name: "Gettext Catalog", value: "Gettext Catalog" },
  { name: "Gin", value: "Gin" },
  { name: "Git", value: "Git" },
  { name: "GLSL", value: "GLSL" },
  { name: "Glyph", value: "Glyph" },
  { name: "GN", value: "GN" },
  { name: "Gnuplot", value: "Gnuplot" },
  { name: "Go", value: "Go" },
  { name: "Golo", value: "Golo" },
  { name: "Google Analytics", value: "Google Analytics" },
  { name: "Google Cloud", value: "Google Cloud" },
  { name: "Google Web Toolkit", value: "Google Web Toolkit" },
  { name: "Gosu", value: "Gosu" },
  { name: "Grace", value: "Grace" },
  { name: "Gradle", value: "Gradle" },
  { name: "Grails", value: "Grails" },
  { name: "Grain", value: "Grain" },
  { name: "Grammatical Framework", value: "Grammatical Framework" },
  { name: "Graph Modeling Language", value: "Graph Modeling Language" },
  { name: "GraphQL", value: "GraphQL" },
  { name: "Graphviz (DOT)", value: "Graphviz (DOT)" },
  { name: "Groff", value: "Groff" },
  { name: "Grok", value: "Grok" },
  { name: "Groovy", value: "Groovy" },
  { name: "Groovy Server Pages", value: "Groovy Server Pages" },
  { name: "Gulp", value: "Gulp" },
  { name: "GWT ", value: "GWT " },
  { name: "Hack", value: "Hack" },
  { name: "Halcyon", value: "Halcyon" },
  { name: "Haml", value: "Haml" },
  { name: "Hanami", value: "Hanami" },
  { name: "Handlebars", value: "Handlebars" },
  { name: "Happstack", value: "Happstack" },
  { name: "Harbour", value: "Harbour" },
  { name: "Haskell", value: "Haskell" },
  { name: "Haxe", value: "Haxe" },
  { name: "HCL", value: "HCL" },
  { name: "Hemlock", value: "Hemlock" },
  { name: "Heroku", value: "Heroku" },
  { name: "Hibernate", value: "Hibernate" },
  { name: "HLSL", value: "HLSL" },
  { name: "Horde", value: "Horde" },
  { name: "HTML", value: "HTML" },
  { name: "HTTP", value: "HTTP" },
  { name: "Hy", value: "Hy" },
  { name: "HybridJava", value: "HybridJava" },
  { name: "Hybris", value: "Hybris" },
  { name: "HydraMVC", value: "HydraMVC" },
  { name: "HyPhy", value: "HyPhy" },
  { name: "IDL", value: "IDL" },
  { name: "Idris", value: "Idris" },
  { name: "IGOR Pro", value: "IGOR Pro" },
  { name: "Inform 7", value: "Inform 7" },
  { name: "INI", value: "INI" },
  { name: "Inno Setup", value: "Inno Setup" },
  { name: "Io", value: "Io" },
  { name: "Ioke", value: "Ioke" },
  { name: "Ionic", value: "Ionic" },
  { name: "iOS", value: "iOS" },
  { name: "IRC log", value: "IRC log" },
  { name: "Ireport", value: "Ireport" },
  { name: "Isabelle", value: "Isabelle" },
  { name: "Isabelle ROOT", value: "Isabelle ROOT" },
  { name: "J", value: "J" },
  { name: "Jade", value: "Jade" },
  { name: "Jasmin", value: "Jasmin" },
  { name: "Jasper", value: "Jasper" },
  { name: "Java", value: "Java" },
  { name: "JavaEE", value: "JavaEE" },
  { name: "JavaScript", value: "JavaScript" },
  { name: "JavaScriptMVC", value: "JavaScriptMVC" },
  { name: "Java Server Pages", value: "Java Server Pages" },
  { name: "Jenkins ", value: "Jenkins " },
  { name: "Jest", value: "Jest" },
  { name: "JFlex", value: "JFlex" },
  { name: "Jifty", value: "Jifty" },
  { name: "jQuery", value: "jQuery" },
  { name: "JSF", value: "JSF" },
  { name: "JSON", value: "JSON" },
  { name: "JSX", value: "JSX" },
  { name: "Julia", value: "Julia" },
  { name: "JUnit", value: "JUnit" },
  { name: "Jupyter Notebook", value: "Jupyter Notebook" },
  { name: "Kanban", value: "Kanban" },
  { name: "KiCad", value: "KiCad" },
  { name: "Kit", value: "Kit" },
  { name: "Koa", value: "Koa" },
  { name: "Kohana", value: "Kohana" },
  { name: "Konstrukt", value: "Konstrukt" },
  { name: "Kotlin", value: "Kotlin" },
  { name: "KRL", value: "KRL" },
  { name: "kubernetes", value: "kubernetes" },
  { name: "KumbiaPHP", value: "KumbiaPHP" },
  { name: "LabVIEW", value: "LabVIEW" },
  { name: "Laravel", value: "Laravel" },
  { name: "Lasso", value: "Lasso" },
  { name: "Latte", value: "Latte" },
  { name: "Lean", value: "Lean" },
  { name: "Lemmachine", value: "Lemmachine" },
  { name: "Less", value: "Less" },
  { name: "Lex", value: "Lex" },
  { name: "LFE", value: "LFE" },
  { name: "Lift", value: "Lift" },
  { name: "LilyPond", value: "LilyPond" },
  { name: "Limbo", value: "Limbo" },
  { name: "Linker Script", value: "Linker Script" },
  { name: "Linux", value: "Linux" },
  { name: "Linux Kernel Module", value: "Linux Kernel Module" },
  { name: "Liquid", value: "Liquid" },
  { name: "LiquidLava", value: "LiquidLava" },
  { name: "Literate Agda", value: "Literate Agda" },
  { name: "Literate CoffeeScript", value: "Literate CoffeeScript" },
  { name: "Literate Haskell", value: "Literate Haskell" },
  { name: "Lithium", value: "Lithium" },
  { name: "LiveScript", value: "LiveScript" },
  { name: "LLVM", value: "LLVM" },
  { name: "Logos", value: "Logos" },
  { name: "Logtalk", value: "Logtalk" },
  { name: "LOLCODE", value: "LOLCODE" },
  { name: "LookML", value: "LookML" },
  { name: "LoomScript", value: "LoomScript" },
  { name: "LSL", value: "LSL" },
  { name: "Lua", value: "Lua" },
  { name: "LUMEN", value: "LUMEN" },
  { name: "M", value: "M" },
  { name: "M4", value: "M4" },
  { name: "M4Sugar", value: "M4Sugar" },
  { name: "Machine learning", value: "Machine learning" },
  { name: "Mack", value: "Mack" },
  { name: "Magento", value: "Magento" },
  { name: "Makefile", value: "Makefile" },
  { name: "Mako", value: "Mako" },
  { name: "MariaDB", value: "MariaDB" },
  { name: "Markdown", value: "Markdown" },
  { name: "Martini", value: "Martini" },
  { name: "Mask", value: "Mask" },
  { name: "Mason", value: "Mason" },
  { name: "Mate", value: "Mate" },
  { name: "Mathematica", value: "Mathematica" },
  { name: "Matlab", value: "Matlab" },
  { name: "Maven POM", value: "Maven POM" },
  { name: "Maveric", value: "Maveric" },
  { name: "Max", value: "Max" },
  { name: "MAXScript", value: "MAXScript" },
  { name: "MediaWiki", value: "MediaWiki" },
  { name: "Merb", value: "Merb" },
  { name: "Mercury", value: "Mercury" },
  { name: "Metal", value: "Metal" },
  { name: "Meteor", value: "Meteor" },
  { name: "MFlow", value: "MFlow" },
  { name: "miku", value: "miku" },
  { name: "MiniD", value: "MiniD" },
  { name: "Mirah", value: "Mirah" },
  { name: "Mithril", value: "Mithril" },
  { name: "Modelica", value: "Modelica" },
  { name: "Modula-2", value: "Modula-2" },
  { name: "Module Management System", value: "Module Management System" },
  { name: "Mojolicious", value: "Mojolicious" },
  { name: "MongoDB", value: "MongoDB" },
  { name: "Monkey", value: "Monkey" },
  { name: "Moocode", value: "Moocode" },
  { name: "MoonScript", value: "MoonScript" },
  { name: "Moustache", value: "Moustache" },
  { name: "MQL4", value: "MQL4" },
  { name: "MQL5", value: "MQL5" },
  { name: "MTML", value: "MTML" },
  { name: "MUF", value: "MUF" },
  { name: "mupad", value: "mupad" },
  { name: "MVC", value: "MVC" },
  { name: "MVVM", value: "MVVM" },
  { name: "Myghty", value: "Myghty" },
  { name: "MySQL", value: "MySQL" },
  { name: "Nancy", value: "Nancy" },
  { name: "NCL", value: "NCL" },
  { name: "Nemerle", value: "Nemerle" },
  { name: "Nerve", value: "Nerve" },
  { name: "nesC", value: "nesC" },
  { name: ".NET", value: ".NET" },
  { name: ".NET Core", value: ".NET Core" },
  { name: "NetLinx", value: "NetLinx" },
  { name: "NetLinx+ERB", value: "NetLinx+ERB" },
  { name: "NetLogo", value: "NetLogo" },
  { name: ".NET MVC", value: ".NET MVC" },
  { name: "NewLisp", value: "NewLisp" },
  { name: "NextJS", value: "NextJS" },
  { name: "Nginx", value: "Nginx" },
  { name: "Nimrod", value: "Nimrod" },
  { name: "Ninja", value: "Ninja" },
  { name: "Nit", value: "Nit" },
  { name: "Nitro", value: "Nitro" },
  { name: "Nix", value: "Nix" },
  { name: "NL", value: "NL" },
  { name: "Node.js", value: "Node.js" },
  { name: "Nodemachine", value: "Nodemachine" },
  { name: "Node-router", value: "Node-router" },
  { name: "Noir", value: "Noir" },
  { name: "NoSQL", value: "NoSQL" },
  { name: "NPM", value: "NPM" },
  { name: "NSIS", value: "NSIS" },
  { name: "Nu", value: "Nu" },
  { name: "NumPy", value: "NumPy" },
  { name: "ObjDump", value: "ObjDump" },
  { name: "Objective-C", value: "Objective-C" },
  { name: "Objective-C++", value: "Objective-C++" },
  { name: "Objective-J", value: "Objective-J" },
  { name: "OCaml", value: "OCaml" },
  { name: "Odoo", value: "Odoo" },
  { name: "Omgrofl", value: "Omgrofl" },
  { name: "Ooc", value: "Ooc" },
  { name: "Opa", value: "Opa" },
  { name: "Opal", value: "Opal" },
  { name: "OpenCL", value: "OpenCL" },
  { name: "OpenCV", value: "OpenCV" },
  { name: "OpenEdge ABL", value: "OpenEdge ABL" },
  { name: "OpenRC runscript", value: "OpenRC runscript" },
  { name: "OpenSCAD", value: "OpenSCAD" },
  { name: "OpenType Feature File", value: "OpenType Feature File" },
  { name: "OpenUI5", value: "OpenUI5" },
  { name: "ORACLE", value: "ORACLE" },
  { name: "Orbit", value: "Orbit" },
  { name: "Org", value: "Org" },
  { name: "Ox", value: "Ox" },
  { name: "Oxygene", value: "Oxygene" },
  { name: "Oz", value: "Oz" },
  { name: "Pan", value: "Pan" },
  { name: "Pandas", value: "Pandas" },
  { name: "Papyrus", value: "Papyrus" },
  { name: "Parrot", value: "Parrot" },
  { name: "Parrot Assembly", value: "Parrot Assembly" },
  {
    name: "Parrot Internal Representation",
    value: "Parrot Internal Representation",
  },
  { name: "Pascal", value: "Pascal" },
  { name: "PAWN", value: "PAWN" },
  { name: "PeopleCode", value: "PeopleCode" },
  { name: "Perfect", value: "Perfect" },
  { name: "Perl", value: "Perl" },
  { name: "Perl6", value: "Perl6" },
  { name: "Phalcon", value: "Phalcon" },
  { name: "Phoenix", value: "Phoenix" },
  { name: "PhoneGap", value: "PhoneGap" },
  { name: "Photoshop", value: "Photoshop" },
  { name: "PHP", value: "PHP" },
  { name: "PHPUnit", value: "PHPUnit" },
  { name: "Pic", value: "Pic" },
  { name: "Picard", value: "Picard" },
  { name: "Pickle", value: "Pickle" },
  { name: "PicoLisp", value: "PicoLisp" },
  { name: "PigLatin", value: "PigLatin" },
  { name: "Pike", value: "Pike" },
  { name: "Play", value: "Play" },
  { name: "PLpgSQL", value: "PLpgSQL" },
  { name: "PLSQL", value: "PLSQL" },
  { name: "Pod", value: "Pod" },
  { name: "PogoScript", value: "PogoScript" },
  { name: "Polymer", value: "Polymer" },
  { name: "Pony", value: "Pony" },
  { name: "POO", value: "POO" },
  { name: "PostgreSQL", value: "PostgreSQL" },
  { name: "PostScript", value: "PostScript" },
  { name: "POV-Ray SDL", value: "POV-Ray SDL" },
  { name: "PowerBuilder", value: "PowerBuilder" },
  { name: "PowerShell", value: "PowerShell" },
  { name: "Prado", value: "Prado" },
  { name: "Processing", value: "Processing" },
  { name: "Progress", value: "Progress" },
  { name: "Prolog", value: "Prolog" },
  { name: "Propeller Spin", value: "Propeller Spin" },
  { name: "Protocol Buffer", value: "Protocol Buffer" },
  { name: "Public Key", value: "Public Key" },
  { name: "Puppet", value: "Puppet" },
  { name: "PureBasic", value: "PureBasic" },
  { name: "Pure Data", value: "Pure Data" },
  { name: "PureMVC", value: "PureMVC" },
  { name: "PureScript", value: "PureScript" },
  { name: "PWA", value: "PWA" },
  { name: "Pyramid", value: "Pyramid" },
  { name: "PyTest", value: "PyTest" },
  { name: "Python", value: "Python" },
  { name: "PyTorch", value: "PyTorch" },
  { name: "QA - Quality Assurance", value: "QA - Quality Assurance" },
  { name: "Qcodo", value: "Qcodo" },
  { name: "QCubed", value: "QCubed" },
  { name: "Qlikview", value: "Qlikview" },
  { name: "QMake", value: "QMake" },
  { name: "QML", value: "QML" },
  { name: "Quasar", value: "Quasar" },
  { name: "Quixote", value: "Quixote" },
  { name: "R", value: "R" },
  { name: "RabbitMQ", value: "RabbitMQ" },
  { name: "Racket", value: "Racket" },
  { name: "Ragel in Ruby Host", value: "Ragel in Ruby Host" },
  { name: "Ramaze", value: "Ramaze" },
  { name: "RAML", value: "RAML" },
  { name: "Rascal", value: "Rascal" },
  { name: "Ratpack", value: "Ratpack" },
  { name: "RavenDB", value: "RavenDB" },
  { name: "Raw token data", value: "Raw token data" },
  { name: "RDoc", value: "RDoc" },
  { name: "ReactJS", value: "ReactJS" },
  { name: "React Native", value: "React Native" },
  { name: "REALbasic", value: "REALbasic" },
  { name: "Rebol", value: "Rebol" },
  { name: "Red", value: "Red" },
  { name: "Redcode", value: "Redcode" },
  { name: "Redis", value: "Redis" },
  { name: "Redux", value: "Redux" },
  { name: "RenderScript", value: "RenderScript" },
  { name: "Ren'Py", value: "Ren'Py" },
  { name: "RESTful", value: "RESTful" },
  { name: "RestfulX", value: "RestfulX" },
  { name: "Restlet", value: "Restlet" },
  { name: "reStructuredText", value: "reStructuredText" },
  { name: "Revel", value: "Revel" },
  { name: "REXX", value: "REXX" },
  { name: "RHTML", value: "RHTML" },
  { name: "Ring", value: "Ring" },
  { name: "Riot.js", value: "Riot.js" },
  { name: "RMarkdown", value: "RMarkdown" },
  { name: "RobotFramework", value: "RobotFramework" },
  { name: "Roma", value: "Roma" },
  { name: "Rouge", value: "Rouge" },
  { name: "RPM Spec", value: "RPM Spec" },
  { name: "Ruby", value: "Ruby" },
  { name: "Ruby on Rails", value: "Ruby on Rails" },
  { name: "Rum", value: "Rum" },
  { name: "RUNOFF", value: "RUNOFF" },
  { name: "Rust", value: "Rust" },
  { name: "Sage", value: "Sage" },
  { name: "Sails.js", value: "Sails.js" },
  { name: "Salesforce", value: "Salesforce" },
  { name: "SaltStack", value: "SaltStack" },
  { name: "Salvia", value: "Salvia" },
  { name: "SAP", value: "SAP" },
  { name: "SAS", value: "SAS" },
  { name: "Sass", value: "Sass" },
  { name: "Scala", value: "Scala" },
  { name: "Scalatra", value: "Scalatra" },
  { name: "Scaml", value: "Scaml" },
  { name: "Scheme", value: "Scheme" },
  { name: "Scikit-learn", value: "Scikit-learn" },
  { name: "Scilab", value: "Scilab" },
  { name: "SCORM", value: "SCORM" },
  { name: "SCRUM", value: "SCRUM" },
  { name: "SCSS", value: "SCSS" },
  { name: "Seagull", value: "Seagull" },
  { name: "seaside", value: "seaside" },
  { name: "Selenium", value: "Selenium" },
  { name: "Self", value: "Self" },
  { name: "SEO", value: "SEO" },
  { name: "servant", value: "servant" },
  { name: "Server", value: "Server" },
  { name: "Serverless", value: "Serverless" },
  { name: "Sharepoint", value: "Sharepoint" },
  { name: "Shell", value: "Shell" },
  { name: "Shell Script", value: "Shell Script" },
  { name: "ShellSession", value: "ShellSession" },
  { name: "Shen", value: "Shen" },
  { name: "SilverStripe Sapphire", value: "SilverStripe Sapphire" },
  { name: "simplex", value: "simplex" },
  { name: "Sin", value: "Sin" },
  { name: "Sinatra", value: "Sinatra" },
  { name: "Slash", value: "Slash" },
  { name: "Slim", value: "Slim" },
  { name: "Smali", value: "Smali" },
  { name: "Smalltalk", value: "Smalltalk" },
  { name: "Smarty", value: "Smarty" },
  { name: "SMT", value: "SMT" },
  { name: "Snap", value: "Snap" },
  { name: "Solar", value: "Solar" },
  { name: "S.O.L.I.D", value: "S.O.L.I.D" },
  { name: "SourcePawn", value: "SourcePawn" },
  { name: "SPARQL", value: "SPARQL" },
  { name: "Spline Font Database", value: "Spline Font Database" },
  { name: "Spring", value: "Spring" },
  { name: "Spring Boot", value: "Spring Boot" },
  { name: "Spring Cloud", value: "Spring Cloud" },
  { name: "Spring MVC", value: "Spring MVC" },
  { name: "SproutCore", value: "SproutCore" },
  { name: "SQF", value: "SQF" },
  { name: "SQL", value: "SQL" },
  { name: "SQLite", value: "SQLite" },
  { name: "SQLPL", value: "SQLPL" },
  { name: "SQL Server", value: "SQL Server" },
  { name: "SQR", value: "SQR" },
  { name: "Squatting", value: "Squatting" },
  { name: "Squirrel", value: "Squirrel" },
  { name: "SRecode Template", value: "SRecode Template" },
  { name: "Stan", value: "Stan" },
  { name: "Standard ML", value: "Standard ML" },
  { name: "Stata", value: "Stata" },
  { name: "STON", value: "STON" },
  { name: "Stripes", value: "Stripes" },
  { name: "Struts", value: "Struts" },
  { name: "Styled-Components", value: "Styled-Components" },
  { name: "Stylus", value: "Stylus" },
  { name: "Sublime Text Config", value: "Sublime Text Config" },
  { name: "SubRip Text", value: "SubRip Text" },
  { name: "SuperCollider", value: "SuperCollider" },
  { name: "SVG", value: "SVG" },
  { name: "sweetscala", value: "sweetscala" },
  { name: "Swift", value: "Swift" },
  { name: "Swiftlet", value: "Swiftlet" },
  { name: "Swiz", value: "Swiz" },
  { name: "Symfony", value: "Symfony" },
  { name: "SystemVerilog", value: "SystemVerilog" },
  { name: "Tapestry", value: "Tapestry" },
  { name: "Tcl", value: "Tcl" },
  { name: "Tcsh", value: "Tcsh" },
  { name: "TDD", value: "TDD" },
  { name: "Tea", value: "Tea" },
  { name: "TensorFlow", value: "TensorFlow" },
  { name: "Terra", value: "Terra" },
  { name: "Testes automatizados", value: "Testes automatizados" },
  { name: "Testes de Regressão", value: "Testes de Regressão" },
  { name: "Testes Funcionais", value: "Testes Funcionais" },
  { name: "Testes unitários", value: "Testes unitários" },
  { name: "TeX", value: "TeX" },
  { name: "Text", value: "Text" },
  { name: "Textile", value: "Textile" },
  { name: "Thrift", value: "Thrift" },
  { name: "Tipfy", value: "Tipfy" },
  { name: "TI Program", value: "TI Program" },
  { name: "TLA", value: "TLA" },
  { name: "Tomcat", value: "Tomcat" },
  { name: "TOML", value: "TOML" },
  { name: "Tornado", value: "Tornado" },
  { name: "T-SQL", value: "T-SQL" },
  { name: "Turbine", value: "Turbine" },
  { name: "TurboGears", value: "TurboGears" },
  { name: "Turing", value: "Turing" },
  { name: "Turtle", value: "Turtle" },
  { name: "Twig", value: "Twig" },
  { name: "TXL", value: "TXL" },
  { name: "TypeScript", value: "TypeScript" },
  { name: "UKI", value: "UKI" },
  { name: "UML", value: "UML" },
  { name: "Unified Parallel C", value: "Unified Parallel C" },
  { name: "Unity", value: "Unity" },
  { name: "Unity3D Asset", value: "Unity3D Asset" },
  { name: "Unix", value: "Unix" },
  { name: "Uno", value: "Uno" },
  { name: "UnrealScript", value: "UnrealScript" },
  { name: "UrWeb", value: "UrWeb" },
  { name: "Vaadin", value: "Vaadin" },
  { name: "Vala", value: "Vala" },
  { name: "Vanilla", value: "Vanilla" },
  { name: "Vapor", value: "Vapor" },
  { name: "VBA", value: "VBA" },
  { name: "VB .NET", value: "VB .NET" },
  { name: "VCL", value: "VCL" },
  { name: "Verilog", value: "Verilog" },
  { name: "Vert.x", value: "Vert.x" },
  { name: "VHDL", value: "VHDL" },
  { name: "VimL", value: "VimL" },
  { name: "Visual Basic", value: "Visual Basic" },
  { name: "Volt", value: "Volt" },
  { name: "Vork", value: "Vork" },
  { name: "Vroom", value: "Vroom" },
  { name: "VTEX", value: "VTEX" },
  { name: "Vue.js", value: "Vue.js" },
  { name: "Wavefront Material", value: "Wavefront Material" },
  { name: "Wavefront Object", value: "Wavefront Object" },
  { name: "Waves", value: "Waves" },
  { name: "web2py", value: "web2py" },
  { name: "Web API", value: "Web API" },
  { name: "WebGUI", value: "WebGUI" },
  { name: "WebIDL", value: "WebIDL" },
  { name: "Webjure", value: "Webjure" },
  { name: "Web Ontology Language", value: "Web Ontology Language" },
  { name: "WebRTC", value: "WebRTC" },
  { name: "Web Services", value: "Web Services" },
  { name: "Wee", value: "Wee" },
  { name: "Wicket", value: "Wicket" },
  { name: "Wisp", value: "Wisp" },
  { name: "WooCommerce", value: "WooCommerce" },
  { name: "WordPress", value: "WordPress" },
  {
    name: "World of Warcraft Addon Data",
    value: "World of Warcraft Addon Data",
  },
  { name: "WPF", value: "WPF" },
  { name: "X10", value: "X10" },
  { name: "Xamarin", value: "Xamarin" },
  { name: "xBase", value: "xBase" },
  { name: "XC", value: "XC" },
  { name: "Xitrum", value: "Xitrum" },
  { name: "XML", value: "XML" },
  { name: "XMPP", value: "XMPP" },
  { name: "Xojo", value: "Xojo" },
  { name: "XPages", value: "XPages" },
  { name: "XProc", value: "XProc" },
  { name: "XQuery", value: "XQuery" },
  { name: "XS", value: "XS" },
  { name: "XSLT", value: "XSLT" },
  { name: "Xtend", value: "Xtend" },
  { name: "Xyster", value: "Xyster" },
  { name: "Yacc", value: "Yacc" },
  { name: "YAML", value: "YAML" },
  { name: "YANG", value: "YANG" },
  { name: "Yesod", value: "Yesod" },
  { name: "Yii", value: "Yii" },
  { name: "Zend", value: "Zend" },
  { name: "Zephir", value: "Zephir" },
  { name: "Zimpl", value: "Zimpl" },
  { name: "ZK", value: "ZK" },
  { name: "Zope", value: "Zope" },
  { name: "Zotonic", value: "Zotonic" },
];
