import { Container } from "./style";
import { TextInput } from "../text_input";
import { Button } from "../button";

// others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateUserPhone } from "../../service/userManagement";
import { toast } from "react-toastify";

export const FormPhone = () => {
  const formSchema = yup.object().shape({
    currentPassword: yup.string().required("Senha obrigatória"),
    phone: yup
      .string()
      .matches(
        /^(?:(?:\+|00)?(55)\s?)?(\(?([1-9][0-9])\)?\s?)((?:9\d|[2-9])\d{3})-?(\d{4})$/,
        "Número inválido"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmitFunction = ({ currentPassword, phone }) => {
    updateUserPhone(currentPassword, phone)
      .then(() => {
        toast("Telefone alterado", { theme: "dark" });
      })
      .catch((error) => {
        console.log(error);
        toast("Falha, tente mais tarde", { theme: "dark" });
      });
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmitFunction)}>
        <h2>Alterar Telefone</h2>
        <TextInput
          type="password"
          placeholder="Senha atual"
          name="currentPassword"
          register={register}
          errors={errors}
          className="input"
        />
        <TextInput
          placeholder="Novo número"
          name="phone"
          register={register}
          errors={errors}
          className="input"
        />
        <Button className="confirm">Confirma</Button>
      </form>
    </Container>
  );
};
