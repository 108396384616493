import styled from "styled-components";

export const Container = styled.div`
  padding: 0;
  text-align: center;

  main {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  p {
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.title.fontSize};
  }
  .star {
    font-size: 2.5rem;
  }
  textarea {
    margin: 2rem 0;
    padding: 1rem;
    width: 90%;
  }
`;
