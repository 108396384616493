import styled, { keyframes } from "styled-components";
import { media } from "../../service/util/util";
const moveUp = keyframes`
  from {
    transform: translateY(2rem)
  }

  to {
    transform: translateY(0)
  }
`;

export const mediaQueries = (container, maintext) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
                    h1{
                        
                      font-size: 2rem;
                    
                    }

                    p{
                      font-size: 1rem;
                       
                    }
                    main{
                        padding:  0 2rem;
                        section:nth-child(1){
                            flex: 1.5 1 0%
                        }
                    }
                    input, button{
                        height: 2.5rem;
                    }
      `}

      ${media.tablet`
                display: flex;
                flex-direction: column;
                align-items: center;
                main{
                    width: 75%;
                    padding: 0 10px;
                    flex-direction: column;
                    align-items: center;
                }

                ${maintext}{
                    flex: 1;
                    animation: ${moveUp} 1s linear;
                    bottom: 0rem;
                }

                .access-the-community {
                    width: 100%;
                    padding-right: 0;
                    h1{
                        
                        font-size: 1rem;
                        text-align: center;
                        line-height: normal;
                    }

                    p{
                        font-size: 0.8rem;
                        text-align: center;
                    }
                }

                .start-of-classes{
                    text-align: center;
                    font-size: 0.8rem;
                    margin-top: 2rem;
                }
             

        `}
    }
  `;
  return mediaContainer;
};
