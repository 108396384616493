// react router dom
import { Link } from "react-router-dom";

// components
import { Header } from "../../components/header";
import { CardTeam } from "../../components/card_team";

// style
import { Container } from "./style";
import { motion } from "framer-motion";

// images
import logo from "../../imgs/logo.png";

//mock
import { teamSkcoders } from "../../service/mocks";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container);

export const PagAboutUs = ({ ...rest }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header>
            <Link to="/">
              <img src={logo} alt="" className="logo" />
            </Link>
          </Header>
          <main>
            <h1 className="main__title">Sobre Nós</h1>
            <p className="main_text">
              "SkCoder é uma escola de tecnologia que tem como missão levar
              conhecimento a todas as pessoas que desejam aprender programação.
              Nós percebemos a demanda por mão de obra qualificada e a falta de
              recursos para muitos brasileiros que querem se capacitar nessa
              área. O nosso projeto visa oferecer uma formação de qualidade para
              os nossos alunos e prepará-los para atuar como desenvolvedores no
              mercado de trabalho."
            </p>
            <hr />
            <h1 className="main__title">Nosso Time</h1>
            <div className="main_cards">
              {teamSkcoders.map(
                ({ title, description, linkedin, github, showIcon }, index) => (
                  <CardTeam
                    title={title}
                    description={description}
                    linkedin={linkedin}
                    github={github}
                    showIcon={showIcon}
                  ></CardTeam>
                )
              )}
            </div>
          </main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
