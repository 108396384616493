import styled from "styled-components";

export const Container = styled.button`
  background-color: ${({ disabled, theme }) =>
    !disabled ? theme.blue : "transparent"};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  border: none;
  color: ${({ disabled, theme }) => (!disabled ? theme.white : theme.blue)};
  border: ${({ disabled, theme }) =>
    !disabled ? "none" : "1px solid " + theme.blue};
  min-width: 5.5rem;
  min-height: 2rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 16px;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "not-allowed")};
  transition: all 0.2s ease-out 100ms;
`;
