import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (container) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
                            
        `}

      ${media.tablet`

      
        padding:0.2rem;
        border-radius:1rem;
        width:10rem;
        height:4rem;
        &::after{
            border:1px solid #74E4E5;
            border-radius:1rem;
         }

        h3{
            font-size:0.8rem;
            text-align:center;
        }
        p{
            font-size:0.7rem; 
            text-align:center;
        }

        
        .card_description_links{
           
            font-size:1rem;
            display:flex;
            flex-direction:row;
            gap:0.5rem;
            top:3rem;
        `}

        ${media.mobileG`
            
        `}
    }
  `;
  return mediaContainer;
};
