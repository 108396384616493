import styled from "styled-components";
import { theme } from "../../styles/global";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #74e4e5;
  width: 40%;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 2rem;

  iframe {
    display: none;
  }
  button {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #f9fafb;

    &:hover {
      font-size: 2.1rem;
      color: #74e4e5;
    }
    &:focus {
      color: #74e4e5;
    }
  }

  .download_title {
    color: ${theme.white};
    font-size: 2rem;
    margin: 1rem 0;
  }
`;
