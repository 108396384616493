import { UPDATE_ACTIVITY } from "./types";

const storageState = JSON.parse(localStorage.getItem("studentActivity"));

const initialState = storageState || {
  lastClass: false,
  lastModule: false,
  lastProgram: false,
}

export const studentActivityReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ACTIVITY:
        return action.payload;

      default:
      return state;
    }
}
