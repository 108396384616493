export const cssBasicoAulas = [
  {
    name: "",
    video: "",
    introduction: "",
    Content: () => {
      return <></>;
    },
  },
  {
    name: "Princípios básicos do CSS",
    video: "https://youtu.be/GFqdOtd_CB8",
    introduction:
      "Começando o curso de CSS com o básico dos conceitos, sintaxes e aplicações do CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Classes e ids",
    video: "https://youtu.be/BQmHAUX9znQ",
    introduction: "Como usar classes e ids no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Hierarquia no CSS",
    video: "https://youtu.be/kWpDvwql9Rs",
    introduction:
      "Aula sobre como usar seletores relacionados as hierarquias dos elementos",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Propriedades de texto",
    video: "https://youtu.be/kWpDvwql9Rs",
    introduction:
      "Aula sobre como usar seletores relacionados as hierarquias dos elementos",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Não há material extra</h2>
          </section>
        </>
      );
    },
  },
  {
    name: "Propriedade de texto no CSS",
    video: "https://youtu.be/spasw3XtYlU",
    introduction: "Entrando mais a fundo das propriedades de texto",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Propriedade de texto no CSS</h2>
            <ol>
              <ol>
                <li>
                  <h3>Cor do Texto (Text Color):</h3>
                  <ul>
                    <li>Define a cor do texto dentro de um elemento HTML.</li>
                    <li>
                      Exemplo: `color: #ff0000;` (define a cor do texto como
                      vermelha).
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Alinhamento do Texto (Text Alignment):</h3>
                  <ul>
                    <li>
                      Controla a posição do texto dentro de um elemento em
                      relação ao seu contêiner.
                    </li>
                    <li>
                      Exemplo: `text-align: center;` (alinha o texto ao centro).
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Decoração do Texto (Text Decoration):</h3>
                  <ul>
                    <li>
                      Adiciona decorações ao texto, como sublinhado, tachado ou
                      sobrelinhado.
                    </li>
                    <li>
                      Exemplo: `text-decoration: underline;` (adiciona
                      sublinhado ao texto).
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Transformação do Texto (Text Transformation):</h3>
                  <ul>
                    <li>
                      Altera a capitalização do texto, transformando-o em
                      maiúsculas, minúsculas ou outras formas.
                    </li>
                    <li>
                      Exemplo: `text-transform: uppercase;` (transforma o texto
                      em maiúsculas).
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Espaçamento do Texto (Text Spacing):</h3>
                  <ul>
                    <li>
                      Controla o espaçamento entre as letras (espaçamento de
                      caracteres) e as palavras.
                    </li>
                    <li>
                      Exemplo: `letter-spacing: 2px; word-spacing: 5px;` (define
                      o espaçamento entre letras e palavras).
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>Sombra do Texto (Text Shadow):</h3>
                  <ul>
                    <li>
                      Adiciona uma sombra ao texto, permitindo controle sobre
                      deslocamento, desfoque e cor da sombra.
                    </li>
                    <li>
                      Exemplo: `text-shadow: 2px 2px 4px #000000;` (adiciona uma
                      sombra preta deslocada 2px horizontal e verticalmente, com
                      desfoque de 4px).
                    </li>
                  </ul>
                </li>
              </ol>
            </ol>
            <p>
              Ao utilizar essas propriedades em conjunto, você pode criar
              estilos de texto variados e personalizados para melhorar a
              apresentação visual do conteúdo em seu site ou aplicação web.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Seletores no CSS",
    video: "https://youtu.be/h9uKE_QIQdc",
    introduction: "Aula sobre os seletores em CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Seletores no CSS</h2>
            <ol>
              <li>
                Seletor de Tipo:
                <ul>
                  <li>Seleciona todos os elementos de um determinado tipo.</li>
                  <li>Exemplo: `p` seleciona todos os parágrafos.</li>
                </ul>
              </li>
              <li>
                Seletor de Classe:
                <ul>
                  <li>Seleciona elementos com uma determinada classe.</li>
                  <li>
                    Exemplo: `.destaque` seleciona todos os elementos com a
                    classe "destaque".
                  </li>
                </ul>
              </li>
              <li>
                Seletor de ID:
                <ul>
                  <li>Seleciona um elemento com um ID específico.</li>
                  <li>
                    Exemplo: `#header` seleciona o elemento com o ID "header".
                  </li>
                </ul>
              </li>
              <li>
                Seletor de Descendência:
                <ul>
                  <li>
                    Seleciona elementos que são descendentes de outro elemento.
                  </li>
                  <li>
                    Exemplo: `article p` seleciona todos os parágrafos dentro de
                    elementos `article`.
                  </li>
                </ul>
              </li>
              <li>
                Seletor de Filho Direto:
                <ul>
                  <li>
                    Seleciona elementos que são filhos diretos de outro
                    elemento.
                  </li>
                  <li>
                    Exemplo: `ul (sinal de maior) li` seleciona todos os itens
                    de lista (`li`) que são filhos diretos de uma lista não
                    ordenada (`ul`).
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Esses são apenas alguns exemplos de seletores CSS. A combinação
              desses seletores oferece grande flexibilidade para estilizar
              elementos específicos em suas páginas web.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Unidades de medidas no CSS",
    video: "https://youtu.be/X0kQSPT0G1M",
    introduction:
      "No CSS, há várias unidades de medida que você pode usar para definir valores como larguras, alturas, margens, preenchimentos e outros.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Unidades de medidas no CSS</h2>
            <p>
              No CSS, há várias unidades de medida que você pode usar para
              definir valores como larguras, alturas, margens, preenchimentos e
              outros. Aqui estão algumas das unidades de medida mais comuns:
            </p>
            <ul>
              <li>
                Pixels (px):
                <ul>
                  <li>Unidade fixa de medida.</li>
                  <li>Exemplo: `width: 200px;`</li>
                </ul>
              </li>
              <li>
                Porcentagem (%):
                <ul>
                  <li>Relativa ao elemento pai.</li>
                  <li>
                    Exemplo: `width: 50%;` (50% da largura do elemento pai).
                  </li>
                </ul>
              </li>
              <li>
                Em (em):
                <ul>
                  <li>Relativa ao tamanho da fonte do elemento pai.</li>
                  <li>
                    Exemplo: `font-size: 1.5em;` (1.5 vezes o tamanho da fonte
                    do elemento pai).
                  </li>
                </ul>
              </li>
              <li>
                Rem (rem):
                <ul>
                  <li>
                    Relativa ao tamanho da fonte do elemento raiz (normalmente o
                    elemento `html`).
                  </li>
                  <li>
                    Exemplo: `margin: 2rem;` (2 vezes o tamanho da fonte do
                    elemento raiz).
                  </li>
                </ul>
              </li>
              <li>
                Viewport Width (vw):
                <ul>
                  <li>Relativa à largura da viewport.</li>
                  <li>Exemplo: `width: 10vw;` (10% da largura da viewport).</li>
                </ul>
              </li>
              <li>
                Viewport Height (vh):
                <ul>
                  <li>Relativa à altura da viewport.</li>
                  <li>Exemplo: `height: 75vh;` (75% da altura da viewport).</li>
                </ul>
              </li>
              <li>
                Viewport Minimum (vmin):
                <ul>
                  <li>
                    Relativa à menor dimensão da viewport (largura ou altura).
                  </li>
                  <li>
                    Exemplo: `font-size: 3vmin;` (3% da menor dimensão da
                    viewport).
                  </li>
                </ul>
              </li>
              <li>
                Viewport Maximum (vmax):
                <ul>
                  <li>
                    Relativa à maior dimensão da viewport (largura ou altura).
                  </li>
                  <li>
                    Exemplo: `margin: 5vmax;` (5% da maior dimensão da
                    viewport).
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Essas unidades oferecem flexibilidade ao desenvolver layouts
              responsivos e adaptáveis a diferentes dispositivos e tamanhos de
              tela.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Width e Height no CSS",
    video: "https://youtu.be/FIqXrNHt9qg",
    introduction: "Entenda como usar as propriedades Width e Height no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Width e Height no CSS</h2>
            <ul>
              <li>
                Descrição:
                <p> - A propriedade "width" define a largura de um elemento.</p>
              </li>
              <li>
                Valores Permitidos:
                <p>
                  {" "}
                  - Pode ser especificada em pixels (px), porcentagem (%), em
                  (em), rem (rem), viewport width (vw), ou outras unidades de
                  medida.
                </p>
              </li>
            </ul>
            <h3>Height:</h3>
            <ul>
              <li>
                Descrição:
                <p> - A propriedade "height" define a altura de um elemento.</p>
              </li>
              <li>
                Valores Permitidos:
                <p>
                  {" "}
                  - Assim como "width", a altura pode ser especificada em pixels
                  (px), porcentagem (%), em (em), rem (rem), viewport height
                  (vh), ou outras unidades de medida.
                </p>
              </li>
              <li>
                Aspect Ratio (Proporção de Aspecto):
                <p>
                  {" "}
                  - Se você definir apenas uma das propriedades ("width" ou
                  "height"), e a outra como "auto", o navegador manterá a
                  proporção do elemento.
                </p>
              </li>
              <li>
                Valores Percentuais:
                <p>
                  {" "}
                  - Quando você usa valores percentuais, eles são calculados com
                  base no elemento pai. Isso é especialmente útil para criar
                  layouts responsivos.
                </p>
              </li>
              <li>
                Unidades Relativas:
                <p>
                  {" "}
                  - O uso de unidades relativas como em e rem pode ser útil
                  quando você deseja que as dimensões estejam relacionadas ao
                  tamanho da fonte do elemento pai ou ao tamanho da fonte do
                  elemento raiz, respectivamente.
                </p>
              </li>
            </ul>
            <p>
              Em resumo, as propriedades "width" e "height" são fundamentais
              para controlar as dimensões dos elementos em uma página da web e
              são essenciais para criar layouts responsivos e visualmente
              agradáveis.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Cores no CSS",
    video: "https://youtu.be/VsNqDjLe1CY",
    introduction: "Entenda como usar as propriedades de cores no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Cores no CSS</h2>
            <h3>Representação de Cores:</h3>
            <ul>
              <li>
                Palavras-Chave:
                <ul>
                  <li>
                    Utilize palavras-chave como `red`, `blue`, `green`, etc.
                  </li>
                </ul>
              </li>
              <li>
                Valores Permitidos:
                <p>
                  {" "}
                  - Pode ser especificada em pixels (px), porcentagem (%), em
                  (em), rem (rem), viewport width (vw), ou outras unidades de
                  medida.
                </p>
              </li>
            </ul>
            <h3>Height:</h3>
            <ul>
              <li>
                Descrição:
                <p> - A propriedade "height" define a altura de um elemento.</p>
              </li>
              <li>
                Valores Permitidos:
                <p>
                  {" "}
                  - Assim como "width", a altura pode ser especificada em pixels
                  (px), porcentagem (%), em (em), rem (rem), viewport height
                  (vh), ou outras unidades de medida.
                </p>
              </li>
              <li>
                Aspect Ratio (Proporção de Aspecto):
                <p>
                  {" "}
                  - Se você definir apenas uma das propriedades ("width" ou
                  "height"), e a outra como "auto", o navegador manterá a
                  proporção do elemento.
                </p>
              </li>
              <li>
                Valores Percentuais:
                <p>
                  {" "}
                  - Quando você usa valores percentuais, eles são calculados com
                  base no elemento pai. Isso é especialmente útil para criar
                  layouts responsivos.
                </p>
              </li>
              <li>
                Unidades Relativas:
                <p>
                  {" "}
                  - O uso de unidades relativas como em e rem pode ser útil
                  quando você deseja que as dimensões estejam relacionadas ao
                  tamanho da fonte do elemento pai ou ao tamanho da fonte do
                  elemento raiz, respectivamente.
                </p>
              </li>
            </ul>
            <p>
              Em resumo, as propriedades "width" e "height" são fundamentais
              para controlar as dimensões dos elementos em uma página da web e
              são essenciais para criar layouts responsivos e visualmente
              agradáveis.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Background no CSS",
    video: "https://youtu.be/VW7Vg3y0970",
    introduction: "Entenda como usar as propriedades de background no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Background no CSS</h2>
            <ol>
              <li>
                Cor de Fundo:
                <ul>
                  <li>
                    Define a cor de fundo de um elemento.
                    <br />
                    <br />
                    ```css
                    <br />
                    body {"{"}
                    <br />
                    &nbsp;background-color: #f0f0f0; /* Exemplo: cinza claro */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Imagem de Fundo:
                <ul>
                  <li>
                    Define uma imagem como fundo.
                    <br />
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;background-image: url('imagem.jpg');
                    <br />
                    &nbsp;background-repeat: no-repeat; /* Evita repetição da
                    imagem */
                    <br />
                    &nbsp;background-size: cover; /* Ajusta o tamanho da imagem
                    */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Posicionamento da Imagem de Fundo:
                <ul>
                  <li>
                    Controla a posição da imagem de fundo.
                    <br />
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;background-position: center top; /* Posiciona a imagem
                    no centro superior */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Gradientes:
                <ul>
                  <li>
                    Cria gradientes de cor como fundo.
                    <br />
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;background: linear-gradient(to right, #ffcc00,
                    #ff6600); /* Gradiente de laranja para amarelo */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Anexar Múltiplas Imagens de Fundo:
                <ul>
                  <li>
                    Permite o uso de várias imagens como plano de fundo.
                    <br />
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;background-image: url('imagem1.jpg'),
                    url('imagem2.jpg');
                    <br />
                    &nbsp;background-position: top left, bottom right;
                    <br />
                    &nbsp;background-repeat: no-repeat, repeat-x;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Cor do Texto sobre Fundo Escuro:
                <ul>
                  <li>
                    Garante que o texto seja legível em fundos escuros.
                    <br />
                    <br />
                    ```css
                    <br />p {"{"}
                    <br />
                    &nbsp;background-color: #000;
                    <br />
                    &nbsp;color: #fff;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Background Fixo:
                <ul>
                  <li>
                    Mantém o plano de fundo fixo enquanto o conteúdo rola.
                    <br />
                    <br />
                    ```css
                    <br />
                    body {"{"}
                    <br />
                    &nbsp;background-image: url('imagem.jpg');
                    <br />
                    &nbsp;background-attachment: fixed;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Múltiplas Propriedades no Background:
                <ul>
                  <li>
                    Combina várias propriedades relacionadas ao background.
                    <br />
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;background: #f0f0f0 url('imagem.jpg') center top
                    no-repeat;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Estas são apenas algumas das propriedades básicas para trabalhar
              com backgrounds no CSS. Ao personalizar os backgrounds, lembre-se
              de considerar a acessibilidade e a legibilidade do conteúdo em
              relação ao plano de fundo escolhido. Experimente diferentes
              combinações para criar o visual desejado.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Bordas no CSS",
    video: "https://youtu.be/EXB-L0_hMFI",
    introduction: "Entenda como usar as propriedades de bordas no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Bordas no CSS</h2>
            <p>
              No CSS, a propriedade border é usada para definir as bordas ao
              redor de um elemento HTML. Aqui está o que você precisa saber
              sobre border no CSS:
            </p>
            <ol>
              <li>
                Estilo, Largura e Cor Separados:
                <ul>
                  <li>
                    Você pode especificar o estilo, a largura e a cor
                    separadamente.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;border-style: dashed; /* Estilo da borda: tracejada */
                    <br />
                    &nbsp;border-width: 2px; /* Largura da borda: 2 pixels */
                    <br />
                    &nbsp;border-color: #333; /* Cor da borda: cinza escuro */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Bordas Individuais:
                <ul>
                  <li>
                    É possível definir estilos, larguras e cores diferentes para
                    cada borda (superior, direita, inferior, esquerda).
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;border-top: 2px solid #ff0000; /* Borda superior: 2
                    pixels, sólida, vermelha */
                    <br />
                    &nbsp;border-right: 1px dotted #00ff00; /* Borda direita: 1
                    pixel, pontilhada, verde */
                    <br />
                    &nbsp;border-bottom: 3px dashed #0000ff; /* Borda inferior:
                    3 pixels, tracejada, azul */
                    <br />
                    &nbsp;border-left: 4px double #ff00ff; /* Borda esquerda: 4
                    pixels, dupla, rosa */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Bordas Arredondadas:
                <ul>
                  <li>
                    A propriedade `border-radius` permite criar bordas
                    arredondadas.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;border: 2px solid #333;
                    <br />
                    &nbsp;border-radius: 10px; /* Borda com cantos arredondados
                    de 10 pixels */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Bordas Simples:
                <ul>
                  <li>
                    Define uma borda simples usando a propriedade `border`.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;border: 1px solid #000; /* 1 pixel, sólida, cor preta
                    */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Box Sizing:
                <ul>
                  <li>
                    A propriedade `box-sizing` afeta o modelo de caixa,
                    considerando ou não o preenchimento e a borda na largura e
                    altura do elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;box-sizing: border-box; /* Inclui preenchimento e
                    borda na largura/altura */
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Lembre-se de que você pode ajustar essas propriedades de acordo
              com suas necessidades de design. As bordas são uma parte crucial
              do layout e estilização de elementos HTML, proporcionando
              estrutura e distinção visual. Experimente diferentes estilos,
              cores e larguras para alcançar o efeito desejado.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Margins no CSS",
    video: "https://youtu.be/vlAZ_4D6yqM",
    introduction:
      "A propriedade `margin` no CSS é utilizada para controlar o espaçamento ao redor de um elemento. Aqui estão algumas informações básicas sobre como trabalhar com a propriedade `margin`",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Margins no CSS</h2>
            <p>
              A propriedade `margin` no CSS é utilizada para controlar o
              espaçamento ao redor de um elemento. Aqui estão algumas
              informações básicas sobre como trabalhar com a propriedade
              `margin`:
            </p>
            <ol>
              <li>
                Definição Simples:
                <ul>
                  <li>
                    Define margens iguais para todos os lados do elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin: 10px;{" "}
                    {"/* Margem de 10 pixels para todos os lados */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Definição por Lado:
                <ul>
                  <li>
                    Define margens diferentes para cada lado do elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin-top: 10px;
                    <br />
                    &nbsp;margin-right: 20px;
                    <br />
                    &nbsp;margin-bottom: 15px;
                    <br />
                    &nbsp;margin-left: 5px;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Definição Abreviada:
                <ul>
                  <li>
                    Pode ser abreviada para definir todas as margens em um único
                    valor.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin: 10px 20px 15px 5px;{" "}
                    {"/* Topo, Direita, Baixo, Esquerda */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Margem Auto:
                <ul>
                  <li>
                    Define a margem automaticamente para centralizar um elemento
                    horizontalmente.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin-left: auto;
                    <br />
                    &nbsp;margin-right: auto;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Unidades de Medida:
                <ul>
                  <li>
                    As margens podem ser definidas em várias unidades, como
                    pixels, em, rem, etc.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin: 2em; {"/* Margem de 2 em */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Margens Negativas:
                <ul>
                  <li>
                    Podem ser utilizadas margens negativas para sobrepor
                    elementos vizinhos.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin-left: -10px;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Herança de Margens:
                <ul>
                  <li>
                    Margens podem ser herdadas de elementos pais, dependendo da
                    situação.
                    <br />
                    ```css
                    <br />
                    parent {"{"}
                    <br />
                    &nbsp;margin-bottom: 15px;
                    <br />
                    {"}"}
                    <br />
                    <br />
                    child {"{"}
                    <br />
                    {
                      "/* O elemento filho herda a margem inferior do elemento pai */"
                    }
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Box Model:
                <ul>
                  <li>
                    Lembre-se de que as margens fazem parte do modelo de caixa,
                    junto com o preenchimento (padding) e as bordas.
                  </li>
                </ul>
              </li>
              <li>
                Colapsamento de Margens:
                <ul>
                  <li>
                    Em alguns casos, as margens podem "colapsar" e a maior
                    margem entre elementos adjacentes é usada.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;margin-bottom: 10px;
                    <br />
                    {"}"}
                    <br />
                    <br />p {"{"}
                    <br />
                    &nbsp;margin-top: 15px;
                    <br />
                    {"}"}
                    <br />
                    <br />
                    {
                      "/* Neste caso, a margem entre div e p será 15px, não 25px */"
                    }
                    ```
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Estas são informações básicas sobre a propriedade `margin` no CSS.
              A manipulação adequada das margens é essencial para criar layouts
              eficientes e esteticamente agradáveis em páginas da web.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Padding no CSS",
    video: "https://youtu.be/-CulhfHfyqw",
    introduction:
      "A propriedade `padding` no CSS é utilizada para controlar o preenchimento ao redor de um elemento. Aqui estão algumas informações básicas sobre como trabalhar com a propriedade `padding`",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Padding no CSS</h2>
            <ol>
              <li>
                Definição Simples:
                <ul>
                  <li>
                    Define o mesmo valor de preenchimento para todos os lados do
                    elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: 10px;{" "}
                    {"/* Preenchimento de 10 pixels para todos os lados */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Definição por Lado:
                <ul>
                  <li>
                    Permite definir valores diferentes de preenchimento para
                    cada lado do elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding-top: 10px;
                    <br />
                    &nbsp;padding-right: 20px;
                    <br />
                    &nbsp;padding-bottom: 15px;
                    <br />
                    &nbsp;padding-left: 5px;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Definição Abreviada:
                <ul>
                  <li>
                    Pode ser abreviada para definir todos os preenchimentos em
                    um único valor.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: 10px 20px 15px 5px;{" "}
                    {"/* Topo, Direita, Baixo, Esquerda */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Unidades de Medida:
                <ul>
                  <li>
                    Os valores de preenchimento podem ser especificados em
                    diversas unidades, como pixels, em, rem, etc.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: 2em; {"/* Preenchimento  de 2 em */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Box Model:
                <ul>
                  <li>
                    Lembre-se de que o preenchimento faz parte do modelo de
                    caixa, junto com as bordas e as margens.
                  </li>
                </ul>
              </li>
              <li>
                Herança de Preenchimento:
                <ul>
                  <li>
                    Diferentemente das margens, os preenchimentos não são
                    herdados pelos elementos filhos.
                    <br />
                    ```css
                    <br />
                    parent {"{"}
                    <br />
                    &nbsp;padding: 10px;
                    <br />
                    {"}"}
                    <br />
                    <br />
                    child {"{"}
                    <br />
                    {
                      "/* O elemento filho não herda o preenchimento do elemento pai */"
                    }
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Preenchimento Negativas:
                <ul>
                  <li>
                    Não é possível especificar valores de preenchimento
                    negativos no CSS.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: -5px; {"/* Isso não é válido */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Preenchimento em Percentagem:
                <ul>
                  <li>
                    É possível usar porcentagens para definir o preenchimento em
                    relação ao tamanho do elemento.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: 5%;{" "}
                    {
                      "/* Preenchimento de 5% em relação à largura do elemento */"
                    }
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Preenchimento Auto:
                <ul>
                  <li>
                    Não há um valor `auto` para o preenchimento. O preenchimento
                    é sempre definido explicitamente.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: auto; {"/* Isso não é válido */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
              <li>
                Preenchimento Zero:
                <ul>
                  <li>
                    Pode-se definir o preenchimento como zero para remover
                    qualquer espaço interno.
                    <br />
                    ```css
                    <br />
                    div {"{"}
                    <br />
                    &nbsp;padding: 0;
                    <br />
                    {"}"}
                    <br />
                    ```
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Essas são informações básicas sobre a propriedade `padding` no
              CSS. O controle eficiente do preenchimento é essencial para criar
              layouts equilibrados e esteticamente agradáveis em páginas da web.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Box Model no CSS",
    video: "https://youtu.be/Od1ClxUA9OA",
    introduction:
      "O modelo de caixa (box model) no CSS é um conceito fundamental que descreve como os elementos HTML são representados visualmente na página.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Box Model no CSS</h2>
            <p>
              O modelo de caixa (box model) no CSS é um conceito fundamental que
              descreve como os elementos HTML são representados visualmente na
              página. Aqui está um resumo sobre o box model:
            </p>
            <ol>
              <li>
                Conteúdo (Content):
                <ul>
                  <li>
                    O conteúdo real do elemento, como texto, imagens, etc.
                  </li>
                  <li>É definido pelas propriedades de `width` e `height`.</li>
                </ul>
              </li>
              <li>
                Preenchimento (Padding):
                <ul>
                  <li>Espaço entre o conteúdo e a borda do elemento.</li>
                  <li>
                    Controlado pelas propriedades de `padding-top`,
                    `padding-right`, `padding-bottom` e `padding-left`.
                  </li>
                </ul>
              </li>
              <li>
                Borda (Border):
                <ul>
                  <li>Linha que envolve o conteúdo e o preenchimento.</li>
                  <li>
                    Determinada pelas propriedades de `border-width`,
                    `border-style` e `border-color`.
                  </li>
                </ul>
              </li>
              <li>
                Margem (Margin):
                <ul>
                  <li>
                    Espaço entre a borda do elemento e os elementos adjacentes.
                  </li>
                  <li>
                    Definido pelas propriedades de `margin-top`, `margin-right`,
                    `margin-bottom` e `margin-left`.
                  </li>
                </ul>
              </li>
              <li>
                Funcionamento:
                <ul>
                  <li>
                    As propriedades de `width` e `height` definem o tamanho do
                    conteúdo.
                  </li>
                  <li>
                    O `padding` cria espaço interno entre o conteúdo e a borda.
                  </li>
                  <li>
                    A `border` envolve o conteúdo e o preenchimento com uma
                    linha visível.
                  </li>
                  <li>
                    A `margin` define o espaço externo entre a borda do elemento
                    e outros elementos.
                  </li>
                </ul>
              </li>
              <li>
                Abreviações:
                <ul>
                  <li>
                    As propriedades do box model podem ser abreviadas em uma
                    única linha para simplificar o código CSS.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              O entendimento do modelo de caixa é essencial para criar layouts
              precisos e controlar o espaçamento entre os elementos em uma
              página da web. Ao ajustar o box model, você pode criar designs
              flexíveis e responsivos.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "CSS reset no css",
    video: "https://youtu.be/a4T8_HvQ18k",
    introduction:
      "Um CSS reset é uma técnica usada para garantir que os estilos padrão dos navegadores sejam removidos ou redefinidos, garantindo uma base consistente para estilização em todos os navegadores.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>CSS reset no css</h2>
            <p>
              Um CSS reset é uma técnica usada para garantir que os estilos
              padrão dos navegadores sejam removidos ou redefinidos, garantindo
              uma base consistente para estilização em todos os navegadores.
              Aqui está um resumo sobre o CSS reset:
            </p>
            <ol>
              <li>
                Objetivo:
                <ul>
                  <li>
                    Padronizar o estilo dos elementos HTML em todos os
                    navegadores, removendo as diferenças que podem ocorrer
                    devido aos estilos padrão dos navegadores.
                  </li>
                </ul>
              </li>
              <li>
                Remoção de Margens e Preenchimento:
                <ul>
                  <li>
                    Os navegadores geralmente aplicam margens e preenchimentos
                    padrão a elementos como `body`, `h1`, `p`, etc. Um CSS reset
                    geralmente redefine essas margens e preenchimentos para zero
                    para garantir consistência.
                  </li>
                </ul>
              </li>
              <li>
                Zerar Preenchimentos e Bordas:
                <ul>
                  <li>
                    Além das margens, os preenchimentos e bordas também são
                    frequentemente redefinidos para zero para evitar espaços
                    indesejados entre elementos.
                  </li>
                </ul>
              </li>
              <li>
                Normalização de Estilos:
                <ul>
                  <li>
                    Além de redefinir valores padrão, algumas técnicas de reset
                    também normalizam estilos, garantindo que os elementos HTML
                    tenham uma aparência mais consistente entre os navegadores.
                  </li>
                </ul>
              </li>
              <li>
                Reset Global vs. Reset Parcial:
                <ul>
                  <li>
                    Existem diferentes abordagens para CSS reset, desde resets
                    globais que afetam todos os elementos até resets parciais
                    que afetam apenas elementos específicos.
                  </li>
                </ul>
              </li>
              <li>
                Frameworks e Bibliotecas:
                <ul>
                  <li>
                    Alguns frameworks e bibliotecas CSS populares incluem seus
                    próprios resets ou normalizações CSS para garantir uma
                    experiência consistente ao usar seus componentes.
                  </li>
                </ul>
              </li>
              <li>
                Exemplo de CSS Reset Básico:
                <ul>
                  <li>
                    ```css
                    <br />
                    {"/* Reset básico */"}
                    <br />
                    {"* {"}
                    <br />
                    &nbsp;margin: 0;
                    <br />
                    &nbsp;padding: 0;
                    <br />
                    &nbsp;box-sizing: border-box;{" "}
                    {
                      "/* Garante que a largura/altura inclua padding e borda */"
                    }
                    <br />
                    {"}"}
                    <br />
                    <br />
                    body {"{"}
                    <br />
                    &nbsp;font-family: Arial, sans-serif;{" "}
                    {"/* Define uma fonte padrão para o corpo */"}
                    <br />
                    {"}"}
                    <br />
                    ```
                    <br />
                  </li>
                </ul>
              </li>
              <li>
                Personalização:
                <ul>
                  <li>
                    O CSS reset pode ser personalizado de acordo com as
                    necessidades do projeto, adicionando estilos específicos
                    conforme necessário.
                  </li>
                </ul>
              </li>
            </ol>
            <p>
              Um CSS reset é uma etapa comum no desenvolvimento web para
              garantir uma base consistente para estilos e layout em todos os
              navegadores. Ele ajuda a reduzir as inconsistências na aparência
              dos elementos HTML e fornece uma base limpa para estilização
              personalizada.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Projeto prático - parte 1",
    video: "https://youtu.be/tgHBVqb4esg",
    introduction:
      "Este projeto prático consiste em uma página HTML simples que exibe informações sobre uma pessoa, incluindo uma imagem de perfil, nome, localização, breve descrição e links para perfis em diferentes plataformas.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Projeto prático - parte 1</h2>
            <p>
              Este projeto prático consiste em uma página HTML simples que exibe
              informações sobre uma pessoa, incluindo uma imagem de perfil,
              nome, localização, breve descrição e links para perfis em
              diferentes plataformas.
            </p>
            <p>O que foi feito:</p>
            <ul>
              <li>
                Criou-se uma estrutura básica HTML com tags semânticas para
                melhor acessibilidade e SEO.
              </li>
              <li>
                Definiram-se estilos CSS para o layout e design da página.
              </li>
              <li>
                Utilizou-se a fonte "Inter" do Google Fonts para a tipografia.
              </li>
              <li>
                Ajustaram-se as cores usando variáveis CSS para facilitar a
                manutenção e reutilização.
              </li>
              <li>
                Centralizou-se o conteúdo na página e aplicaram-se estilos de
                espaçamento e alinhamento para uma aparência limpa e organizada.
              </li>
              <li>
                Criou-se um contêiner para o perfil da pessoa, com uma imagem,
                nome, localização e links para perfis em diferentes plataformas.
              </li>
              <li>
                Estilizaram-se os elementos para garantir uma experiência visual
                agradável, incluindo bordas arredondadas, espaçamentos adequados
                e estilos de texto.
              </li>
            </ul>
            <p>Resultado:</p>
            <ul>
              <li>
                Um layout simples e elegante que destaca as informações do
                perfil da pessoa.
              </li>
              <li>
                Os estilos aplicados proporcionam uma aparência moderna e
                profissional à página.
              </li>
              <li>
                A estrutura HTML semântica e os estilos CSS bem organizados
                facilitam a manutenção e a escalabilidade do projeto.
              </li>
              <li>
                Os links fornecem acesso fácil aos perfis da pessoa em diversas
                plataformas, tornando a página interativa e funcional.
              </li>
            </ul>
            <p>
              Este projeto demonstra o uso prático de HTML e CSS para criar uma
              página web com aparência profissional e funcionalidade básica. Ele
              serve como uma base sólida para adicionar mais recursos e
              personalizações conforme necessário.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Projeto prático - parte 2",
    video: "https://youtu.be/coSmTuOIUec",
    introduction:
      "Este projeto prático consiste em uma página HTML simples que exibe informações sobre uma pessoa, incluindo uma imagem de perfil, nome, localização, breve descrição e links para perfis em diferentes plataformas.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <ul>
              <p>O que foi feito:</p>
              <li>
                Criou-se uma estrutura básica HTML com tags semânticas para
                melhor acessibilidade e SEO.
              </li>
              <li>
                Definiram-se estilos CSS para o layout e design da página.
              </li>
              <li>
                Utilizou-se a fonte "Inter" do Google Fonts para a tipografia.
              </li>
              <li>
                Ajustaram-se as cores usando variáveis CSS para facilitar a
                manutenção e reutilização.
              </li>
              <li>
                Centralizou-se o conteúdo na página e aplicaram-se estilos de
                espaçamento e alinhamento para uma aparência limpa e organizada.
              </li>
              <li>
                Criou-se um contêiner para o perfil da pessoa, com uma imagem,
                nome, localização e links para perfis em diferentes plataformas.
              </li>
              <li>
                Estilizaram-se os elementos para garantir uma experiência visual
                agradável, incluindo bordas arredondadas, espaçamentos adequados
                e estilos de texto.
              </li>
            </ul>
            <ul>
              <p>Resultado:</p>
              <li>
                Um layout simples e elegante que destaca as informações do
                perfil da pessoa.
              </li>
              <li>
                Os estilos aplicados proporcionam uma aparência moderna e
                profissional à página.
              </li>
              <li>
                A estrutura HTML semântica e os estilos CSS bem organizados
                facilitam a manutenção e a escalabilidade do projeto.
              </li>
              <li>
                Os links fornecem acesso fácil aos perfis da pessoa em diversas
                plataformas, tornando a página interativa e funcional.
              </li>
            </ul>
            <p>
              Este projeto demonstra o uso prático de HTML e CSS para criar uma
              página web com aparência profissional e funcionalidade básica. Ele
              serve como uma base sólida para adicionar mais recursos e
              personalizações conforme necessário.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Projeto prático - parte 3",
    video: "https://youtu.be/_9apFNuTtD0",
    introduction:
      "Este projeto prático consiste em uma página HTML simples que exibe informações sobre uma pessoa, incluindo uma imagem de perfil, nome, localização, breve descrição e links para perfis em diferentes plataformas.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <ul>
              <p>O que foi feito:</p>
              <li>
                Criou-se uma estrutura básica HTML com tags semânticas para
                melhor acessibilidade e SEO.
              </li>
              <li>
                Definiram-se estilos CSS para o layout e design da página.
              </li>
              <li>
                Utilizou-se a fonte "Inter" do Google Fonts para a tipografia.
              </li>
              <li>
                Ajustaram-se as cores usando variáveis CSS para facilitar a
                manutenção e reutilização.
              </li>
              <li>
                Centralizou-se o conteúdo na página e aplicaram-se estilos de
                espaçamento e alinhamento para uma aparência limpa e organizada.
              </li>
              <li>
                Criou-se um contêiner para o perfil da pessoa, com uma imagem,
                nome, localização e links para perfis em diferentes plataformas.
              </li>
              <li>
                Estilizaram-se os elementos para garantir uma experiência visual
                agradável, incluindo bordas arredondadas, espaçamentos adequados
                e estilos de texto.
              </li>
            </ul>
            <ul>
              <p>Resultado:</p>
              <li>
                Um layout simples e elegante que destaca as informações do
                perfil da pessoa.
              </li>
              <li>
                Os estilos aplicados proporcionam uma aparência moderna e
                profissional à página.
              </li>
              <li>
                A estrutura HTML semântica e os estilos CSS bem organizados
                facilitam a manutenção e a escalabilidade do projeto.
              </li>
              <li>
                Os links fornecem acesso fácil aos perfis da pessoa em diversas
                plataformas, tornando a página interativa e funcional.
              </li>
            </ul>
            <p>
              Este projeto demonstra o uso prático de HTML e CSS para criar uma
              página web com aparência profissional e funcionalidade básica. Ele
              serve como uma base sólida para adicionar mais recursos e
              personalizações conforme necessário.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Outline no CSS",
    video: "https://youtu.be/z-vpLgrmsM4",
    introduction:
      "A propriedade CSS `outline` é usada para adicionar uma linha ao redor de um elemento HTML, sem afetar o layout da página. É semelhante à propriedade `border`, mas não ocupa espaço adicional na página e não é afetada por mudanças no tamanho do conteúdo.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <p>A sintaxe básica da propriedade `outline` é a seguinte:</p>
            <br />
            <p>```css</p>
            <p>
              &nbsp;outline: [outline-width] [outline-style] [outline-color];
            </p>
            <p>```</p>
            <br />
            <ul>
              <li>
                - `outline-width`: Define a largura da linha de contorno. Pode
                ser especificado em pixels, em, rem ou como uma palavra-chave
                como `thin`, `medium` ou `thick`.
              </li>
              <li>
                - `outline-style`: Define o estilo da linha de contorno, como
                sólido, tracejado, pontilhado, duplo, etc.
              </li>
              <li>
                - `outline-color`: Define a cor da linha de contorno, que pode
                ser especificada usando um valor de cor (como nome, hexadecimal,
                RGB ou HSL).
              </li>
            </ul>
            <br />
            <p>Por exemplo:</p>
            <br />
            <p>```css</p>
            <p>&nbsp;outline: 2px dashed red;</p>
            <p>```</p>
            <br />
            <p>
              Isso cria uma linha contínua de 2 pixels de largura, com estilo
              tracejado e cor vermelha ao redor do elemento.
            </p>
            <p>
              A propriedade `outline` é frequentemente usada para fornecer
              feedback visual sobre elementos focados ou ativos em uma página da
              web, especialmente em formulários, onde é comum ser utilizada a
              propriedade `:focus` em conjunto com `outline`. No entanto, é
              importante observar que a `outline` não é afetada pelo
              `border-radius` e geralmente é renderizada em torno da caixa de
              delimitação do elemento, sem seguir seu contorno exato.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Fonts no CSS",
    video: "https://youtu.be/Yy3lEXtcnw0",
    introduction:
      "Compreender e aplicar esses conceitos irá ajudá-lo a ter um maior controle sobre a tipografia em seus projetos web.",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <ol>
              <li>
                Definição de fontes: No CSS, você pode definir fontes usando
                várias propriedades, como `font-family`, `font-size`,
                `font-weight`, `font-style`.
              </li>
              <li>
                <p>
                  `font-family`: Esta propriedade especifica a fonte da qual um
                  elemento de texto deve ser exibido. Você pode fornecer uma
                  lista de fontes em ordem de preferência, separadas por
                  vírgulas. O navegador tentará usar a primeira fonte da lista
                  e, se não estiver disponível, passará para a próxima fonte na
                  lista.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>```css</p>
                <p>p {"{"}</p>
                <p>&nbsp;font-family: Arial, sans-serif;</p>
                <p>{"}"}</p>
                <p>```</p>
                <br />
              </li>
              <li>
                <p>
                  `font-size`: Essa propriedade define o tamanho da fonte. Você
                  pode especificá-lo em unidades como `px`, `em`, `%`, etc.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>```css</p>
                <p>p {"{"}</p>
                <p>&nbsp;font-size: 16px;</p>
                <p>{"}"}</p>
                <p>```</p>
                <br />
              </li>
              <li>
                <p>
                  `font-weight`: Essa propriedade controla a espessura da fonte.
                  Valores comuns incluem `normal`, `bold`, `bolder`, `lighter` e
                  valores numéricos específicos.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>```css</p>
                <p>p {"{"}</p>
                <p>&nbsp;font-weight: bold;</p>
                <p>{"}"}</p>
                <p>```</p>
                <br />
              </li>
              <li>
                <p>
                  `font-style`: Esta propriedade define o estilo da fonte, como
                  `normal` ou `italic`.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>```css</p>
                <p>p {"{"}</p>
                <p>&nbsp;font-style: italic;</p>
                <p>{"}"}</p>
                <p>```</p>
                <br />
              </li>
              <li>
                Web Fonts: Além das fontes disponíveis localmente no sistema do
                usuário, você pode incorporar fontes personalizadas em seu site
                usando serviços de fontes da web, como Google Fonts, Adobe
                Fonts, etc. Essas fontes são baixadas do servidor quando o
                usuário acessa o site.
              </li>
              <li>
                Unidades de medida de fonte: As unidades mais comuns para o
                tamanho da fonte são `px` (pixels), `em`, `rem` (relativos ao
                tamanho da fonte do elemento pai), `%` (porcentagem do tamanho
                da fonte do elemento pai) e `vw` (viewport width).
              </li>
              <li>
                <p>
                  Fallback Fonts: É uma boa prática fornecer uma lista de fontes
                  alternativas em caso de a fonte preferida não estar
                  disponível. Isso é feito separando as fontes com vírgulas.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>```css</p>
                <p>body {"{"}</p>
                <p>
                  &nbsp;font-family: "Helvetica Neue", Helvetica, Arial,
                  sans-serif;
                </p>
                <p>{"}"}</p>
                <p>```</p>
                <br />
              </li>
            </ol>
            <p>
              Compreender e aplicar esses conceitos irá ajudá-lo a ter um maior
              controle sobre a tipografia em seus projetos web.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Links no CSS",
    video: "https://youtu.be/7ewpDXfV-W0",
    introduction: "Resumo sobre a propriedade e como usar links no CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <p>Resumo sobre a propriedade e como usar links no CSS:</p>
            <br />
            <u>
              <p>Propriedade "text-decoration":</p>
              <li>
                - Esta propriedade é usada para definir a decoração do texto,
                como sublinhado, tachado, etc., de um elemento de texto.
              </li>
              <li>
                - Ela pode ser aplicada a elementos de texto como links (a),
                parágrafos (p), títulos (h1, h2, etc.) e outros.
              </li>
            </u>
            <br />
            <ol>
              <p>Como usar links no CSS:</p>
              <li>
                <p>Estilizar links não visitados:</p>
                <p>
                  Você pode definir estilos para links que ainda não foram
                  visitados pelos usuários usando o seletor "a:link".
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a:link {"{"}</p>
                <p>&nbsp;color: blue; /* Define a cor do link */</p>
                <p>&nbsp;text-decoration: underline; /* Sublinha o link */</p>
                <p>{"}"}</p>
              </li>
              <br />
              <li>
                <p>Estilizar links visitados:</p>
                <p>
                  Você pode definir estilos para links que já foram visitados
                  pelos usuários usando o seletor "a:visited".
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a:visited {"{"}</p>
                <p>&nbsp;color: purple; /* Define a cor do link visitado */</p>
                <p>{"}"}</p>
              </li>
              <br />
              <li>
                <p>Estilizar links quando o mouse passa sobre eles:</p>
                <p>
                  Você pode definir estilos para links quando o cursor do mouse
                  passa sobre eles usando o seletor "a:hover".
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a:hover {"{"}</p>
                <p>
                  &nbsp;color: red; /* Define a cor do link quando o mouse passa
                  sobre ele */
                </p>
                <p>{"}"}</p>
              </li>
              <br />
              <li>
                <p>Estilizar links ativos:</p>
                <p>
                  Você pode definir estilos para links quando eles estão ativos
                  (por exemplo, quando clicados) usando o seletor "a:active".
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a:active {"{"}</p>
                <p>
                  &nbsp;color: green; /* Define a cor do link quando ele está
                  ativo */
                </p>
                <p>{"}"}</p>
              </li>
              <br />
              <li>
                <p>Remover decoração padrão de links:</p>
                <p>
                  Às vezes, você pode querer remover a decoração padrão de
                  links, como sublinhado, usando a propriedade "text-decoration"
                  com o valor "none".
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a {"{"}</p>
                <p>
                  &nbsp;text-decoration: none; /* Remove a decoração padrão de
                  links */
                </p>
                <p>{"}"}</p>
              </li>
              <br />
              <li>
                <p>Outros estilos de links:</p>
                <p>
                  Além das propriedades mencionadas acima, você pode aplicar
                  qualquer estilo CSS válido aos links, como fonte, espaçamento,
                  margens, etc.
                </p>
                <br />
                <p>Exemplo:</p>
                <p>a {"{"}</p>
                <p>
                  &nbsp;font-weight: bold; /* Define a espessura da fonte */
                </p>
                <p>
                  &nbsp;margin-right: 10px; /* Define uma margem à direita */
                </p>
                <p>{"}"}</p>
              </li>
              <br />
            </ol>
            <p>
              Essas são algumas maneiras de estilizar links usando CSS,
              permitindo que você personalize sua aparência de acordo com o
              design do seu site.
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Listas no CSS",
    video: "https://youtu.be/85J5mzf6sYg",
    introduction: "Aula sobre Listas em CSS",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Aula sobre Listas em CSS</h2>
            <h3>Introdução:</h3>
            <p>
              As listas são elementos fundamentais em HTML para organizar e
              apresentar informações de forma estruturada. No entanto, a sua
              aparência pode ser customizada usando CSS para se adequar ao
              design de um site. Nesta aula, vamos explorar as diferentes
              propriedades CSS que podem ser aplicadas a listas para
              estilizá-las de várias maneiras.
            </p>
            <ol>
              <li>
                <h3>Tipos de Listas:</h3>
                <ol>
                  <li>
                    <h4>Listas Não Ordenadas (ul):</h4>
                    <ul>
                      <li>
                        Utilizadas para listar itens sem uma ordem específica.
                      </li>
                      <li>
                        Marcadas por padrão com bullets (pontos, círculos,
                        quadrados).
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h4>Listas Ordenadas (ol):</h4>
                    <ul>
                      <li>
                        Utilizadas para listar itens em uma ordem específica.
                      </li>
                      <li>Marcadas por padrão com números ou letras.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                <h3>Propriedades CSS para Listas:</h3>
                <ol>
                  <li>
                    <h4>list-style-type:</h4>
                    <ul>
                      <li>Define o tipo de marcador usado nas listas.</li>
                      <li>
                        Valores comuns incluem: disc, circle, square, decimal,
                        lower-alpha, upper-alpha, lower-roman, upper-roman,
                        entre outros.
                      </li>
                    </ul>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul {"{"}</p>
                    <p>&nbsp;list-style-type: square;</p>
                    <p>{"}"}</p>
                    <p>```</p>
                    <br />
                  </li>
                  <li>
                    <h4>list-style-image:</h4>
                    <ul>
                      <li>
                        Permite especificar uma imagem personalizada como
                        marcador.
                      </li>
                      <li>
                        A imagem será exibida no lugar do marcador padrão.
                      </li>
                    </ul>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul {"{"}</p>
                    <p>
                      &nbsp;list-style-image: url('caminho/para/imagem.png');
                    </p>
                    <p>{"}"}</p>
                    <p>```</p>
                    <br />
                  </li>
                  <li>
                    <h4>list-style-position:</h4>
                    <ul>
                      <li>
                        Define se o marcador deve estar dentro (inside) ou fora
                        (outside) do contêiner da lista.
                      </li>
                      <li>Por padrão, o valor é outside.</li>
                    </ul>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul {"{"}</p>
                    <p>&nbsp;list-style-position: inside;</p>
                    <p>{"}"}</p>
                    <p>```</p>
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                <h3>Estilizando Listas Adicionais:</h3>
                <ol>
                  <li>
                    <h4>Removendo Marcadores:</h4>
                    <ul>
                      <li>
                        Às vezes, é desejável remover os marcadores padrão das
                        listas.
                      </li>
                      <li>
                        Isso pode ser feito definindo list-style-type como none.
                      </li>
                    </ul>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul.no-marker {"{"}</p>
                    <p>&nbsp;list-style-type: none;</p>
                    <p>{"}"}</p>
                    <p>```</p>
                    <br />
                  </li>
                  <li>
                    <h4>Alterando a Aparência de Links em Listas:</h4>
                    <ul>
                      <li>
                        Quando os itens de lista contêm links, é comum
                        estilizá-los de forma diferente para indicar sua
                        natureza interativa.
                      </li>
                    </ul>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul li a {"{"}</p>
                    <p>&nbsp;color: #007bff; /* Cor do link */</p>
                    <p>&nbsp;text-decoration: none; /* Remover sublinhado */</p>
                    <p>{"}"}</p>
                    <br />
                    <p>Exemplo:</p>
                    <p>```css</p>
                    <p>ul li a:hover {"{"}</p>
                    <p>
                      &nbsp;text-decoration: underline; /* Adicionar sublinhado
                      no hover */
                    </p>
                    <p>{"}"}</p>
                    <br />
                  </li>
                </ol>
              </li>
              <li>
                <h3>Conclusão:</h3>
                <p>
                  As propriedades CSS fornecem um grande controle sobre a
                  aparência de listas em páginas da web. Ao entender como
                  utilizar list-style-type, list-style-image,
                  list-style-position, e outras propriedades relacionadas, os
                  desenvolvedores podem criar designs personalizados e atraentes
                  para suas listas de forma eficaz.
                </p>
              </li>
            </ol>
          </section>
        </>
      );
    },
  },
  {
    name: "Display no CSS",
    video: "https://youtu.be/1bESu-hLwd4",
    introduction: "Resumo sobre display no CSS",
    isAvailable: true,
    Content: () => {
      return <></>;
    },
  },
];
