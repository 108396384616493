//React Dependencies
import { Link } from "react-router-dom";
import { useRef } from "react";

//Icons
import { FaBars, FaTimes } from "react-icons/fa"

//Images
import logo from "../../imgs/fullLogo.svg";

//Styles
import { Container } from "./style";

//Responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container)

export const LandingHeader = () => {
    const navRef = useRef()

    const toggleNav = ()=>{
        navRef.current.classList.toggle("reponsive-menu")
    }

    return (
        <MediaContainer>
            <Container>
                <Link to="/">
                    <img src={logo} alt="logo da SkCoders" width="220px" height="57" />
                </Link>
                <nav className="group-nav" ref={navRef}>
                    <nav className="header-nav">
                        <button className="nav-btn nav-close-btn" onClick={toggleNav}><FaTimes/></button>
                        <ul className="header-nav__landing-page-nav">
                            <li>
                                <Link to="/cursos">O que irá aprender?</Link>
                            </li>
                            <li>
                                <Link to="/sobre_nos">Sobre nós</Link>
                            </li>
                            <li>
                                <Link to="/discord">Contato</Link>
                            </li>
                        </ul>
                        
                    </nav>
                    <nav>
                        <ul className="header-nav__account-nav">
                            <li>
                                <Link to="/entrar" className="header-nav__account-nav__entrar">
                                    Entrar
                                </Link>
                            </li>
                            <li>
                                <Link to="/cadastro" className="create-account-header">
                                    Criar conta
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </nav>
                <button className="nav-btn" onClick={toggleNav}><FaBars/></button>
               
            </Container>
        </MediaContainer>
    );
};
