// react
import { useEffect } from "react";

// router dom
import { Router } from "./routes";

// Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";

// redux
import { useDispatch } from "react-redux";

import { setUserThunk } from "./store/modules/user/thunks";
import { setUserIsLoading, signIn, signOut } from "./store/modules/user/actions";

import { getStudentActivityThunk } from "./store/modules/student_activity/thunks";

// others
import { GlobalStyle } from "./styles/global";
import { Analytics } from "@vercel/analytics/react";
import { ScrollToTop } from "./components/scroll_to_top";
import { ToastContainer } from "react-toastify";

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const auth = getAuth();

      const unsubscribe = onAuthStateChanged(auth, (user) => {
        // dispatch(setUserIsLoading(true));

        if (user) {
          dispatch(signIn(user.refreshToken));
          dispatch(setUserThunk());
          dispatch(getStudentActivityThunk());
          dispatch(setUserIsLoading(false));
        } else {
          dispatch(signOut());
          dispatch(setUserIsLoading(false));
        }
      });
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Router />
      <ToastContainer />
      <Analytics />
    </>
  );
};

export default App;
