import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.white};
  main {
    display: flex;
  }
`;

export const TextContainer = styled.section`
  padding: 50px;
  width: calc(50vw - 100px);
  height: calc(100vh - 100px);
  border-radius: 0 15px 15px 0;
  background-color: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  h1 {
    margin: 0 0 10px;
    font-weight: normal;
    font-family: "Poppins", sans-serif;
    font-size: 2rem;
  }
  p {
    font-size: 1.2rem;
    margin: 10px 0 25px;
  }
  .skill-labs-link {
    display: flex;
    justify-content: start;
    width: 100%;
    a {
      color: ${({ theme }) => theme.white};
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const FormContainer = styled.section`
  padding: 40px 90px;
  width: calc(50vw - 180px);
  height: calc(100vh - 80px);
  .logo {
    height: 1.7rem;
    width: auto;
  }
  h1 {
    font-size: 2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 550;
    margin: 10px 0 0;
    color: ${({ theme }) => theme.darkBlue};
  }
  .form-container__subtitle {
    margin: 5px 0 25px;
    color: ${({ theme }) => theme.gray};
  }
  input {
    width: 100%;
    box-sizing: border-box;
  }

  .btn-submit {
    width: 100%;
    height: 2.5rem;
    margin: 30px 0 0;
    button {
      width: 100%;
      height: 100%;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }
    
  }
  .toRegister {
    text-align: center;
    width: calc(100% + 16px);
  }
  .toRegister__question {
    margin: 15px 0 5px;
    color: ${({ theme }) => theme.blue};
  }
  .toRegister__link {
    color: ${({ theme }) => theme.darkBlue};
    text-decoration: underline;
  }
  .toRegister__forgot-password {
    text-align: center;
    margin: 0;
  }
`;
