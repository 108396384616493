import { css } from "styled-components";
import v from "voca";

export const addInDiscord = () => {
  window.open("https://discord.gg/bQ9pXZnyB2", "_blank", "noreferrer");
};

export const enterToForum = () => {
  window.open(
    "https://discord.com/channels/1105124408605024329/1168995807442239488",
    "_blank",
    "noreferrer"
  );
};

export const nameDisplayer = (name) => {
  return name
    .split(" ")
    .filter((_, index, arrayName) => {
      if(arrayName.length === 1){
        return arrayName[0]
      }

      if (arrayName[1].length <= 2) {
        return index < 3;
      } else {
        return index < 2;
      }

    })
    .map((word) => v.capitalize(word, true))
    .join(" ");
};
 
export const takeVideoYoutubeId = (url) => {
  return url.split("/").reverse()[0];
};

//media screens for responsibility
const screenBreakpoints = {
  desktopAverage: 1280,
  desktop: 1024,
  desktopG:1170,
  desktopGG: 1300,
  tablet: 768,
  verticalTablet: 700,
  mobile: 376,
  mobileG: 425,
  mobileGG: 520
};

//create media queries
export const media = Object.keys(screenBreakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${screenBreakpoints[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});
