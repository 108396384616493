// Router Dom
import { Link } from "react-router-dom";

// components
import { LandingHeader } from "../../components/landing_header";
import { Video } from "../../components/video";

// styles
import { Container, FirstSection, CourseSection, LearnSection } from "./style";

export const LandingPageCourses = () => {
  return (
    <Container>
      <LandingHeader />
      <main>
        <FirstSection>
          <h1>
            Aprenda a programa
            <br />
            do seu jeito e no seu tempo.
          </h1>
          <p>
            Criamos trilhas de ensino que te prepara para dar os primeiros
            passos no mundo da tecnologia, gerando novas oportunidades em sua
            vida profissional.
          </p>
          <Link to="/cadastro" className="create-account">
            Criar conta
          </Link>
        </FirstSection>
        <CourseSection>
          <p>Curso</p>
          <h1>
            O que você irá aprender no nosso Programa de Formação Fullstack
          </h1>
          <Video url="https://youtu.be/i2WnU9FY-Zg" />
        </CourseSection>
        <LearnSection>
          <section className="main-text">
            <article>
              <p className="main-text__subtitle">Conteúdo</p>
              <h1>O que irá aprender</h1>
              <p className="main-text__text">
                Nosso programa abrangente aborda uma ampla gama de tópicos
                essenciais em desenvolvimento web, garantindo que você esteja
                preparado para enfrentar os desafios do mundo da programação.
                Aqui estão alguns dos principais conhecimentos e habilidades que
                você adquirirá durante o programa
              </p>
            </article>
          </section>
          <section></section>
        </LearnSection>
      </main>
    </Container>
  );
};
