import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  height: 100%;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 20;
  .close {
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .modal_content {
    background: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    position: fixed;
    width: 70%;
    height: 90vh;
    padding: 1rem 2rem;
  }

  .close-button {
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;
