export const ddi = [
  { value: "213", name: "Algeria (+213)" },
  { value: "376", name: "Andorra (+376)" },
  { value: "244", name: "Angola (+244)" },
  { value: "1264", name: "Anguilla (+1264)" },
  { value: "1268", name: "Antigua &amp; Barbuda (+1268)" },
  { value: "54", name: "Argentina (+54)" },
  { value: "374", name: "Armenia (+374)" },
  { value: "297", name: "Aruba (+297)" },
  { value: "61", name: "Australia (+61)" },
  { value: "43", name: "Austria (+43)" },
  { value: "994", name: "Azerbaijan (+994)" },
  { value: "1242", name: "Bahamas (+1242)" },
  { value: "973", name: "Bahrain (+973)" },
  { value: "880", name: "Bangladesh (+880)" },
  { value: "1246", name: "Barbados (+1246)" },
  { value: "375", name: "Belarus (+375)" },
  { value: "32", name: "Belgium (+32)" },
  { value: "501", name: "Belize (+501)" },
  { value: "229", name: "Benin (+229)" },
  { value: "1441", name: "Bermuda (+1441)" },
  { value: "975", name: "Bhutan (+975)" },
  { value: "591", name: "Bolivia (+591)" },
  { value: "387", name: "Bosnia Herzegovina (+387)" },
  { value: "267", name: "Botswana (+267)" },
  { value: "55", name: "Brazil (+55)" },
  { value: "673", name: "Brunei (+673)" },
  { value: "359", name: "Bulgaria (+359)" },
  { value: "226", name: "Burkina Faso (+226)" },
  { value: "257", name: "Burundi (+257)" },
  { value: "855", name: "Cambodia (+855)" },
  { value: "237", name: "Cameroon (+237)" },
  { value: "1", name: "Canada (+1)" },
  { value: "238", name: "Cape Verde Islands (+238)" },
  { value: "1345", name: "Cayman Islands (+1345)" },
  { value: "236", name: "Central African Republic (+236)" },
  { value: "56", name: "Chile (+56)" },
  { value: "86", name: "China (+86)" },
  { value: "57", name: "Colombia (+57)" },
  { value: "269", name: "Comoros (+269)" },
  { value: "242", name: "Congo (+242)" },
  { value: "682", name: "Cook Islands (+682)" },
  { value: "506", name: "Costa Rica (+506)" },
  { value: "385", name: "Croatia (+385)" },
  { value: "53", name: "Cuba (+53)" },
  { value: "90392", name: "Cyprus North (+90392)" },
  { value: "357", name: "Cyprus South (+357)" },
  { value: "42", name: "Czech Republic (+42)" },
  { value: "45", name: "Denmark (+45)" },
  { value: "253", name: "Djibouti (+253)" },
  { value: "1809", name: "Dominica (+1809)" },
  { value: "1809", name: "Dominican Republic (+1809)" },
  { value: "593", name: "Ecuador (+593)" },
  { value: "20", name: "Egypt (+20)" },
  { value: "503", name: "El Salvador (+503)" },
  { value: "240", name: "Equatorial Guinea (+240)" },
  { value: "291", name: "Eritrea (+291)" },
  { value: "372", name: "Estonia (+372)" },
  { value: "251", name: "Ethiopia (+251)" },
  { value: "500", name: "Falkland Islands (+500)" },
  { value: "298", name: "Faroe Islands (+298)" },
  { value: "679", name: "Fiji (+679)" },
  { value: "358", name: "Finland (+358)" },
  { value: "33", name: "France (+33)" },
  { value: "594", name: "French Guiana (+594)" },
  { value: "689", name: "French Polynesia (+689)" },
  { value: "241", name: "Gabon (+241)" },
  { value: "220", name: "Gambia (+220)" },
  { value: "7880", name: "Georgia (+7880)" },
  { value: "49", name: "Germany (+49)" },
  { value: "233", name: "Ghana (+233)" },
  { value: "350", name: "Gibraltar (+350)" },
  { value: "30", name: "Greece (+30)" },
  { value: "299", name: "Greenland (+299)" },
  { value: "1473", name: "Grenada (+1473)" },
  { value: "590", name: "Guadeloupe (+590)" },
  { value: "671", name: "Guam (+671)" },
  { value: "502", name: "Guatemala (+502)" },
  { value: "224", name: "Guinea (+224)" },
  { value: "245", name: "Guinea - Bissau (+245)" },
  { value: "592", name: "Guyana (+592)" },
  { value: "509", name: "Haiti (+509)" },
  { value: "504", name: "Honduras (+504)" },
  { value: "852", name: "Hong Kong (+852)" },
  { value: "36", name: "Hungary (+36)" },
  { value: "354", name: "Iceland (+354)" },
  { value: "91", name: "India (+91)" },
  { value: "62", name: "Indonesia (+62)" },
  { value: "98", name: "Iran (+98)" },
  { value: "964", name: "Iraq (+964)" },
  { value: "353", name: "Ireland (+353)" },
  { value: "972", name: "Israel (+972)" },
  { value: "39", name: "Italy (+39)" },
  { value: "1876", name: "Jamaica (+1876)" },
  { value: "81", name: "Japan (+81)" },
  { value: "962", name: "Jordan (+962)" },
  { value: "7", name: "Kazakhstan (+7)" },
  { value: "254", name: "Kenya (+254)" },
  { value: "686", name: "Kiribati (+686)" },
  { value: "850", name: "Korea North (+850)" },
  { value: "82", name: "Korea South (+82)" },
  { value: "965", name: "Kuwait (+965)" },
  { value: "996", name: "Kyrgyzstan (+996)" },
  { value: "856", name: "Laos (+856)" },
  { value: "371", name: "Latvia (+371)" },
  { value: "961", name: "Lebanon (+961)" },
  { value: "266", name: "Lesotho (+266)" },
  { value: "231", name: "Liberia (+231)" },
  { value: "218", name: "Libya (+218)" },
  { value: "417", name: "Liechtenstein (+417)" },
  { value: "370", name: "Lithuania (+370)" },
  { value: "352", name: "Luxembourg (+352)" },
  { value: "853", name: "Macao (+853)" },
  { value: "389", name: "Macedonia (+389)" },
  { value: "261", name: "Madagascar (+261)" },
  { value: "265", name: "Malawi (+265)" },
  { value: "60", name: "Malaysia (+60)" },
  { value: "960", name: "Maldives (+960)" },
  { value: "223", name: "Mali (+223)" },
  { value: "356", name: "Malta (+356)" },
  { value: "692", name: "Marshall Islands (+692)" },
  { value: "596", name: "Martinique (+596)" },
  { value: "222", name: "Mauritania (+222)" },
  { value: "269", name: "Mayotte (+269)" },
  { value: "52", name: "Mexico (+52)" },
  { value: "691", name: "Micronesia (+691)" },
  { value: "373", name: "Moldova (+373)" },
  { value: "377", name: "Monaco (+377)" },
  { value: "976", name: "Mongolia (+976)" },
  { value: "1664", name: "Montserrat (+1664)" },
  { value: "212", name: "Morocco (+212)" },
  { value: "258", name: "Mozambique (+258)" },
  { value: "95", name: "Myanmar (+95)" },
  { value: "264", name: "Namibia (+264)" },
  { value: "674", name: "Nauru (+674)" },
  { value: "977", name: "Nepal (+977)" },
  { value: "31", name: "Netherlands (+31)" },
  { value: "687", name: "New Caledonia (+687)" },
  { value: "64", name: "New Zealand (+64)" },
  { value: "505", name: "Nicaragua (+505)" },
  { value: "227", name: "Niger (+227)" },
  { value: "234", name: "Nigeria (+234)" },
  { value: "683", name: "Niue (+683)" },
  { value: "672", name: "Norfolk Islands (+672)" },
  { value: "670", name: "Northern Marianas (+670)" },
  { value: "47", name: "Norway (+47)" },
  { value: "968", name: "Oman (+968)" },
  { value: "680", name: "Palau (+680)" },
  { value: "507", name: "Panama (+507)" },
  { value: "675", name: "Papua New Guinea (+675)" },
  { value: "595", name: "Paraguay (+595)" },
  { value: "51", name: "Peru (+51)" },
  { value: "63", name: "Philippines (+63)" },
  { value: "48", name: "Poland (+48)" },
  { value: "351", name: "Portugal (+351)" },
  { value: "1787", name: "Puerto Rico (+1787)" },
  { value: "974", name: "Qatar (+974)" },
  { value: "262", name: "Reunion (+262)" },
  { value: "40", name: "Romania (+40)" },
  { value: "7", name: "Russia (+7)" },
  { value: "250", name: "Rwanda (+250)" },
  { value: "378", name: "San Marino (+378)" },
  { value: "239", name: "Sao Tome &amp; Principe (+239)" },
  { value: "966", name: "Saudi Arabia (+966)" },
  { value: "221", name: "Senegal (+221)" },
  { value: "381", name: "Serbia (+381)" },
  { value: "248", name: "Seychelles (+248)" },
  { value: "232", name: "Sierra Leone (+232)" },
  { value: "65", name: "Singapore (+65)" },
  { value: "421", name: "Slovak Republic (+421)" },
  { value: "386", name: "Slovenia (+386)" },
  { value: "677", name: "Solomon Islands (+677)" },
  { value: "252", name: "Somalia (+252)" },
  { value: "27", name: "South Africa (+27)" },
  { value: "34", name: "Spain (+34)" },
  { value: "94", name: "Sri Lanka (+94)" },
  { value: "290", name: "St. Helena (+290)" },
  { value: "1869", name: "St. Kitts (+1869)" },
  { value: "1758", name: "St. Lucia (+1758)" },
  { value: "249", name: "Sudan (+249)" },
  { value: "597", name: "Suriname (+597)" },
  { value: "268", name: "Swaziland (+268)" },
  { value: "46", name: "Sweden (+46)" },
  { value: "41", name: "Switzerland (+41)" },
  { value: "963", name: "Syria (+963)" },
  { value: "886", name: "Taiwan (+886)" },
  { value: "7", name: "Tajikstan (+7)" },
  { value: "66", name: "Thailand (+66)" },
  { value: "228", name: "Togo (+228)" },
  { value: "676", name: "Tonga (+676)" },
  { value: "1868", name: "Trinidad &amp; Tobago (+1868)" },
  { value: "216", name: "Tunisia (+216)" },
  { value: "90", name: "Turkey (+90)" },
  { value: "7", name: "Turkmenistan (+7)" },
  { value: "993", name: "Turkmenistan (+993)" },
  { value: "1649", name: "Turks &amp; Caicos Islands (+1649)" },
  { value: "688", name: "Tuvalu (+688)" },
  { value: "256", name: "Uganda (+256)" },
  { value: "44", name: "UK (+44)" },
  { value: "380", name: "Ukraine (+380)" },
  { value: "971", name: "United Arab Emirates (+971)" },
  { value: "598", name: "Uruguay (+598)" },
  { value: "1", name: "USA (+1)" },
  { value: "7", name: "Uzbekistan (+7)" },
  { value: "678", name: "Vanuatu (+678)" },
  { value: "379", name: "Vatican City (+379)" },
  { value: "58", name: "Venezuela (+58)" },
  { value: "84", name: "Vietnam (+84)" },
  { value: "84", name: "Virgin Islands - British (+1284)" },
  { value: "84", name: "Virgin Islands - US (+1340)" },
  { value: "681", name: "Wallis &amp; Futuna (+681)" },
  { value: "969", name: "Yemen (North)(+969)" },
  { value: "967", name: "Yemen (South)(+967)" },
  { value: "260", name: "Zambia (+260)" },
  { value: "263", name: "Zimbabwe (+263)" },
];
