import styled from "styled-components";

export const Container = styled.main`
  background-color: ${({ theme }) => theme.lighterBlue};
  .bold-gray {
    font-size: 1.1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.darkGray};
  }
  .line {
    border: 1px solid ${({ theme }) => theme.gray};
    margin: 30px 0;
  }
  .profile {
    width: calc(100% - 320px);
    padding-left: 150px;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 15px;

    svg {
      fill: ${({ theme }) => theme.darkBlue};
      font-size: 1.5rem;
      cursor: pointer;
      margin-right: -32px;
    }
  }
  .card-header--white {
    svg {
      fill: ${({ theme }) => theme.white};
    }
  }
  .continue-in-module__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
    h2 {
      color: ${({ theme }) => theme.darkGray};
      font-size: 2rem;
      margin-left: 20px;
    }
    span {
      color: ${({ theme }) => theme.darkGray};
      font-size: 2rem;
    }
  }
  .continue-in-module__cards-module {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 2rem 2.5rem 0;
  }
  .continue-in-module__card-module {
    width: 280px;

    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 2.5rem;
    .continue-in-module__icon {
      background-color: ${({ theme }) => theme.white};
      border: 1px solid ${({ theme }) => theme.ice};
      width: 280px;
      height: 280px;
      padding: 0 10px;
      color: ${({ theme }) => theme.darkGray};
      font-size: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      position: relative;
      margin-bottom: 10px;
    }
    .continue-in-module__card-module__text {
      width: 100%;
      padding: 0 10px;
    }
    .continue-in-module__card-module__text__head {
      display: flex;
      justify-content: space-between;
      align-items: start;
      h3 {
        max-width: 220px;
        height: 65px;
      }
      p {
        margin-top: 5px;
      }
    }
    h3 {
      color: ${({ theme }) => theme.darkGray};
      font-size: 1.5rem;
      margin: 0;
      text-align: left;
    }
    p {
      color: ${({ theme }) => theme.darkGray};
      margin: 0;
    }
    .continue-in-module__description {
      text-align: left;
      margin: 1rem 0;
      height: 3rem;
    }
    button {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }
  .continue-in-module__card-module__text {
    text-align: center;
  }
  .projects {
    margin-top: 60px;
  }
  .projects__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
    h2 {
      color: ${({ theme }) => theme.darkGray};
      font-size: 2rem;
      margin-left: 20px;
    }
    span {
      color: ${({ theme }) => theme.darkGray};
      font-size: 2rem;
    }
  }
  .projects__cards-project {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 2rem 2.5rem 0;
  }
  .projects__card-project {
    padding: 10px;
    width: 280px;
    /* height: 480px; */
    background-color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.ice};
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    .projects__card-project__text {
      padding: 15px;
      display: flex;
      align-items: center;
      flex-direction: column;
      ul {
        padding: 0;
      }
      li {
        list-style-type: none;
        text-align: center;
        border: 1px solid ${({ theme }) => theme.darkGray};
        border-radius: 7px;
        color: ${({ theme }) => theme.darkGray};
        width: 4rem;
        padding: 5px;
      }
    }
    .projects__icon {
      color: ${({ theme }) => theme.darkGray};
      font-size: 140px;
      border: 1px solid ${({ theme }) => theme.ice};
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      .projects__tag {
        position: absolute;
        border: 1px solid ${({ theme }) => theme.darkGray};
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        font-weight: 700;
        padding: 0.2rem 0.5rem;
        border-radius: 15px;
        font-size: 0.8rem;
        color: ${({ theme }) => theme.darkGray};
      }
    }
    .projects__level {
      display: flex;
      align-items: center;
      align-self: flex-start;
      color: ${({ theme }) => theme.darkGray};
    }
    .projects__level__circle {
      margin-left: 1rem;
      border: 1px solid ${({ theme }) => theme.darkGray};
      border-radius: 15px;
      width: 10px;
      height: 10px;
    }
    .projects__level__circle--filled {
      background-color: ${({ theme }) => theme.darkGray};
    }
    h3 {
      color: ${({ theme }) => theme.darkGray};
      font-size: 1.5rem;
      margin: 2rem 0 0;
    }
    p {
      color: ${({ theme }) => theme.darkGray};
      margin: 0;
    }
    .projects__description {
      text-align: center;
      margin: 0.2rem 0;
      height: 4rem;
    }
    button {
      background-color: ${({ theme }) => theme.blue};
      color: #fff;
      font-weight: bold;
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      cursor: pointer;
      border: none;
      width: 120px;
      height: 30px;
      font-size: 1rem;
      margin: 1rem;
      align-self: center;
    }
  }
  .geral-infos {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .modal-skill-box {
    h2 {
      text-align: center;
    }
    .open-skill-input-box {
      justify-content: center;
    }
  }
`;

export const AsideMenu = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 288px;
  height: 100vh;
  padding: 16px 0 0 0;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.ice};
  ul {
    padding: 0 0 0 32px;
  }
  li {
    list-style-type: none;
    padding: 1rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.darkGray};
  }
  .aside-item {
    color: ${({ theme }) => theme.darkGray};
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 8px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const HeaderSection = styled.div`
  .continue-studies {
    background-color: ${({ theme }) => theme.boxGray};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    border: none;
  }
`;

export const IntroductionSection = styled.section`
  width: 100%;
  height: auto;
  position: relative;

  .card-name {
    display: flex;
    padding: 0 20px 0 0;
  }
  .cover {
    height: 200px;
    width: 100vw;
    position: relative;
    left: -150px;
    background: ${({ coverBackgroundUrl, theme }) =>
      coverBackgroundUrl ? "url(" + coverBackgroundUrl + ")" : theme.lightGray};
    background-size: cover;
    position: relative;
    right: 32px;
    .card-header {
      position: absolute;
      top: 35px;
      right: 50px;
    }
  }
`;

export const CardNameStudent = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 32px;
  color: ${({ theme }) => theme.darkBlue};
  position: relative;
  bottom: 50px;
  width: 100%;
  .card-name__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 5px solid ${({ theme }) => theme.white};
    overflow: hidden;
    font-size: 50px;
    color: black;
    font-weight: bolder;
    background-color: #fff;
    margin-right: 16px;
    img {
      width: 100%;
      min-height: 180px;
      object-fit: cover;
    }
  }

  .card-name__text {
    width: 320px;
  }
  p {
    margin: 0;
  }
  .first-name {
    height: 3rem;
    font-size: 1.7rem;
    font-weight: bold;
    span {
      display: inline-block;
      margin-right: 16px;
    }
  }
  .continue-studies {
    background-color: ${({ theme }) => theme.blue};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    border: none;
    position: absolute;
    top: 70px;
    right: 10px;
  }
`;

export const CardAboutMe = styled.div`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.ice};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  margin: 0px 0 0 20px;
  width: 40%;
  padding: 30px 50px;
  section {
    color: ${({ theme }) => theme.darkBlue};

    h1 {
      font-size: 1.5rem;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 18px;
      line-height: 1.3;
    }
  }
`;

export const Progress = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 35%;
  height: 300px;
  background-color: #121010;
  border-radius: 15px;
  font-size: 32px;
  color: #ffffff;
  position: relative;
  h1 {
    font-size: 2rem;
    margin: 0;
  }
  .msg-progess {
    font-size: 1rem;
    color: #ed7014;
  }
  .bar-progress {
    width: 250px;
    background-color: #ed7014;
    height: 0.5rem;
    display: flex;
    align-items: center;
    > div {
      margin-left: 1rem;
      border-radius: 15px;
      background-color: #ed7014;
      font-size: 1rem;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #121010;
    }
  }
`;

export const SectionInfo = styled.div`
  width: 40%;
  color: ${(theme) => theme.darkBlue};
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.ice};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  padding: 30px 50px 20px;
  margin: 0px 50px 0 20px;
  position: relative;
  .card-header {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  h2 {
    margin: 20px 0 5px;
    font-size: 1rem;
    color: ${({ theme }) => theme.darkGray};
    /* font-weight: 600; */
  }
  ul {
    padding: 0;
  }

  li {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-weight: 700;
  }
  .skills {
    display: flex;
    flex-wrap: wrap;
  }
  .skill {
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border: 1px solid ${(theme) => theme.darkBlue};
    padding: 0.2rem 0.5rem;
    border-radius: 15px;
    text-align: center;
    font-size: 0.8rem;
    margin: 5px;
  }
  .add-skill-button {
    background-color: transparent;
    cursor: pointer;
  }

  .address-box {
    position: relative;
  }

  .address {
    padding: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    font-size: 1.1rem;
    svg {
      font-size: 1.3rem;
      margin: 0 5px 0 0;
    }
  }

  .web-address-list {
    margin: 0;
  }
  .web-address-name {
    margin-right: 15px;
  }

  svg {
    margin: 0 15px 0 0;
    fill: ${(theme) => theme.darkBlue};
    font-size: 25px;
    vertical-align: middle;
    cursor: pointer;
  }

  a {
    color: ${({ theme }) => theme.darkBlue};
    font-size: 1.1rem;
  }
`;
export const Form = styled.form`
  color: ${({ theme }) => theme.white};
  textarea {
    background-color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.boxGray};
    resize: none;
    margin-top: 15px;
    height: 200px;
    overflow: auto;
    font-size: 18px;
  }

  label {
    font-size: 32px;
  }

  .open-skill-input-box {
    justify-content: start;
  }
`;
export const SocialsInput = styled.div`
  margin: 35px 0 30px 0;
  div {
    opacity: 1 !important;
  }
  input {
    width: 100%;
    border: 2px solid #121010;
    background-color: #000000;
    padding-left: 158px;
    color: #ffffff;
    position: relative;
    font-size: 18px;
    box-sizing: border-box;
  }

  div .iconBg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-left: 20px;
    max-width: 150px;
    height: 3rem;
    border: 2px solid #121010;
    border-radius: 15px;
    background-color: #121010;
    font-size: 20px;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;

    svg {
      font-size: 20px;
    }
  }
`;

export const AvatarInput = styled.div`
  margin: 55px 0 50px 0;
  label {
    color: ${({ theme }) => theme.darkBlue};
  }
  input {
    margin-left: 30px;
  }
`;

export const CoverBackgroundInput = styled.div`
  margin: 100px 0 100px 0;
  input {
    margin-left: 30px;
  }
  label {
    color: ${({ theme }) => theme.darkBlue};
  }
`;

export const DataUserInput = styled.div`
  margin: 35px 0 30px 0;
  display: flex;
  justify-content: space-between;

  > div {
    width: 30%;
  }

  .max-large {
    width: 100%;
  }

  .medium-large {
    width: 50%;
  }

  div {
    opacity: 1 !important;
  }
  input {
    width: 100%;
    border: 2px solid ${({ theme }) => theme.boxGray};
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.darkBlue};
    position: relative;
    font-size: 18px;
    box-sizing: border-box;
    padding-left: 158px;
  }
  select {
    width: 100%;
    border: 2px solid ${({ theme }) => theme.boxGray};
    color: ${({ theme }) => theme.darkBlue};
    font-size: 18px;
  }
  .arrow {
    top: 45px;
    svg {
      color: ${({ theme }) => theme.darkBlue};
    }
  }

  .add-skill-button {
    background-color: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.white};
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .profile .aboutExp {
    color: ${({ theme }) => theme.white};
  }

  div .iconBg {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding-left: 20px;
    max-width: 150px;
    height: 3rem;
    border: 2px solid ${({ theme }) => theme.boxGray};
    border-radius: ${({ theme }) => theme.defaultBorderRadius} 15px 15px
      ${({ theme }) => theme.defaultBorderRadius};
    background-color: ${({ theme }) => theme.boxGray};
    font-size: 20px;
    position: absolute;
    z-index: 1;
    box-sizing: border-box;

    svg {
      font-size: 20px;
    }
  }
  button {
    margin: 8px 0 0 15px;
    height: 100%;
  }
`;

export const BtnGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  opacity: 1;
  div {
    opacity: 1 !important;
  }
  button {
    background: ${({ theme }) => theme.darkBlue};
    color: ${({ theme }) => theme.white};
    font-size: 1.2rem;
  }
`;

export const SkillsStyled = styled.ul`
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    font-weight: 700;
    border: 1px solid ${({ theme }) => theme.darkBlue};
    padding: 0.2rem 0.5rem;
    border-radius: 15px;
    font-size: 0.8rem;
    margin-right: 10px;
    color: ${({ theme }) => theme.darkBlue};
    button {
      background-color: transparent;
      border: none;
      padding: 0 0 0 8px;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;
