import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (
    Container,
    FirstSection,
    Techs,
    AboutProgram,
    Advantages,
    Participate,
    Motivations,
    Community,
    Footer,
) => {

    return styled.div`
        ${Container}{
            ${media.desktopGG`
                img{
                    width: 100%;
                }

                ${Motivations}{
                    
                    .cards{
                        justify-content: center;
                        article{
                            max-width: 800px;
                            width: auto;
                            .cards__content{
                                img{
                                    margin: 32px 0;
                                }
                            }

                            p{
                                padding: 32px;
                                font-size: 0.9rem;
                                line-height: 28px;
                            }
                        }
                    }
                }

                ${Community}{
                    padding: 180px 20px 40px;
                    .in-call{
                        min-width: 400px;
                        max-width: 500px;
                        box-sizing: border-box;
                    }
                    .content{
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        h1{
                            font-size: clamp(1.2rem, 3vw, 2.7rem);
                        }

                        p{
                            font-size: 0.8rem;
                        }
                        nav{
                            margin: 0px 0px 10px;
                        }

                        a{
                            max-width: 40px
                        }
                    }
                }
            `}

            ${media.desktopG`
                ${FirstSection}{
                    .btn-account{
                        margin-right: 8px
                    }

                    .btn-theme-white{
                        display: inline;
                    }
                }

                ${Participate}{
                    flex-direction: column-reverse;
                    padding: 0 40px 60px 40px;
    
                    .models-left, .models-right{
                        display: flex;
                        flex-direction: column;
                     
                    }

                    .models-left{
                        top:40px;
                        margin-right: 20px;
                    }

                    .models-right{
                        top: -50px
                    }

                    img{
                      max-width: 200px;
                    }

                    article{
                        min-width: auto;
                        max-width: 600px;
                    }

                    p{
                        width: 100%;
                    }
                }

                ${Advantages}{
                    p{
                        max-width: 270px
                    }
                }
            `}

            ${media.tablet`
                ${Advantages}{
                    grid-template-columns: 1fr 1fr;
                }

                ${AboutProgram}{
                    padding: 0px 50px;
                  
                }

                ${Motivations}{
                    padding: 301px 20px 0px;
                }

                
            `}

            ${media.mobileGG`
                ${FirstSection}{
                    padding: 30px 20px;

                    h1,p{
                        text-align: center;
                    }

                    .btn-account{
                        display: inline-block;
                        padding: 10px 0;
                        width: 100%;
                        text-align: center;
                        margin-bottom: 12px;
                    }
                }

                ${Techs}{
                    padding: 40px;
                   
                }

                ${AboutProgram}{
                    padding: 0 20px;

                    p{
                        font-size:1rem;
                        margin: 30px 0px 0px;
                    }
                }

                ${Advantages}{
                    grid-template-columns: 1fr;
                    padding: 100px 20px 30px;
                    text-align: center;

                    p{
                        max-width: 425px;
                        
                    }

                    article{
                        margin: 0px 0px 50px;
                    }
                }

                ${Participate}{
                    h1{
                        font-size: 2rem;
                        margin-top: 80px
                    }

                    p{
                        font-size: 1rem;
                        margin-bottom: 20px;
                    }

                    .btn-account{
                        display: inline-block;
                        padding: 10px 0px;
                        width: 100%;
                        text-align: center
                    }
                }

                ${Motivations}{
                    padding: 100px 20px 0px;
                    article{
                        padding: 0
                    }

                    h1{
                        font-size: 1.6rem;
                        margin: 0px auto 90px;
                        
                    }

                    h2{
                        width: auto;
                        font-size: 1rem;
                    }

                    .cards article p{
                        padding: 20px;
                        line-height: 1.8;
                    }

                    .cards__header{
                        flex-direction: column;
                        svg{
                            margin: 40px 0 15px 0;
                            padding: 0;
                            font-size: 2rem;
                        }
                    }

                    .cards article .cards__content{
                        position: relative;
                        align-items: center;
                    }

                    img{
                        z-index: 0;
                        position: absolute;
                        right: 0px;
                        height: 80%;
                        box-sizing: border-box;
                        opacity: 0.1;
                        max-width: 150px;
                        object-fit: cover;
                        object-position: center;
                    }

                }
                
                ${Community}{
                    flex-direction: column;

                    .in-call{
                        min-width: auto;
                        margin-bottom: 12px
                    }

                    .content{
                        margin-left: 0;

                        a{
                            width: 100%;
                            padding: 10px 0;
                            text-align: center;
                            max-width: 100%;
                        }
                    }
                }

                ${Footer}{
                    padding: 60px 20px 5px 20px;
                    align-items: center;
                }
            `}
        }
    `
}