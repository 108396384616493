import styled from "styled-components";
import { theme } from "../../styles/global";

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #ffffff;
    border-radius: ${theme.defaultBorderRadius};
    padding: 0 2rem 0.5rem;
    max-width: 15rem;
    height: 95%;
    input {
      width: 14rem;
    }
  }
`;
