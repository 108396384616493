import styled from "styled-components";

export const Container = styled.div`
  margin: 32px 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    color: ${({ theme, selected }) => (selected ? theme.blue : theme.darkBlue)};
    font-size: 1.2rem;
    text-decoration: ${({ selected }) => (selected ? "underline" : "none")};
  }
  button {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    color: ${({ theme, selected }) => (selected ? theme.blue : theme.darkBlue)};
    font-size: 1.2rem;
    padding: 0;
    cursor: pointer;
  }
`;
