// react
import { useState, useEffect } from "react";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateRegister,
  reinicializerRegister,
} from "../../store/modules/register/actions";

// router
import { useParams, useNavigate } from "react-router-dom";

// components
import { TextInput } from "../../components/text_input";
import { DateInput } from "../../components/date_input";
import { PhoneNumberInput } from "../../components/input_phone";
import { CheckboxInput } from "../../components/checkbox_input";
import { SelectInput } from "../../components/select_input";
import { Button } from "../../components/button";
import { Modal } from "../../components/modal";
import { PasswordInput } from "../../components/password_input";

// style
import { motion, useCycle, AnimatePresence } from "framer-motion";
import {
  ContentModel,
  ButtonGroups,
  PasswordRequirements,
  FieldsetStyled,
} from "./style";

// mock
import { termsOfUse } from "../../service/mocks";

// others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ddi } from "../../service/util/ddi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createUseWithEmail,
  newUserCreateDocument,
  updateUserData,
} from "../../service/userManagement";
import { addInDiscord } from "../../service/util/util";

export const Step1 = ({ setStep }) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  let { linkId } = useParams();

  const options = ddi;

  const formSchema = yup.object().shape({
    name: yup.string().required("Nome completo obrigatório"),
    email: yup.string().required("Email obrigatório").email("Email inválido"),
    ddi: yup.string().required("Obrigatório"),
    phone: yup
      .string()
      .matches(
        /^(?:(?:\+|00)?(55)\s?)?(\(?([1-9][0-9])\)?\s?)((?:9\d|[2-9])\d{3})-?(\d{4})$/,
        "Número inválido"
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (state.register.name) {
      setValue("name", state.register.name);
    }
    if (state.register.email) {
      setValue("email", state.register.email);
    }
    if (state.register.ddi) {
      setValue("ddi", state.register.ddi);
    } else {
      setValue("ddi", "55");
    }
    if (state.register.phone) {
      setValue("phone", state.register.phone);
    }
  }, []);

  const onSubmitFunction = (registerData) => {
    const RegisterLinkId = linkId || "";
    dispatch(updateRegister({ ...registerData, RegisterLinkId }));
    setStep(2);
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmitFunction)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <TextInput
        title="Nome"
        name="name"
        placeholder="Nome completo"
        register={register}
        errors={errors}
      />
      <TextInput
        title="Email"
        name="email"
        placeholder="Email"
        register={register}
        errors={errors}
      />
      <div className="phone-box">
        <SelectInput
          title="DDI"
          placeholder="DDI"
          name="ddi"
          options={options}
          register={register}
          errors={errors}
          className="ddi"
        />
        <PhoneNumberInput
          title="Telefone"
          name="phone"
          placeholder="(XX) XXXXX-XXXX"
          mask="(99) 99999-9999"
          register={register}
          errors={errors}
          className="phone"
        />
      </div>
      <Button className="btn-submit">Continuar</Button>
    </motion.form>
  );
};

export const Step2 = ({ setStep }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [isVisible, toggleOpen] = useCycle(false, true);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const options = [{ name: "Curso fullstack", value: "curso_fullstack" }];

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .required("Senha obrigatória")
      .min(8, "Senha deve ter mais de 8 caractéres")
      .matches(/^.*[A-Z]+.*$/, "Senha deve ter pelo menos uma letra maiúscula")
      .matches(/^.*[a-z]+.*$/, "Senha deve ter pelo menos uma letra minúscula")
      .matches(/^.*[$*&@#]+.*$/, "Senha deve ter pelo menos um símbolo")
      .matches(/^.*[0-9]+.*$/, "Senha deve ter pelo menos um número"),
    confirmPassword: yup
      .string()
      .required("Confimação obrigatória")
      .oneOf([yup.ref("password")], "Deve ser igual ao campo Senha"),
    program: yup.string().required("Selecionar o programa é obrigatório"),
    needComputer: yup.boolean().oneOf([true], " ***"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (state.register.password) {
      setValue("password", state.register.password);
    }
    if (state.register.password) {
      setValue("confirmPassword", state.register.password);
    }
    if (state.register.program) {
      setValue("program", "curso_fullstack");
    }
    if (state.register.program) {
      setValue("needComputer", true);
    }
  }, []);

  const onSubmitFunction = ({
    needComputer,
    terms,
    confirmPassword,
    ...registerData
  }) => {
    dispatch(updateRegister(registerData));
    setStep(3);
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmitFunction)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <PasswordInput
        title="Senha"
        name="password"
        value={currentPassword}
        onChange={(event) => setCurrentPassword(event.target.value)}
        register={register}
        errors={errors}
        placeholder="senha"
        isVisible={isVisible}
        toggleOpen={toggleOpen}
      />
      <PasswordInput
        title="Confirme a senha"
        name="confirmPassword"
        placeholder="Confirme a senha"
        register={register}
        errors={errors}
        isVisible={isVisible}
        toggleOpen={toggleOpen}
      />
      <PasswordRequirements currentPassword={currentPassword}>
        <AnimatePresence>
          {!!currentPassword && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <li className="password-box__min">8 caractéres</li>
              <li className="password-box__uppercase">maiúscula</li>
              <li className="password-box__lowercase">minúscula</li>
              <li className="password-box__symbol">símbolo</li>
              <li className="password-box__number">número</li>
            </motion.ul>
          )}
        </AnimatePresence>
      </PasswordRequirements>
      <SelectInput
        title="Programa"
        placeholder="Programa que irá participar"
        name="program"
        options={options}
        register={register}
        errors={errors}
      />
      <div className="need-computer-box">
        <CheckboxInput
          name="needComputer"
          id="need-computer"
          className="form__checkbox-box"
          register={register}
          errors={errors}
        >
          Você precisa de um computador para realizar o curso
        </CheckboxInput>
      </div>
      <ButtonGroups>
        <Button
          type="button"
          className="back-button"
          onClick={() => setStep(1)}
        >
          Voltar
        </Button>
        <Button className="btn-submit">Continuar</Button>
      </ButtonGroups>
    </motion.form>
  );
};

export const Step3 = ({ setStep }) => {
  const dispatch = useDispatch();
  const [fieldsetError, setFieldsetError] = useState(false);
  const state = useSelector((state) => state);

  const pronounOptions = [
    { name: "Ele/Dele", value: "Ele/Dele" },
    { name: "Ela/Dela", value: "Ela/Dela" },
    { name: "Elu/Delu", value: "Elu/Delu" },
  ];

  const formSchema = yup.object().shape({
    pronoun: yup.string().required("Selecionar o seu pronome é obrigatório"),
    morningFree: yup
      .boolean()
      .test("contradiction", "conflito", (item, testContext) => {
        if (item) {
          return !testContext.parent.noFree;
        } else {
          return true;
        }
      }),
    afternoonFree: yup
      .boolean()
      .test("contradiction", "conflito", (item, testContext) => {
        if (item) {
          return !testContext.parent.noFree;
        } else {
          return true;
        }
      }),
    nightFree: yup
      .boolean()
      .test("contradiction", "conflito", (item, testContext) => {
        if (item) {
          return !testContext.parent.noFree;
        } else {
          return true;
        }
      }),
    noFree: yup
      .boolean()
      .test("contradiction", "conflito", (item, testContext) => {
        if (item) {
          return (
            !testContext.parent.morningFree &&
            !testContext.parent.afternoonFree &&
            !testContext.parent.nightFree
          );
        } else {
          return true;
        }
      })
      .test("required", "", (item, testContext) => {
        if (
          item ||
          testContext.parent.morningFree ||
          testContext.parent.afternoonFree ||
          testContext.parent.nightFree
        ) {
          setFieldsetError(false);
          return true;
        } else {
          setFieldsetError(true);
          return false;
        }
      }),
    birth: yup
      .date()
      .required("Data de nascimento obrigatória")
      .max(new Date(), "Não é possível incluir uma data futura")
      .typeError("Data de nascimento obrigatória"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (state.register.pronoun) {
      setValue("pronoun", state.register.pronoun);
    }
    if (state.register.morningFree) {
      setValue("morningFree", state.register.morningFree);
    }
    if (state.register.afternoonFree) {
      setValue("afternoonFree", state.register.afternoonFree);
    }
    if (state.register.nightFree) {
      setValue("nightFree", state.register.nightFree);
    }
    if (state.register.noFree) {
      setValue("noFree", state.register.noFree);
    }
    if (state.register.birth) {
      setValue("birth", state.register.birth.toISOString().substr(0, 10));
    }
  }, []);

  const onSubmitFunction = ({
    morningFree,
    afternoonFree,
    nightFree,
    noFree,
    ...registerData
  }) => {
    let available = [];
    if (morningFree) {
      available.push("manhã");
    }
    if (afternoonFree) {
      available.push("tarde");
    }
    if (nightFree) {
      available.push("noite");
    }
    if (noFree) {
      available.push("nenhum");
    }
    const availableData = available.join("|");
    dispatch(
      updateRegister({
        availableData,
        morningFree,
        afternoonFree,
        nightFree,
        noFree,
        ...registerData,
      })
    );
    setStep(4);
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmitFunction)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SelectInput
        title="Pronome"
        placeholder="Seu pronome"
        name="pronoun"
        options={pronounOptions}
        register={register}
        errors={errors}
      />
      <FieldsetStyled fieldsetError={fieldsetError}>
        <legend>Que horário você tem disponível para estudar?</legend>
        <div className="fieldset-options">
          <div className="morning-free">
            <CheckboxInput
              name="morningFree"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Manhã
            </CheckboxInput>
          </div>

          <div className="afternoon-free">
            <CheckboxInput
              name="afternoonFree"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Tarde
            </CheckboxInput>
          </div>

          <div className="night-free">
            <CheckboxInput
              name="nightFree"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Noite
            </CheckboxInput>
          </div>
          <div className="no-free">
            <CheckboxInput
              name="noFree"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Meus horários são complicados
            </CheckboxInput>
          </div>
        </div>
      </FieldsetStyled>
      <DateInput
        title="Data de nascimento"
        name="birth"
        register={register}
        errors={errors}
        className="date-input"
      />
      <ButtonGroups>
        <Button
          type="button"
          className="back-button"
          onClick={() => setStep(2)}
        >
          Voltar
        </Button>
        <Button className="btn-submit">Continuar</Button>
      </ButtonGroups>
    </motion.form>
  );
};

export const Step4 = ({ setStep }) => {
  const [fieldsetError, setFieldsetError] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const programLevelOptions = [
    {
      name: "Mal sei usar um computador",
      value: "nenhum conhecimento",
    },
    {
      name: "Sei usar um computador, mas não programar",
      value: "sabe usar computador",
    },
    { name: "Sei o básico de programação", value: "básico" },
    {
      name: "Trabalhei um pouco com ferramentas complexas da área",
      value: "profissional júnior",
    },
    {
      name: "Tenho anos de experiência na area",
      value: "profissional pleno ou maior",
    },
  ];

  const formSchema = yup.object().shape({
    programLevel: yup.string().required("Selecionar o seu nível é obrigatório"),
    visitorOption: yup.boolean(),
    recommendationOption: yup.boolean(),
    isFreeOption: yup.boolean(),
    formationOption: yup
      .boolean()
      .test("minOneSelected", "", (item, testContext) => {
        if (
          item ||
          testContext.parent.visitorOption ||
          testContext.parent.recommendationOption ||
          testContext.parent.isFreeOption
        ) {
          setFieldsetError(false);
          return true;
        } else {
          setFieldsetError(true);
          return false;
        }
      }),
    terms: yup.boolean().oneOf([true], " ***"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmitFunction = ({ terms, ...registerData }) => {
    const {
      morningFree,
      afternoonFree,
      nightFree,
      noFree,
      email,
      password,
      ...registerDataFinal1
    } = state.register;

    const {
      visitorOption,
      recommendationOption,
      isFreeOption,
      formationOption,
      ...registerDataFinal2
    } = registerData;

    let motivation = [];
    if (visitorOption) {
      motivation.push("visitante");
    }
    if (recommendationOption) {
      motivation.push("veio por recomendação");
    }
    if (isFreeOption) {
      motivation.push("veio pelo curso gratis");
    }
    if (formationOption) {
      motivation.push("veio pela formação");
    }

    const motivationData = motivation.join("|");

    createUseWithEmail(email, password)
      .then((userCredetial) => {
        const uid = userCredetial.user.uid;
        const userData = Object.freeze({
          ...registerDataFinal1,
          ...registerDataFinal2,
          motivationData,
        });
        newUserCreateDocument(uid, userData)
          .then(() => {
            updateUserData({ displayName: registerDataFinal1.name });
            dispatch(reinicializerRegister());
            navigate("/entrar");
            addInDiscord();
            toast("Usuário cadastrado", { theme: "dark" });
          })
          .catch((error) => {
            console.log(error);
            toast("Falha no cadastro", { theme: "dark" });
          });
      })
      .catch((error) => {
        console.log(error);
        toast("Email já cadastrado", { theme: "dark" });
        setStep(1);
      });
  };

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmitFunction)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SelectInput
        title="Conhecimentos em programação"
        placeholder="Qual seu nível em programação"
        name="programLevel"
        options={programLevelOptions}
        register={register}
        errors={errors}
      />
      <FieldsetStyled fieldsetError={fieldsetError}>
        <legend>Por que quer estudar com a gente?</legend>
        <div>
          <div className="visitor-option">
            <CheckboxInput
              name="visitorOption"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Apenas conhecendo
            </CheckboxInput>
          </div>

          <div className="recommendation-option">
            <CheckboxInput
              name="recommendationOption"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Recomendaram-me a SkCoders
            </CheckboxInput>
          </div>

          <div className="is-free-option">
            <CheckboxInput
              name="isFreeOption"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Fiquei interessado porque o curso é gratuito
            </CheckboxInput>
          </div>
          <div className="formation-option">
            <CheckboxInput
              name="formationOption"
              className="form__checkbox-box"
              register={register}
              errors={errors}
            >
              Estava procurando um curso de formação nessa área
            </CheckboxInput>
          </div>
        </div>
      </FieldsetStyled>
      <div className="terms_of_use">
        <CheckboxInput
          name="terms"
          className="form__checkbox-box"
          register={register}
          errors={errors}
        >
          Li e aceito os{" "}
          <button onClick={() => setOpenModal(true)} type="button">
            Termos de uso
          </button>
        </CheckboxInput>
        <div className="modal">
          <Modal
            isOpen={openModal}
            setModalOpen={() => setOpenModal(!openModal)}
          >
            <h1 className="modal__title">
              Termos de Uso e Privacidade - Plataforma de EAD SkCoders
            </h1>
            <ContentModel>
              <p className="content_description">
                Bem-vindo(a) à plataforma de ensino a distância (EAD) SkCoders,
                desenvolvida pela Skill Labs. Estes Termos de Uso e Privacidade
                regem o uso do nosso site e serviços relacionados. Ao acessar e
                utilizar a plataforma Sk Coders, você concorda com estes termos
                e assume a responsabilidade por cumprir com todas as políticas
                aqui estabelecidas. Caso você discorde de algum dos termos
                abaixo, recomendamos que não utilize nossos serviços.
              </p>
              <ol>
                {termsOfUse.map(({ title, Description }, index) => (
                  <li className="content_title">
                    {termsOfUse[index].title}
                    <ul className="content_description">
                      <Description />
                    </ul>
                  </li>
                ))}
              </ol>
            </ContentModel>
          </Modal>
        </div>
      </div>
      <ButtonGroups>
        <Button
          type="button"
          className="back-button"
          onClick={() => setStep(3)}
        >
          Voltar
        </Button>
        <Button className="btn-submit">Cadastre-se</Button>
      </ButtonGroups>
    </motion.form>
  );
};
