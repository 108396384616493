import { app, storage } from "../firebaseSdk";
import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const createUseWithEmail = (email, password) => {
  const auth = getAuth(app);

  return createUserWithEmailAndPassword(auth, email, password);
};

export const newUserCreateDocument = (documentRef, userData) => {
  const dbReference = getFirestore(app);
  const collectionRef = collection(dbReference, "User");

  return setDoc(doc(collectionRef, documentRef), userData);
};

export const getUserData = () => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    const data = {
      email: "",
      name: "",
      ddi: "",
      phone: "",
      program: "",
      pronoun: "",
      birth: "",
      programLevel: "",
      availableData: "",
      motivationData: "",
      aboutMe: "",
      shortAboutMe: "",
      facebook: "",
      github: "",
      instagram: "",
      linkedin: "",
      portfolio: "",
      country: "",
      state: "",
      city: "",
      aboutExp: "",
      skills: [],
      linkId: "",
    };
    if (user !== null) {
      data.name = user.displayName;
      data.email = user.email;
      const docRef = doc(getFirestore(app), "User", user.uid);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const dataUser = docSnap.data();
          for (const key in data) {
            data[key] = dataUser[key];
          }
          data.skills = dataUser["skills"] || [];
        }
        resolve(data);
      } catch (error) {
        reject(error);
      }
    }
    reject(new Error("Usuário não está autenticado."));
  });
};

export const updateUserData = (userData) => {
  const auth = getAuth(app);
  updateProfile(auth.currentUser, { ...userData });
};

export const updateUserDoc = (data) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    const docRef = doc(getFirestore(app), "User", user.uid);
    try {
      await updateDoc(docRef, data);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserEmail = (curruentPassword, newEmail) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    try {
      const dataUser = await getUserData();
      await loginWithEmail(dataUser.email, curruentPassword);
      await updateEmail(auth.currentUser, newEmail);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserPassword = (curruentPassword, newPassword) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    try {
      const dataUser = await getUserData();
      await loginWithEmail(dataUser.email, curruentPassword);
      await updatePassword(auth.currentUser, newPassword);
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserPhone = (curruentPassword, newPhone) => {
  return new Promise(async (resolve, reject) => {
    const dataUser = await getUserData();
    await loginWithEmail(dataUser.email, curruentPassword);
    const auth = getAuth(app);
    const user = auth.currentUser;

    console.log("updateUserPhone uid:", user.uid);

    const docRef = doc(getFirestore(app), "User", user.uid);
    try {
      await updateDoc(docRef, {
        phone: newPhone,
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateUserAboutMe = (aboutMe) => {
  return updateUserDoc(aboutMe);
};

export const updateUserAboutExp = (aboutExp) => {
  return updateUserDoc(aboutExp);
};

export const updateUserContacts = (contacts) => {
  return updateUserDoc(contacts);
};

export const addSkill = (skill) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    const docRef = doc(getFirestore(app), "User", user.uid);
    try {
      await updateDoc(docRef, {
        skills: arrayUnion(skill),
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const removeSkill = (skill) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    const user = auth.currentUser;

    const docRef = doc(getFirestore(app), "User", user.uid);
    try {
      await updateDoc(docRef, {
        skills: arrayRemove(skill),
      });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

export const createUserWithGmail = () => {
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const credencial = GoogleAuthProvider.credentialFromResult(result);
      const token = credencial.accessToken;
      const user = result.user;
    })
    .catch((error) => {
      console.log(error);
      // todo tratar erros
    });
};

export const logout = () => {
  const auth = getAuth(app);
  return signOut(auth);
  // todo inserir lógica pós logout
};

export const loginWithEmail = (email, password) => {
  const auth = getAuth(app);
  return signInWithEmailAndPassword(auth, email, password);
};

export const forgotPasswordEmail = (email) => {
  const auth = getAuth();

  return sendPasswordResetEmail(auth, email);
};

export const sentEmailForSuport = () => {
  collection("mail")
    .add({
      to: "someone@example.com",
      message: {
        subject: "Hello from Firebase!",
        text: "This is the plaintext section of the email body.",
        html: "This is the <code>HTML</code> section of the email body.",
      },
    })
    .then(() => console.log("Queued email for delivery!"));
};

export const uploadAvatar = (avatarFile) => {
  if (avatarFile) {
    const auth = getAuth(app);
    const user = auth.currentUser;

    const avatarImagesRef = ref(storage, `avatar-user-skcoders/${user.uid}`);
    return uploadBytes(avatarImagesRef, avatarFile, {
      contentType: avatarFile.type,
    });
  }
  return new Promise(async (resolve) => {
    resolve(true);
  });
};

export const takeAvatar = () => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  return getDownloadURL(ref(storage, `avatar-user-skcoders/${user.uid}`));
};

export const uploadCoverBackground = (coverBackgroundFile) => {
  if (coverBackgroundFile) {
    const auth = getAuth(app);
    const user = auth.currentUser;

    const coverBackgroundFileImagesRef = ref(
      storage,
      `cover-background-skcoders/${user.uid}`
    );
    return uploadBytes(coverBackgroundFileImagesRef, coverBackgroundFile, {
      contentType: coverBackgroundFile.type,
    });
  }
  return new Promise(async (resolve) => {
    resolve(true);
  });
};

export const takeCoverBackgroundFile = () => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  return getDownloadURL(ref(storage, `cover-background-skcoders/${user.uid}`));
};

export const updateShortAboutMeAndName = (dataUser) => {
  return new Promise(async (resolve, reject) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    const { name } = dataUser;

    console.log("updateUseruid:", user.uid);

    const docRef = doc(getFirestore(app), "User", user.uid);
    try {
      await updateDoc(docRef, dataUser);
      updateUserData({ displayName: name });
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};
