// redux
import { useDispatch } from "react-redux";

// router dom
import { Link } from "react-router-dom";

// components
import { TextInput } from "../../components/text_input";
import { PasswordInput } from "../../components/password_input";
import { Button } from "../../components/button";

// style
import { Container, TextContainer, FormContainer } from "./style";
import logo from "../../imgs/fullLogo2.svg";
import { useCycle } from "framer-motion";

// others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signInThunk } from "../../store/modules/user/thunks";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, TextContainer, FormContainer )

export const Login = () => {
  const dispatch = useDispatch();
  const [isVisible, toggleOpen] = useCycle(false, true);

  const formSchema = yup.object().shape({
    email: yup.string().required("E-mail obrigatório").email("E-mail inválido"),
    password: yup.string().required("Senha obrigatória"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmitFunction = (data) => {
    dispatch(signInThunk(data));
  };

  return (
    <MediaContainer>
    <Container>
      <main>
        <TextContainer>
          <div>
            <h1>Skcoders, plataforma de ensino de tecnologia</h1>
            <p>Aprenda programação do seu jeito, no seu tempo.</p>
          </div>
          <div className="skill-labs-link">
            <a
              href="https://www.sklabs.com.br/"
              target="_black"
              rel="noreferrer"
            >
              Skill labs company
            </a>
          </div>
        </TextContainer>
        <FormContainer>
          <Link to="/">
            <img src={logo} alt="" className="logo" />
          </Link>
          <h1>Entrar na sua conta</h1>
          <p className="form-container__subtitle">
            Entre na nossa plataforma de ensino de tecnologia
          </p>
          <form onSubmit={handleSubmit(onSubmitFunction)}>
            <TextInput
              title="Email"
              name="email"
              placeholder="Email"
              register={register}
              errors={errors}
            />
            <PasswordInput
              title="Senha"
              name="password"
              register={register}
              errors={errors}
              placeholder="senha"
              isVisible={isVisible}
              toggleOpen={toggleOpen}
              className="password-input"
            />
            <p className="toRegister__forgot-password">
              <Link to="/recuperar_senha" className="toRegister__link">
                Esqueceu sua senha?
              </Link>
            </p>
            <Button className="btn-submit">Continuar</Button>
          </form>
          <div className="toRegister">
            <p className="toRegister__question">Não possui conta?</p>
            <Link to="/cadastro" className="toRegister__link">
              Criar conta
            </Link>
          </div>
        </FormContainer>
      </main>
    </Container>
    </MediaContainer>
  );
};
