import { REINICIALIZER_REGISTER, UPDATE_REGISTER } from "./actionsTypes";

export const registerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_REGISTER:
      const { registerData } = action;

      return { ...state, ...registerData };

    case REINICIALIZER_REGISTER:
      return {};

    default:
      return state;
  }
};
