import { updateIndex } from "./actions";

export const updateLessonThunk = (actualIndex) => (dispatch) => {
  try {
    //updateUserLesson(actualIndex);
    localStorage.setItem("actualCourse", actualIndex.courseName);
    localStorage.setItem("actualModule", actualIndex.moduloName);
    localStorage.setItem("actualLesson", actualIndex.lessonIndex);
    dispatch(updateIndex(actualIndex));
  } catch (e) {
    throw new Error(e);
  }
};
