import styled, { keyframes } from "styled-components";

const moveUp = keyframes`
  from {
    transform: translateY(2rem)
  }

  to {
    transform: translateY(0)
  }
`;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkBlue};
  min-height: 100vh;
  main {
    min-height: 90vh;
    display: flex;
    padding: 0rem 4rem 0 4rem;
    justify-content: center;
    align-items: center;
  }

  .logo {
    height: 4.5rem;
    padding-top: 5rem;
  }

  .redes-sociais {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    a {
      margin: 0 1.5rem;
      color: ${({ theme }) => theme.white};
      font-size: 2rem;
      :hover {
        text-decoration: underline;
      }
    }
  }
`;

export const MainText = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  bottom: 3rem;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;

  animation: ${moveUp} 1s linear;
  .access-the-community {
    h1 {
      color: ${({ theme }) => theme.white};
      font-size: 3rem;
      max-width: 550px;
      text-align: center;
      line-height: 5.5rem;
      margin: 0 auto;
    }
    p {
      color: ${({ theme }) => theme.white};
      font-size: 1.5rem;
    }
  }
  .information {
    color: ${({ theme }) => theme.white};
    font-size: 1.5rem;
    margin-top: 2rem;
    text-align: center;
    max-width: 600px;
    line-height: 2.5rem;
  }
  .start-of-classes {
    color: ${({ theme }) => theme.white};
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 2rem;
    text-align: center;
    a {
      color: ${({ theme }) => theme.white};
      :hover {
        text-decoration: underline;
      }
    }
  }
  .cyan {
    color: ${({ theme }) => theme.blue};
  }

  .button {
    font-family: "Inconsolata", monospace;
    font-weight: bold;
    width: 19rem;
    height: 2.5rem;
    border-radius: 7px;
    font-size: 1.5rem;
    &:hover {
      background-color: ${({ theme }) => theme.darkBlue};
      border: none;
    }
    button {
      width: 100%;
      border-radius: 5px;
      height: 100%;
      font-size: 1.2rem;
      padding: 0.5rem;
    }
  }
`;
