import styled from "styled-components";
import { theme } from "../../styles/global";

const scale = 2;

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  width: 100%;
  cursor: pointer;
  input {
    cursor: pointer;
    max-height: 12px;
    max-width: 12px;
    margin: 0 16px;
    -ms-transform: scale(${scale}); /* IE */
    -moz-transform: scale(${scale}); /* FF */
    -webkit-transform: scale(${scale}); /* Safari and Chrome */
    -o-transform: scale(${scale}); /* Opera */
    transform: scale(${scale});
  }
  label {
    cursor: pointer;
    font-size: 1rem;
    color: ${({ theme }) => theme.darkBlue};
  }
  .error {
    color: ${theme.errorMsg};
    font-size: 0.8rem;
  }
`;
