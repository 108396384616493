import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (container) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.mobileG`
          .modal_content{
            top:2rem;
          }
        `}
    }
  `;
  return mediaContainer;
};
