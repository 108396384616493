import { app } from "../firebaseSdk";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  updateDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";

export const uploadStudentActivity = async (studentActivity) => {
  try {
    const firestore = getFirestore(app);
    const user = getAuth(app).currentUser;

    if (user) {
      const userId = user.uid;
      const activityDocRef = doc(firestore, "usersActivity", userId);
      const activityDocSnapshot = await getDoc(activityDocRef);

      if (activityDocSnapshot.exists()) {
        await updateDoc(activityDocRef, studentActivity);
      } else {
        await setDoc(activityDocRef, studentActivity);
      }
    } else {
      console.log("Usuário não autenticado ou inválido!");
      return false;
    }
  } catch (error) {
    console.log(`Erro ao atualizar aula: ${error.message}`);
    return false;
  }
};

export const downloadStudentActivity = async () => {
  try {
    const firestore = getFirestore(app);
    const user = getAuth(app).currentUser;

    if (user) {
      const userId = user.uid;
      const activityDocRef = doc(firestore, "usersActivity", userId);
      const activityDocSnapshot = await getDoc(activityDocRef);

      if (activityDocSnapshot.exists()) {
        const lessonData = activityDocSnapshot.data();
        return lessonData;
      }
    } else {
      console.log("Usuário não autenticado ou inválido!");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
