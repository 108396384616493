import styled, { keyframes } from "styled-components";
import { media } from "../../service/util/util";
const moveDown = keyframes`
  from {
    transform: translateY(-2rem)
  }

  to {
    transform: translateY(0)
  }
`;

export const mediaQueries = (container, ProfileHeader, ProfileContent) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
            .content_grid{
                grid-template-columns:1fr 1fr;
                padding-bottom:2rem;
            }
            `}

      ${media.tablet`
                padding:0;
                width:90%;
                max-height:10rem;
                main{
                    width:100%;
                    padding:0 0 0 1.5rem;

                }
                .main_title{
                    margin-top:6rem;
                    font-size:1.5rem;

                }

                ${ProfileHeader}{
                    padding: 1rem 0 2rem 2rem;

                    .profile__circle {
                        font-size:6.5rem;
                        bottom: -6rem;
                        left: 1rem;
                    }
                    .profile__header__bond{
                        font-size:1.3rem;
                        right: 2rem;
                        top:0.5rem;
                    }
                    p{
                        font-size:1.3rem;
                    }
                    .profile__header__infs{
                        flex-direction:column;
                        padding:2rem 0;
                    }
                    .infs__email,
                    .infs__number {
                        display:flex;
                        padding:0 0 0.5rem 0;
                    }
                    .infs__field-name {
                        padding-right:1rem;
                        
                    }
                }
                ${ProfileContent}{
                    margin:1rem;
                    

                
                .content_title{
                        display:none;
                    }
                .content_grid{
                    padding-top:1rem;
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    align-items:center;
                }
                    
                }
           
            `}

            ${media.mobile`
            .content_grid{
                margin:1rem 0;
                padding:1rem;

                form{
                    
                    max-width: 11rem;

                    input{
                        width:11rem;
                    }
                }
            }
            
       `}
    }
  `;
  return mediaContainer;
};
