import { useNavigate } from "react-router-dom";
// components
import { Header } from "../../components/header";
import { BackButton } from "../../components/back_button";
import { Textarea } from "../../components/textarea";
import { Rate } from "../../components/rating";
import { Button } from "../../components/button";
import { LessonNavMobile } from "../../components/lesson_nav_mobile"

//style
import {Container} from "./style";
import { motion } from "framer-motion";

// others
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


export const PagRatingContent = ({ ...rest }) => {
    const navigate = useNavigate();

    const formSchema = yup.object().shape({
        rating: yup.string(),
      });
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(formSchema),
      });
    
      const onSubmitFunction = ({ rating }) => {
        console.log({ rating });
      };
    
  
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 1 }}
    {...rest}
  >
    
      <Container>
        <Header hasPerfil></Header>
        <main>
          <LessonNavMobile className="buttons"/>
                        
            <form onSubmit={handleSubmit(onSubmitFunction)}>
            <Rate/>
            <Textarea
                  name="rating"
                  register={register}
                  errors={errors}
                  placeholder="Comentários sobre a aula (Ajude-nos a melhorar)"
                  className="description-input"
                >

            </Textarea>
            <Button 
              className="send-button"
              onClick={() => navigate("/dashboard")}>Enviar
            </Button>
                
              
            </form>
        </main>
    </Container>

    </motion.div>
  )}