// react
import { useEffect, useRef } from "react";

// redux
import { useSelector, useDispatch } from "react-redux";

// style
import {
  Form,
  BtnGroup,
  SocialsInput,
  DataUserInput,
  AvatarInput,
  CoverBackgroundInput,
  SkillsStyled,
} from "./style";

// components
import { Modal } from "../../components/modal";
import { Button } from "../../components/button";
import { Textarea } from "../../components/textarea";
import { TextInput } from "../../components/text_input";
import { SelectInput } from "../../components/select_input";

// icons
import {
  BiLogoLinkedinSquare as LinkedinIcon,
  BiLogoGithub as GithubIcon,
  BiLogoInstagramAlt as InstagramIcon,
  BiLogoFacebookCircle as FacebookIcon,
  BiWorld as WordIcon,
  BiSolidMap as CityIcon,
  BiUserCircle as NameIcon,
} from "react-icons/bi";
import { FaRegNewspaper as CurriculumIcon } from "react-icons/fa6";
import { SiOpenstreetmap as StateIcon } from "react-icons/si";
import { PiChatTextBold as AboutMeIcon } from "react-icons/pi";

// yup e form
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// other
import {
  updateUserAboutMe,
  updateUserContacts,
  uploadAvatar,
  updateShortAboutMeAndName,
  uploadCoverBackground,
  takeAvatar,
  takeCoverBackgroundFile,
  updateUserAboutExp,
  addSkill,
  removeSkill,
} from "../../service/userManagement";
import { setUserThunk } from "../../store/modules/user/thunks";
import { skillsList } from "./skills";

export const ModalAboutMe = ({ modalEditOpen, modalHandle }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();

  const modalSubmit = (aboutMe) => {
    updateUserAboutMe(aboutMe)
      .then(() => {
        dispatch(setUserThunk());
        modalHandle("OpenAboutMe");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenAboutMe"}
      setModalOpen={() => modalHandle("OpenAboutMe")}
    >
      <Form onSubmit={handleSubmit(modalSubmit)}>
        <label htmlFor="aboutMe">Fale um pouco sobre você!</label>
        <Textarea id="aboutMe" name="aboutMe" register={register} />
        <BtnGroup>
          <Button
            onClick={() => modalHandle()}
            className="cancel-button"
            type="button"
          >
            Cancelar
          </Button>
          <Button children="Salvar Alterações" />
        </BtnGroup>
      </Form>
    </Modal>
  );
};

export const ModalAboutExp = ({ modalEditOpen, modalHandle }) => {
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const modalSubmit = (aboutExp) => {
    updateUserAboutExp(aboutExp)
      .then(() => {
        dispatch(setUserThunk());
        modalHandle("OpenAboutExp");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenAboutExp"}
      setModalOpen={() => modalHandle("OpenAboutExp")}
    >
      <Form onSubmit={handleSubmit(modalSubmit)}>
        <label htmlFor="aboutExp">Fale um pouco sobre você!</label>
        <Textarea id="aboutExp" name="aboutExp" register={register} />
        <BtnGroup>
          <Button
            onClick={() => modalHandle()}
            className="cancel-button"
            type="button"
          >
            Cancelar
          </Button>
          <Button children="Salvar Alterações" />
        </BtnGroup>
      </Form>
    </Modal>
  );
};

export const ModalContacts = ({ modalEditOpen, modalHandle }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const formSchema = yup.object().shape({
    linkedin: yup.string(),
    instagram: yup.string(),
    github: yup.string(),
    facebook: yup.string(),
    portfolio: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    setValue("linkedin", user.linkedin ? user.linkedin : "");
    setValue("instagram", user.instagram ? user.instagram : "");
    setValue("github", user.github ? user.github : "");
    setValue("facebook", user.facebook ? user.facebook : "");
    setValue("portfolio", user.portfolio ? user.portfolio : "");
  }, []);

  const modalSubmit = (contacts) => {
    updateUserContacts(contacts)
      .then(() => {
        dispatch(setUserThunk());
        modalHandle("OpenContacts");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenContacts"}
      setModalOpen={() => modalHandle("OpenContacts")}
    >
      <Form onSubmit={handleSubmit(modalSubmit)}>
        <SocialsInput>
          <div>
            <div className="iconBg">
              <LinkedinIcon />
              <p>Linkedin</p>
            </div>
            <TextInput name="linkedin" register={register} errors={errors} />
          </div>

          <div>
            <div className="iconBg">
              <InstagramIcon />
              <p>Instagram</p>
            </div>
            <TextInput name="instagram" register={register} errors={errors} />
          </div>

          <div>
            <div className="iconBg">
              <GithubIcon />
              <p>Github</p>
            </div>
            <TextInput name="github" register={register} errors={errors} />
          </div>

          <div>
            <div className="iconBg">
              <FacebookIcon />
              <p>Facebook</p>
            </div>
            <TextInput name="facebook" register={register} errors={errors} />
          </div>

          <div>
            <div className="iconBg">
              <CurriculumIcon />
              <p>Portfólio</p>
            </div>
            <TextInput name="portfolio" register={register} errors={errors} />
          </div>
        </SocialsInput>

        <BtnGroup>
          <Button
            onClick={() => modalHandle()}
            className="cancel-button"
            type="button"
          >
            Cancelar
          </Button>
          <Button children="Salvar Alterações" />
        </BtnGroup>
      </Form>
    </Modal>
  );
};

export const ModalMainInfos = ({
  modalEditOpen,
  modalHandle,
  setAvatarUrl,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const formSchema = yup.object().shape({
    avatar: yup.mixed(),
    name: yup.string().required("Obrigatório"),
    shortAboutMe: yup.string().max(50, "Texto grande demais"),
    // country: yup.string().required("Obrigatório"),
    // state: yup
    //   .string()
    //   .required("Obrigatório")
    //   .min(2, "Deve ter duas letras (exp: RN, RS, AC...)")
    //   .max(2, "Deve ter duas letras (exp: RN, RS, AC...)"),
    // city: yup.string().required("Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    setValue("name", user.name ? user.name : "");
    setValue(
      "shortAboutMe",
      user.shortAboutMe
        ? user.shortAboutMe
        : "Sou um(a) estudante de programação :)"
    );
    // setValue("country", user.country ? user.country : "");
    // setValue("state", user.state ? user.state : "");
    // setValue("city", user.city ? user.city : "");
  }, []);

  const modalSubmit = ({ avatar, ...dataUser }) => {
    const avatarFile = avatar[0];
    uploadAvatar(avatarFile)
      .then(() => {
        dispatch(setUserThunk());
        modalHandle("OpenMainInfos");
        takeAvatar()
          .then((url) => {
            setAvatarUrl(url);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    updateShortAboutMeAndName(dataUser)
      .then((_) => {
        dispatch(setUserThunk());
        modalHandle("OpenMainInfos");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenMainInfos"}
      setModalOpen={() => modalHandle("OpenMainInfos")}
    >
      <Form onSubmit={handleSubmit(modalSubmit)}>
        <AvatarInput>
          <label for="avatar">Imagem de capa de perfil:</label>
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/*"
            {...register("avatar")}
          />
          <span className="error">{errors["avatar"]?.message}</span>
        </AvatarInput>
        <DataUserInput>
          <div className="name max-large">
            <div className="iconBg">
              <NameIcon />
              <p>Nome</p>
            </div>
            <TextInput name="name" register={register} errors={errors} />
          </div>
        </DataUserInput>
        <DataUserInput>
          <div className="short-about-me max-large ">
            <div className="iconBg">
              <AboutMeIcon />
              <p>Sobre</p>
            </div>
            <TextInput
              name="shortAboutMe"
              register={register}
              errors={errors}
            />
          </div>
        </DataUserInput>
        {/* <DataUserInput>
          <div>
            <div className="iconBg">
              <WordIcon />
              <p>País</p>
            </div>
            <TextInput name="country" register={register} errors={errors} />
          </div>
          <div>
            <div className="iconBg">
              <StateIcon />
              <p>Estado</p>
            </div>
            <TextInput name="state" register={register} errors={errors} />
          </div>
          <div>
            <div className="iconBg">
              <CityIcon />
              <p>Cidade</p>
            </div>
            <TextInput name="city" register={register} errors={errors} />
          </div>
        </DataUserInput> */}

        <BtnGroup>
          <Button
            onClick={() => modalHandle()}
            className="cancel-button"
            type="button"
          >
            Cancelar
          </Button>
          <Button>Salvar</Button>
        </BtnGroup>
      </Form>
    </Modal>
  );
};

export const ModalCoverBackground = ({
  modalEditOpen,
  modalHandle,
  setCoverBackgroundUrl,
}) => {
  const dispatch = useDispatch();

  const formSchema = yup.object().shape({
    coverBackground: yup.mixed().required("Obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const modalSubmit = ({ coverBackground }) => {
    const coverBackgroundFile = coverBackground[0];

    uploadCoverBackground(coverBackgroundFile)
      .then(() => {
        dispatch(setUserThunk());
        modalHandle("OpenCoverBackground");
        takeCoverBackgroundFile()
          .then((url) => {
            setCoverBackgroundUrl(url);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenCoverBackground"}
      setModalOpen={() => modalHandle("OpenCoverBackground")}
    >
      <Form onSubmit={handleSubmit(modalSubmit)}>
        <CoverBackgroundInput>
          <label for="coverBackground">Imagem de capa de perfil:</label>
          <input
            type="file"
            id="coverBackground"
            name="coverBackground"
            accept="image/*"
            {...register("coverBackground")}
          />
          <span className="error">{errors["coverBackground"]?.message}</span>
        </CoverBackgroundInput>
        <BtnGroup>
          <Button
            onClick={() => modalHandle()}
            className="cancel-button"
            type="button"
          >
            Cancelar
          </Button>
          <Button>Salvar</Button>
        </BtnGroup>
      </Form>
    </Modal>
  );
};

const SkillsBox = () => {
  const dispatch = useDispatch();
  const selectForm = useRef(null);

  const { skills } = useSelector((state) => state.user);

  const formSchema = yup.object().shape({
    skill: yup
      .string()
      .required("escolha uma habilidade")
      .test(
        "skill exist",
        "Você já adicionou essa skill",
        (value) => !!skills && !skills.includes(value)
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const modalSubmit = ({ skill }) => {
    addSkill(skill)
      .then(() => {
        dispatch(setUserThunk());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Form
      className="modal-open-skill"
      ref={selectForm}
      method="get"
      onChange={handleSubmit(modalSubmit)}
    >
      <DataUserInput className="open-skill-input-box">
        <SelectInput
          className="medium-large"
          register={register}
          name="skill"
          errors={errors}
          title="Skill"
          options={skillsList}
        />
      </DataUserInput>
    </Form>
  );
};

export const ModalSkills = ({ modalEditOpen, modalHandle }) => {
  const { skills } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const skillRemoverButtonFunction = (skill) => {
    removeSkill(skill)
      .then(() => {
        dispatch(setUserThunk());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      isOpen={modalEditOpen === "OpenSkills"}
      setModalOpen={() => modalHandle("OpenSkills")}
    >
      <div className="modal-skill-box">
        <h2>Selecione suas skills tech</h2>
        <SkillsBox />
        <SkillsStyled>
          {skills &&
            skills.map((skill, index) => (
              <li key={index}>
                {skill}{" "}
                <button onClick={() => skillRemoverButtonFunction(skill)}>
                  -
                </button>
              </li>
            ))}
        </SkillsStyled>
      </div>
    </Modal>
  );
};
