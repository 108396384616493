import { Container } from "./style";
import { motion } from "framer-motion";
import { itemVariants } from "../../styles/global";

export const Button = ({ children, className, ...rest }) => {
  return (
    <motion.div variants={itemVariants} className={className}>
      <Container {...rest}>{children}</Container>
    </motion.div>
  );
};
