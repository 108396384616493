import { Container } from "./style";

export const Lesson = ({
  lesson: { Content, isAvailable, dateAvailable },
  ...rest
}) => {
  return (
    <Container {...rest}>
      {isAvailable ? (
        <Content />
      ) : (
        <section>
          <h2>Material indisponível</h2>
          {dateAvailable ? (
            <p>O material será liberado dia {dateAvailable}</p>
          ) : (
            <p>Não há prazo para liberar esse material</p>
          )}
        </section>
      )}
    </Container>
  );
};
