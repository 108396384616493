import styled from "styled-components";
import { theme } from "../../styles/global";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  .error {
    color: ${theme.errorMsg};
    font-size: 0.8rem;
  }
  label {
    font-weight: bold;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.darkBlue};
  }
`;

export const InputStyled = styled.input`
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 7px;
  width: 20rem;
  height: 2.5rem;
  color: ${({ theme }) => theme.darkBlue};
  padding: 0 0 0 16px;
  margin: 0.5rem 0;

  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.blue};
    outline: 1px solid ${({ theme }) => theme.blue};
  }
  &::-webkit-input-placeholder {
  }
`;
