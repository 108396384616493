import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0 0 6rem;
  background-color: ${({ theme }) => theme.darkBlue};
  main {
    padding: 0 2rem;
  }
  h2 {
    font-size: ${({ theme }) => theme.title.fontSize};
    color: ${({ theme }) => theme.white};
    text-align: center;
  }
`;

export const ProfileHeader = styled.section`
  background-color: ${({ theme }) => theme.blue};
  max-height: 8rem;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  position: relative;
  padding: 2rem 0 1rem 11rem;
  margin-top: 2rem;
  .profile__circle {
    color: ${({ theme }) => theme.blue.color};
    font-size: ${({ theme }) => theme.blue.fontSize};
    position: absolute;
    bottom: -5rem;
    left: 3rem;
  }
  .profile__header__name-bond {
    h1 {
      color: ${({ theme }) => theme.white};
      font-size: ${({ theme }) => theme.title.fontSize};
      margin: 0;
    }
  }
  .profile__header__bond {
    position: absolute;
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.title.fontSize};
    font-weight: 100;
    right: 1rem;
    top: 1rem;
  }
  .profile__header__infs-box {
    display: inline-block;
    p {
      margin: 0;
      color: ${({ theme }) => theme.white};
      font-size: ${({ theme }) => theme.title.fontSize};
    }
    .infs__field-name {
      font-size: 1rem;
    }
  }
  .profile__header__infs {
    display: flex;
  }
  .infs__email,
  .infs__number {
    padding: 2rem 0 0 2rem;
  }
`;

export const ProfileContent = styled.section`
  margin: 1rem auto;
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  background-color: ${({ theme }) => theme.blue};
  color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  .content_grid {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }
`;
