// Router Dom
import { Link } from "react-router-dom";

// components
import { LandingHeader } from "../../components/landing_header";
// style
import {
  Container,
  FirstSection,
  Techs,
  AboutProgram,
  Advantages,
  Participate,
  Motivations,
  Community,
  LetsGo,
  Footer,
} from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHtml5,
  faCss3Alt,
  faSquareJs,
  faReact,
  faNode,
  faGitAlt,
  faLinkedinIn,
  faDiscord,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faShapes,
  faFilm,
  faPersonChalkboard,
  faHandHoldingDollar,
  faHourglassHalf,
  faLaptopCode,
  faPeopleGroup,
  faRocket,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { AiOutlineEye as EyeIcon } from "react-icons/ai";
import { GoPeople as PeaploIcon } from "react-icons/go";
import { IoMdTime as TimeIcon } from "react-icons/io";
import { SlLike as LikeIcon } from "react-icons/sl";

// images
import logo from "../../imgs/fullLogo.svg";
import model1 from "../../imgs/model1.webp";
import model2 from "../../imgs/model2.webp";
import model3 from "../../imgs/model3.webp";
import model4 from "../../imgs/model4.webp";
import workingInLaptop from "../../imgs/working_in_laptop.webp";
import teacher from "../../imgs/teacher.webp";
import books from "../../imgs/books.webp";
import office from "../../imgs/office.webp";
import inCall from "../../imgs/in_call.webp";
import instagramIcon from "../../imgs/Instagram.svg";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, FirstSection,
    Techs,
    AboutProgram,
    Advantages,
    Participate,
    Motivations,
    Community,
    Footer,)

export const LandingPage = () => {
  return (
    <MediaContainer>
    <Container>
      <LandingHeader />
      <main>
        <FirstSection>
          <h1>
            Aprenda a programar
            <br />
            do seu jeito e no seu tempo.
          </h1>
          <p>
            Criamos trilhas de ensino que preparam você para dar os primeiros
            passos no mundo da tecnologia, gerando novas oportunidades em sua
            vida profissional.
          </p>
          <Link to="/cadastro" className="btn-account">
            Criar conta
          </Link>
          <Link to="/entrar" className="btn-account btn-theme-white">
            Entrar
          </Link>
        </FirstSection>
        <Techs>
          <p className="tech relative">
            <FontAwesomeIcon icon={faGitAlt} />
          </p>
          <p className="tech relative">
            <FontAwesomeIcon icon={faHtml5} />
          </p>
          <p className="tech relative">
            <FontAwesomeIcon icon={faCss3Alt} />
          </p>
          <p className="tech relative">
            <FontAwesomeIcon icon={faSquareJs} />
          </p>
          <p className="tech relative">
            <FontAwesomeIcon icon={faReact} />
          </p>
          <p className="tech relative">
            <FontAwesomeIcon icon={faNode} />
          </p>
        </Techs>
        <AboutProgram>
          <h1>Nosso Programa</h1>
          <p>
            Nosso programa de{" "}
            <span className="blue">
              formação Primeiros Passos como Fullstack
            </span>{" "}
            funciona oferecendo uma experiência completa e gratuita aos
            participantes, combinando aulas gravadas com aulas ao vivo. Os
            alunos têm acesso a um currículo abrangente, no qual aprendem os
            fundamentos e habilidades necessárias para se tornarem
            desenvolvedores fullstack. Nossas aulas gravadas permitem que os
            estudantes avancem em seu próprio ritmo, enquanto as aulas ao vivo
            proporcionam interação direta com instrutores especializados. Com
            essa abordagem combinada, garantimos que os participantes tenham
            acesso ao conhecimento necessário para iniciar sua jornada como
            desenvolvedores fullstack, tudo isso sem custos financeiros.
          </p>
        </AboutProgram>
        <Advantages>
          <article>
            <FontAwesomeIcon icon={faShapes} />
            <h2>Formação básica</h2>
            <p>
              O programa abrange os principais conceitos e habilidades
              necessárias para se tornar um desenvolvedor fullstack.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faFilm} />
            <h2>Aulas gravadas</h2>
            <p>
              Os alunos têm acesso a aulas gravadas, o que permite que estudem
              em seu próprio ritmo e revisitem o conteúdo sempre que necessário.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faPersonChalkboard} />
            <h2>Aulas ao vivo</h2>
            <p>
              As aulas ao vivo oferecem interação direta com instrutores
              especializados, permitindo esclarecer dúvidas, obter feedback em
              tempo real e participar de discussões com outros estudantes.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faHandHoldingDollar} />
            <h2>Programa gratuito</h2>
            <p>
              O programa é 100% gratuito, o que possibilita o acesso ao
              conhecimento de forma acessível, sem custos financeiros.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faHourglassHalf} />
            <h2>Flexibilidade</h2>
            <p>
              Os participantes têm flexibilidade para ajustar seus horários de
              estudo de acordo com suas necessidades e disponibilidade.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faLaptopCode} />
            <h2>Portfólio com projetos reais</h2>
            <p>
              Integramos os alunos iniciantes com nosso time de especialistas
              para desenvolver projetos reais, que além de gerar conhecimento
              mais aprofundado gera experiência profissional.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faPeopleGroup} />
            <h2>Networking</h2>
            <p>
              O programa oferece a oportunidade de interagir com outros
              estudantes e profissionais da área, expandindo a rede de contatos
              e criando possíveis oportunidades futuras.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faRocket} />
            <h2>Carreira tech</h2>
            <p>
              Ao adquirir habilidades básicas em desenvolvimento fullstack, os
              participantes estarão dando os primeiros passos para ingressar no
              mercado de trabalho ou progredir em suas carreiras na área de
              tecnologia.
            </p>
          </article>
          <article>
            <FontAwesomeIcon icon={faCertificate} />
            <h2>Certificado de conclusão</h2>
            <p>
              Ao concluir o programa, os alunos recebem um certificado que
              atesta sua participação e o conhecimento adquirido, agregando
              valor ao currículo.
            </p>
          </article>
        </Advantages>
        <Participate>
          <article>
            <h1>Quem pode participar?</h1>
            <p>
              Para este programa de formação, estamos buscando{" "}
              <span className="bold">pessoas a partir de 18 anos</span>, sem{" "}
              <span className="bold">limite de idade</span>, que{" "}
              <span className="bold">
                não possuam conhecimento prévio em programação.
              </span>
            </p>
            <p>
              Damos preferência a indivíduos que estejam atualmente
              desempregados, em transição de carreira ou buscando sua primeira
              oportunidade de trabalho.
            </p>
            <Link to="/cadastro" className="btn-account">
              Criar conta
            </Link>
          </article>
          <div className="models">
            <div className="models-left">
              <img src={model1} alt="" />
              <img src={model2} alt="" />
            </div>
            <div className="models-right">
              <img src={model3} alt="" />
              <img src={model4} alt="" />
            </div>
          </div>
        </Participate>
        <Motivations>
          <h1>
            Motivos para escolher a Skcoders como sua escola de programação
          </h1>
          <div className="cards">
            <article>
              <div className="cards__header">
                <EyeIcon /> <h2>Aprendizado Prático e Focado</h2>
              </div>
              <div className="cards__content">
                <p>
                  A Skcoders se destaca por oferecer um currículo prático e
                  focado, projetado para ensinar as habilidades de programação
                  mais relevantes e aplicáveis. Os alunos têm a oportunidade de
                  trabalhar em projetos reais desde o início, o que os prepara
                  para o mundo real da programação.
                </p>
                <img src={workingInLaptop} alt="" />
              </div>
            </article>
            <article>
              <div className="cards__header">
                <PeaploIcon /> <h2>Instrutores Experientes</h2>
              </div>
              <div className="cards__content">
                <p>
                  A escola conta com instrutores altamente qualificados e
                  experientes que são apaixonados por compartilhar seu
                  conhecimento e orientar os alunos em seu desenvolvimento. Isso
                  significa que os alunos têm acesso a orientação de qualidade
                  ao longo de sua jornada de aprendizado.
                </p>
                <img src={teacher} alt="" />
              </div>
            </article>
            <article>
              <div className="cards__header">
                <TimeIcon /> <h2>Flexibilidade de Aprendizado</h2>
              </div>
              <div className="cards__content">
                <p>
                  Flexibilidade de Aprendizado Com a combinação de aulas
                  gravadas e ao vivo, a Skcoders permite que os alunos escolham
                  o método que melhor atenda às suas preferências de
                  aprendizado. A disponibilidade de aulas gravadas oferece
                  flexibilidade para que os alunos possam revisar o conteúdo,
                  avançar no seu próprio ritmo e acessar o material a qualquer
                  momento.
                </p>
                <img src={books} alt="" />
              </div>
            </article>
            <article>
              <div className="cards__header">
                <LikeIcon /> <h2>Ajuda na Colocação Profissional</h2>
              </div>
              <div className="cards__content">
                <p>
                  A Skcoders não apenas ensina programação, mas também se
                  preocupa com o sucesso de seus alunos no mercado de trabalho.
                  A escola oferece assistência na colocação profissional,
                  workshops de preparação para entrevistas e estágios para
                  ajudar os alunos a iniciar suas carreiras como programadores.
                </p>
                <img src={office} alt="" />
              </div>
            </article>
          </div>
        </Motivations>
        <Community>
          <img src={inCall} alt="" className="in-call" />
          <div className="content">
            <h1>Participe de nossas comunidades.</h1>
            <p>
              Um grupo de pessoas com o mesmo objetivo: Aprender programação e
              ter a chance de melhorar de vida!
            </p>
            <nav>
              <ul>
                <li>
                  <a
                    href="https://www.linkedin.com/company/skcoders/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.gg/aZqDcxr7fb"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faDiscord} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/skcoders_"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={instagramIcon} alt="icone do instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC6goxJ4qLE72UrUldLvZQbA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
              </ul>
            </nav>
            <a
              href="https://discord.gg/aZqDcxr7fb"
              target="_blank"
              rel="noreferrer"
              className="enter-discord"
            >
              Entrar
            </a>
          </div>
        </Community>
        <LetsGo>
          <h1>Bora começar ?</h1>
          <p>Crie sua conta e comece hoje mesmo.</p>
          <Link to="/cadastro" className="btn-account">
            Criar conta
          </Link>
        </LetsGo>
      </main>
      <Footer>
        <div>
          <img src={logo} alt="" />
        </div>
        <p>Copyright © 2023 Skcoders. Todos os direitos reservados</p>
      </Footer>
    </Container>
    </MediaContainer>
  );
};
