//react
import { useState, useEffect } from "react";

// router dom
import { useLocation } from "react-router-dom";

// redux-thunks
import { useDispatch, useSelector } from "react-redux";
import { signOutThunk } from "../../store/modules/user/thunks";

// style
import { motion } from "framer-motion";
import { Container } from "./style";
import {
  faGraduationCap,
  faPersonRunning,
} from "@fortawesome/free-solid-svg-icons";
import { CgProfile as ProfileIcon } from "react-icons/cg";
import { AiOutlineDown } from "react-icons/ai";

// icons
import {
  FiAirplay as CourseIcon,
  FiUser as UserIcon,
  FiLogOut as ExitIcon,
} from "react-icons/fi";
import { PiCertificate as CertificateIcon } from "react-icons/pi";
import { LiaDiscord as DiscordIcon } from "react-icons/lia";
import { MdOutlineQuiz as ForumIcon } from "react-icons/md";

// component
import { Link } from "react-router-dom";
import { HeaderMenuItem } from "../header_menu_item";

// others
import {
  nameDisplayer,
  addInDiscord,
  enterToForum,
} from "../../service/util/util";
import { takeAvatar } from "../../service/userManagement";

export const HeaderMenu = ({ ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState("");
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.user);

  let location = useLocation();

  useEffect(() => {
    takeAvatar()
      .then((url) => {
        setAvatarUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  });

  return (
    <Container {...rest}>
      <motion.nav
        initial={false}
        animate={isOpen ? "open" : "closed"}
        className="header__nav"
      >
        <motion.div className="head__main-infos">
          <span className="head__user-name">
            {name ? nameDisplayer(name) : ""}
          </span>
          {avatarUrl ? (
            <div className="head__user-img">
              <img src={avatarUrl} alt="" />
            </div>
          ) : (
            <ProfileIcon />
          )}
          <motion.button
            whileTap={{ scale: 0.97 }}
            onClick={() => setIsOpen(!isOpen)}
            className="head__main-button"
          >
            <AiOutlineDown />
          </motion.button>
        </motion.div>
        <motion.ul
          variants={{
            open: {
              clipPath: "inset(0% 0% 0% 0% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
                delayChildren: 0.1,
                staggerChildren: 0.05,
              },
            },
            closed: {
              clipPath: "inset(10% 50% 90% 50% round 10px)",
              transition: {
                type: "spring",
                bounce: 0,
                duration: 0.3,
              },
            },
          }}
          style={{ pointerEvents: isOpen ? "auto" : "none" }}
          className="menu-list"
        >
          <HeaderMenuItem selected={location.pathname === "/area_do_usuario"}>
            <Link to="/area_do_usuario">
              <UserIcon className="icon" />
              Perfil
            </Link>
          </HeaderMenuItem>
          <HeaderMenuItem selected={location.pathname === "/curso_fullstack"}>
            <Link to="/curso_fullstack">
              <CourseIcon className="icon" />
              Curso
            </Link>
          </HeaderMenuItem>
          <HeaderMenuItem icon={faPersonRunning}>
            <button
              className="head__menu-button"
              onClick={() => addInDiscord()}
            >
              <DiscordIcon className="icon" />
              Comunidade
            </button>
          </HeaderMenuItem>
          <HeaderMenuItem icon={faPersonRunning}>
            <button
              className="head__menu-button"
              onClick={() => enterToForum()}
            >
              <ForumIcon className="icon" />
              Forum
            </button>
          </HeaderMenuItem>
          <HeaderMenuItem icon={faGraduationCap}>
            <Link to="/certificado">
              <CertificateIcon className="icon" />
              Ceritificado
            </Link>
          </HeaderMenuItem>
          <HeaderMenuItem icon={faPersonRunning}>
            <button
              className="head__menu-button"
              onClick={() => dispatch(signOutThunk())}
            >
              <ExitIcon className="icon" />
              Sair
            </button>
          </HeaderMenuItem>
        </motion.ul>
      </motion.nav>
    </Container>
  );
};
