import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: calc(100vw - 250px);
  max-width: 1190px;
  justify-content: space-between;
  height: 5rem;
  ul {
    padding: 0rem;
    display: flex;
    li {
      padding: 0 1rem;
      list-style-type: none;
    }
  }
`;
