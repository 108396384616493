import styled from "styled-components";
import { theme } from "../../styles/global";

export const Container = styled.div`
  display: inline-block;
  position: relative;
  max-height: 432px;
  max-width: 768px;
  height: calc((9 * 95vw) / 16);
  width: 95vw;
  border-radius: ${theme.defaultBorderRadius};
  overflow: hidden;
  iframe {
    aspect-ratio: "16 / 9";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
