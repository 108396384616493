//react
import { useState, useEffect } from "react";

// redux
import { useSelector } from "react-redux";

// react router dom
import { useNavigate } from "react-router-dom";

// components
import { Header } from "../../components/header";
import {
  ModalAboutMe,
  ModalContacts,
  ModalMainInfos,
  ModalCoverBackground,
  ModalSkills,
} from "./modals";
import Carousel from "react-grid-carousel";

// style
import {
  Container,
  IntroductionSection,
  CardNameStudent,
  CardAboutMe,
  SectionInfo,
} from "./style";
import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "../../styles/global";
import logo from "../../imgs/fullLogo.svg";

//icons
import {
  BiSolidPencil as EditIcon,
  BiLogoGithub as GithubIcon,
  BiLogoInstagramAlt as InstagramIcon,
  BiLogoFacebookCircle as FacebookIcon,
  BiExit as ExitIcon,
} from "react-icons/bi";
import { PiStudentBold as CourseIcon } from "react-icons/pi";
import { FaRegNewspaper as CurriculumIcon } from "react-icons/fa6";
import { LiaMapMarkerAltSolid as AddressIcon } from "react-icons/lia";
import { FiArrowUpRight as LinkIcon } from "react-icons/fi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

// mock
import { programs, projects } from "../../service/mocks";

// other
import {
  takeAvatar,
  takeCoverBackgroundFile,
} from "../../service/userManagement";
import { nameDisplayer } from "../../service/util/util";
import v from "voca";

// media queries
import { useMediaQuery } from "react-responsive";
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(
  Container,
  IntroductionSection,
  CardNameStudent,
  CardAboutMe,
  SectionInfo
);

export const StudentDashboard = ({ ...rest }) => {
  const { user, studentActivity } = useSelector((state) => state);
  const navigate = useNavigate();

  const [modalEditOpen, setModalEditOpen] = useState("closed");
  const [firstName, setFirstName] = useState("");
  const [initials, setInitials] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [coverBackgroundUrl, setCoverBackgroundUrl] = useState("");
  const [isHideCourses, setIsHideCourses] = useState(true);
  const [isHideProjects, setIsHideProjects] = useState(true);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  useEffect(() => {
    if (user.name !== "" && user.name) {
      setFirstName(nameDisplayer(user.name));
      setInitials(
        user.name
          .split(" ")
          .filter((_, index) => index < 2)
          .map((word) => word[0])
          .join("")
          .toUpperCase()
      );
    }
  }, [user.name]);

  useEffect(() => {
    takeAvatar()
      .then((url) => {
        setAvatarUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
    takeCoverBackgroundFile()
      .then((url) => {
        setCoverBackgroundUrl(url);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const continueStudies = () => {
    navigate(
      `/${studentActivity.lastProgram}/${studentActivity.lastModule}/${studentActivity.lastClass}`
    );
  };

  const continueStudiesInModule = (moduleName) => {
    navigate(
      `/${user.program}/${moduleName}/${
        studentActivity[user.program]?.[moduleName]?.lastClass || 1
      }`
    );
  };

  const continueStudiesInProject = (path) => {
    navigate(path);
  };

  const modalHandle = (openModalValue) => {
    if (openModalValue === modalEditOpen) {
      setModalEditOpen("closed");
    } else {
      setModalEditOpen(openModalValue);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          <Header hasPerfil>
            <img src={logo} alt="" className="logo" />
          </Header>
          <Container>
            <div className="profile">
              <motion.section
                variants={itemVariants}
                initial="hidden"
                animate="visible"
              >
                <ModalAboutMe
                  modalEditOpen={modalEditOpen}
                  modalHandle={modalHandle}
                />

                <ModalContacts
                  modalEditOpen={modalEditOpen}
                  modalHandle={modalHandle}
                />
                <ModalMainInfos
                  modalEditOpen={modalEditOpen}
                  modalHandle={modalHandle}
                  setAvatarUrl={setAvatarUrl}
                />
                <ModalSkills
                  modalEditOpen={modalEditOpen}
                  modalHandle={modalHandle}
                />
                <ModalCoverBackground
                  modalEditOpen={modalEditOpen}
                  modalHandle={modalHandle}
                  setCoverBackgroundUrl={setCoverBackgroundUrl}
                />
                <IntroductionSection coverBackgroundUrl={coverBackgroundUrl}>
                  <div className="cover">
                    <div className="card-header card-header--white">
                      <EditIcon
                        onClick={() => modalHandle("OpenCoverBackground")}
                      />
                    </div>
                  </div>
                  <div className="card-name">
                    <CardNameStudent>
                      <div className="card-name__photo">
                        {avatarUrl ? (
                          <img src={avatarUrl} alt="foto de perfil" />
                        ) : (
                          <p>{initials}</p>
                        )}
                      </div>
                      <div className="card-name__text">
                        <p className="first-name">
                          <span>{firstName}</span>{" "}
                          <span className="card-header">
                            <EditIcon
                              onClick={() => modalHandle("OpenMainInfos")}
                            />
                          </span>
                        </p>
                        <div className="short-about-me">
                          {user.shortAboutMe ? (
                            <p className="bold-gray">{user.shortAboutMe}</p>
                          ) : (
                            <p className="bold-gray">
                              Sou um(a) estudante de programação :{")"}
                            </p>
                          )}
                        </div>
                      </div>
                      <button
                        className="continue-studies"
                        onClick={() => continueStudies()}
                      >
                        Continuar estudos
                      </button>
                    </CardNameStudent>
                  </div>
                  <div className="geral-infos">
                    <CardAboutMe>
                      <section>
                        <div className="card-header">
                          <h1>Sobre mim</h1>
                          <EditIcon
                            onClick={() => modalHandle("OpenAboutMe")}
                          />
                        </div>
                        {user.aboutMe ? (
                          <p className="bold-gray">{user.aboutMe}</p>
                        ) : (
                          <p className="bold-gray">
                            Sou um(a) estudante de programação :{")"}
                          </p>
                        )}
                      </section>
                      <div className="line" />
                      <section>
                        <div className="card-header">
                          <h1>Experiência</h1>
                          <EditIcon
                            onClick={() => modalHandle("OpenAboutMe")}
                          />
                        </div>
                        {user.aboutExp ? (
                          <p>{user.aboutExp}</p>
                        ) : (
                          <p className="bold-gray">
                            Estou começando minha jornada aqui
                          </p>
                        )}
                      </section>
                    </CardAboutMe>
                    <SectionInfo>
                      <h2>Skills</h2>
                      <ul className="skills">
                        {user.skills &&
                          user.skills.map((skill, index) => (
                            <li className="skill" key={index}>
                              {skill}
                            </li>
                          ))}
                        <li>
                          <button
                            className="skill add-skill-button"
                            onClick={() => modalHandle("OpenSkills")}
                          >
                            +
                          </button>
                        </li>
                      </ul>
                      <div className="line" />
                      <div className="address-box">
                        <div className="card-header">
                          <EditIcon
                            onClick={() => modalHandle("OpenContacts")}
                          />
                        </div>
                        <h2>Endereço</h2>
                        <p className="address">
                          <AddressIcon />{" "}
                          <span>
                            {user.city}, {user.country}
                          </span>
                        </p>
                        <nav>
                          <ul className="web-address-list">
                            {user.email && (
                              <li>
                                <h2>Gmail</h2>
                                <a
                                  href={`mailto: ${user.email}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <span className="web-address-name">
                                    {user.email}
                                  </span>{" "}
                                  <LinkIcon />
                                </a>
                              </li>
                            )}
                            {[
                              "website",
                              "linkedin",
                              "instagram",
                              "github",
                              "facebook",
                              "portfolio",
                            ]
                              .filter((linkKey) => !!user[linkKey])
                              .map((linkKey, index) => (
                                <li key={index}>
                                  <h2>{v.capitalize(linkKey, true)}</h2>
                                  <a
                                    href={user[linkKey]}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span className="web-address-name">
                                      {user[linkKey]}
                                    </span>
                                    <LinkIcon />
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </nav>
                      </div>
                    </SectionInfo>
                  </div>
                </IntroductionSection>
              </motion.section>
              <section className="continue-in-module">
                <motion.div
                  variants={itemVariants}
                  className="continue-in-module__header"
                >
                  <h2>Cursos</h2>
                  <span></span>
                </motion.div>
                {user.program && (
                  <div
                    onMouseEnter={() => setIsHideCourses(false)}
                    onMouseLeave={() => setIsHideCourses(true)}
                  >
                    <Carousel
                      cols={isDesktopOrLaptop ? 2 : 3}
                      rows={1}
                      gap={10}
                      hideArrow={isDesktopOrLaptop ? false : isHideCourses}
                      className="continue-in-module__cards-module"
                    >
                      {Object.values(programs[user.program]?.modules).map(
                        (module, index) => (
                          <Carousel.Item>
                            <motion.article
                              variants={itemVariants}
                              className="continue-in-module__card-module"
                              key={index}
                            >
                              <button
                                onClick={() =>
                                  continueStudiesInModule(module.key)
                                }
                                className="continue-in-module__button"
                              >
                                <div
                                  onClick={() =>
                                    continueStudiesInModule(module.key)
                                  }
                                  className="continue-in-module__icon"
                                >
                                  <FontAwesomeIcon
                                    icon={module.icon}
                                    style={{
                                      color: module.iconColor,
                                    }}
                                  />
                                </div>
                                <div className="continue-in-module__card-module__text">
                                  <div className="continue-in-module__card-module__text__head">
                                    <h3>{module.title}</h3>
                                    <p>
                                      {module.classes.length - 1}{" "}
                                      {module.classes.length === 2
                                        ? "aula"
                                        : "aulas"}
                                    </p>
                                  </div>

                                  <p className="continue-in-module__description">
                                    {module.description}
                                  </p>
                                </div>
                              </button>
                            </motion.article>
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </div>
                )}
              </section>
              <section className="projects">
                <motion.div
                  variants={itemVariants}
                  className="projects__header"
                >
                  <h2>Mini projetos</h2>
                  <span></span>
                </motion.div>
                <div
                  onMouseEnter={() => setIsHideProjects(false)}
                  onMouseLeave={() => setIsHideProjects(true)}
                >
                  <Carousel
                    cols={isDesktopOrLaptop ? 2 : 3}
                    rows={1}
                    gap={10}
                    hideArrow={isDesktopOrLaptop ? false : isHideProjects}
                    className="projects__cards-project"
                  >
                    {projects.map((project, index) => (
                      <Carousel.Item>
                        <motion.article
                          variants={itemVariants}
                          className="projects__card-project"
                          key={index}
                        >
                          <div className="projects__icon">
                            <FontAwesomeIcon
                              icon={project.icon}
                              style={{ color: project.iconColor }}
                            />
                            <span className="projects__tag">{project.tag}</span>
                          </div>
                          <div className="projects__card-project__text">
                            <div className="projects__level">
                              <span>Nível</span>
                              {[...Array(3)].map((_, index) =>
                                index < project.level ? (
                                  <div
                                    className="projects__level__circle projects__level__circle--filled"
                                    key={index}
                                  />
                                ) : (
                                  <div
                                    className="projects__level__circle"
                                    key={index}
                                  />
                                )
                              )}
                            </div>
                            <h3>{project.title}</h3>
                            <p className="projects__description">
                              {project.description}
                            </p>
                            <button
                              onClick={() =>
                                continueStudiesInProject(project.path)
                              }
                            >
                              {project.textButton}
                            </button>
                          </div>
                        </motion.article>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              </section>
            </div>
          </Container>
        </motion.div>
      </MediaContainer>
    </motion.div>
  );
};
