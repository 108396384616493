import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (Container)=>{
    return styled.div`
        ${Container}{
            ${media.desktopG`
  
                .group-nav{

                    display: block;
                    position: fixed;
                    top: 0px;
                    right: 0px;
                    background-color: rgb(7 2 54 / 92%);
                    max-width: 70%;
                    min-height: 100vh;
                    z-index: 1000;
                    border-left: 4px solid white;
                    backdrop-filter: blur(8px);
                    transition:1s;
                    transform: translateX(100vw);

                    .header-nav{
                        display: flex;
                        flex-direction: column;
                        ul{
                            display: flex;
                            flex-direction: column;
                            margin-top: 60px
                        }

                        li{
                            margin: 12px 0
                        }
                    }

                    .nav-close-btn{
                        display: block;
                    }

                    .create-account-header,
                    .header-nav__account-nav__entrar{
                        display: none
                    }
                }
                
                .nav-btn{
                    display: block;
                    background: none;
                    border: none;
                    color: white;
                    cursor: pointer;
                    font-size: 22px;
                    position: absolute;
                    top: 40px;
                    right: 40px;
                }
            
                .reponsive-menu{
                    transform: none;
                    
                }

            `}

            ${media.mobileG`
                padding: 24px 20px 10px;
                .nav-btn{
                    font-size: 18px;
                    right: 20px;
                }

                img{
                    width: 120px;
                }
            
            `}
        }
    `
}
