// react
import { useState, useEffect } from "react";

// router dom
import { useNavigate, useParams, Link } from "react-router-dom";

// redux
import { useDispatch, useSelector } from "react-redux";
import { updateStudentActivityThunk } from "../../store/modules/student_activity/thunks";

// components
import { Header } from "../../components/header";
import { Button } from "../../components/button";
import { Video } from "../../components/video";
import { CourseNav } from "../../components/course_nav";
import { LessonNavMobile } from "../../components/lesson_nav_mobile";

// style
import { Container } from "./style";
import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "../../styles/global";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faStar } from "@fortawesome/free-solid-svg-icons";
import { TiArrowLeftOutline as Arrows } from "react-icons/ti";

// mocks
import { programs } from "../../service/mocks";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container);

export const PagLesson = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { courseName, moduleName, lessonIndex } = useParams();

  const [currentLesson, setCurrentLesson] = useState(
    programs[courseName].modules[moduleName].classes[parseInt(lessonIndex)]
  );

  const [initial, setInitial] = useState("hidden");
  const [animate, setAnimate] = useState("visible");

  const studentActivity = useSelector((state) => state.studentActivity);

  useEffect(() => {
    setInitial("visible");
    setAnimate("hidden");
    setTimeout(() => {
      setCurrentLesson(
        programs[courseName].modules[moduleName].classes[parseInt(lessonIndex)]
      );

      dispatch(
        updateStudentActivityThunk({
          ...studentActivity,
          lastProgram: courseName,
          lastModule: moduleName,
          lastClass: lessonIndex,
        })
      );

      setInitial("hidden");
      setAnimate("visible");
    }, 500);
  }, [courseName, moduleName, lessonIndex, navigate]);

  const back = () => {
    navigate("/" + courseName);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header hasPerfil>
            <Button onClick={() => back()} className="header__back-button">
              <Arrows /> Voltar
            </Button>
          </Header>
          <motion.main
            variants={containerVariants}
            initial={initial}
            animate={animate}
          >
            <section className="lesson">
              <div className="video-container">
                <div className="video-container__header">
                  <motion.h1
                    variants={itemVariants}
                    className="video-container__title"
                  >
                    {currentLesson?.name}
                  </motion.h1>
                  <div className="lesson__download-button-stars-box">
                    <FontAwesomeIcon
                      icon={faDownload}
                      style={{ color: "#676767", fontSize: "1.5rem" }}
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{
                        color: "yellow",
                      }}
                    />
                    <span className="lesson__grade">4.9/5</span>
                  </div>
                </div>
                <Video
                  url={currentLesson?.isAvailable ? currentLesson?.video : ""}
                  className="video"
                />
              </div>
              <LessonNavMobile className="lesson__nav-mobile-buttons" />
              <div className="lesson__video-info-box">
                <motion.p className="lesson__intro" variants={itemVariants}>
                  {currentLesson?.isAvailable
                    ? currentLesson?.introduction
                    : currentLesson?.dateAvailable
                    ? `O material será liberado dia ${currentLesson?.dateAvailable}`
                    : "Não há prazo para liberar esse material"}
                </motion.p>
                <Button
                  onClick={() =>
                    navigate(
                      `/${courseName}/${moduleName}/${lessonIndex}/conteudo`
                    )
                  }
                  className="lesson__material-link"
                >
                  Material
                </Button>
              </div>
            </section>
            <CourseNav
              classes={programs[courseName].modules[moduleName].classes}
              className="pag-lesson__course-nav"
              setInitial={setInitial}
              setAnimate={setAnimate}
            />
          </motion.main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
