import styled from 'styled-components';
import { theme } from "../../styles/global";
  
export const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 10vh;
   font-size: ${theme.title.fontSize};
`
export const Radio = styled.input`
   display: none;
`

export const Rating = styled.div`
   cursor: pointer;
`