// router dom
import { Link } from "react-router-dom";

// react redux
import { useDispatch } from "react-redux";
import { updateIndex } from "../../store/modules/current_lesson/actions";

// style
import { Container, Lesson } from "./style";
import { motion } from "framer-motion";
import { itemVariants } from "../../styles/global";
import { useCollapse } from "react-collapsed";

// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

export const ModuleNav = ({
  title,
  classes,
  moduleName,
  courseName,
  disabled,
  ...rest
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const dispatch = useDispatch();

  const selector = (moduleName, courseName, lessonIndex) => {
    dispatch(updateIndex({ moduleName, courseName, lessonIndex }));
  };

  return (
    <motion.div variants={itemVariants}>
      <Container disabled={disabled} {...rest}>
        <div className="module-nav__top">
          <h2>
            {title} ({classes.length - 1} aulas)
          </h2>
          {!disabled ? (
            <button className="extend-button" {...getToggleProps()}>
              {!isExpanded ? (
                <FontAwesomeIcon icon={faCaretRight} />
              ) : (
                <FontAwesomeIcon icon={faCaretDown} />
              )}
            </button>
          ) : (
            <div className="extend-button">
              <FontAwesomeIcon icon={faCaretRight} />
            </div>
          )}
        </div>
        <nav {...getCollapseProps()}>
          <ul>
            {classes.map(
              (lesson, index) =>
                index !== 0 && (
                  <Lesson key={index} isAvaliable={lesson.isAvailable}>
                    <Link
                      to={`/${courseName}/${moduleName}/${index}`}
                      onClick={() => selector(index)}
                    >
                      {index}. {lesson.name}
                    </Link>
                  </Lesson>
                )
            )}
          </ul>
        </nav>
      </Container>
    </motion.div>
  );
};
