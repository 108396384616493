import { updateStudentActivity } from './actions';
import { uploadStudentActivity, downloadStudentActivity } from '../../../service/studentActivityManagement';

export const updateStudentActivityThunk = (payload, updateFirestore=true) => (dispatch) => {
  try {
    const { lastClass, lastModule, lastProgram } = payload;
    const payloadLastProgram = payload[lastProgram] || {};
    const payloadLastModule = payloadLastProgram[lastModule] || {};
    let payloadCurrentClass = payloadLastModule?.currentClass ? parseInt(payloadLastModule.currentClass) : -1;
    const lastClassNumber = parseInt(lastClass);

    if (isNaN(payloadCurrentClass) || payloadCurrentClass == null) {
      payloadCurrentClass = -1;
    }

    const currentClass = lastClassNumber > payloadCurrentClass ? lastClassNumber : payloadCurrentClass;
    const dataAtual = new Date();
    const lastUpdate = dataAtual.getTime();

    const normalizedData = {
      lastUpdate,
      lastClass: lastClassNumber,
      lastModule,
      lastProgram,
      [lastProgram]: {
        ...payloadLastProgram,
        lastModule: lastModule,
        currentModule: "Definir ponteiro",
        [lastModule]: {
          ...payloadLastModule,
          lastClass: lastClassNumber,
          currentClass,
        },
      },
    };

    dispatch(updateStudentActivity(normalizedData));

    localStorage.setItem('studentActivity', JSON.stringify(normalizedData));

    updateFirestore && uploadStudentActivity(normalizedData);
  } catch (e) {
    throw new Error(e);
  }
}

export const getStudentActivityThunk = () => async (dispatch) => {
  try {
    const firestoreStudentActivity = await downloadStudentActivity();
    const localStudentActivity = JSON.parse(localStorage.getItem('studentActivity'));

    if (firestoreStudentActivity?.lastUpdate > localStudentActivity?.lastUpdate) {
      dispatch(updateStudentActivity(firestoreStudentActivity));
      localStorage.setItem('studentActivity', JSON.stringify(firestoreStudentActivity));

    } else if (firestoreStudentActivity?.lastUpdate < localStudentActivity?.lastUpdate) {
      dispatch(updateStudentActivityThunk(localStudentActivity));
    }
  } catch (e) {
    throw new Error(e);
  }
}
