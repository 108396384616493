import styled from "styled-components";

export const Content = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.white};
  font-size: 1.3rem;
  button {
    border: none;
    background: transparent;
    color: ${({ theme }) => theme.white};
    font-size: 1.3rem;
    padding: 0;
    cursor: pointer;
  }
`;
