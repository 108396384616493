import styled from "styled-components";

export const Container = styled.section`
  display: inline-block;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.blue : theme.white};
  border-radius: 7px;
  padding: 16px 0;
  opacity: ${({ disabled }) => (disabled ? "0.2" : "1")};
  .module-nav__top {
    padding: 0 2rem;
    width: 25rem;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      font-size: ${({ theme }) => theme.title.fontSize};
      margin: 0;
      color: ${({ disabled, theme }) =>
        disabled ? theme.white : theme.darkBlue};
    }
    button {
      &:hover {
        cursor: pointer;
      }
    }
    .extend-button {
      font-size: 2rem;
      color: ${({ disabled, theme }) =>
        disabled ? theme.white : theme.darkBlue};
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 20px 20px 0;
  }
`;

export const Lesson = styled.li`
  padding: 10px 1rem;
  a {
    color: ${({ isAvaliable, theme }) =>
      isAvaliable ? theme.darkBlue : theme.darkGray};
    font-weight: bold;
    text-decoration: none;
  }
`;
