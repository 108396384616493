import styled from "styled-components";

export const Container = styled.header`
  padding: 24px 50px 10px;
  display: flex;
  justify-content: space-between;
  .header-nav {
    display: flex;
    justify-content: space-between;
  }
  nav{
    display: contents;
  }
  .nav-btn, .nav-close-btn {
    display: none;
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    a {
      color: ${({ theme }) => theme.white};
      font-size: 1rem;
      font-weight: bold;
      font-family: "Poppins", sans-serif;
    }
  }
  .header-nav__landing-page-nav {
    width: 480px;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .header-nav__account-nav {
    .header-nav__account-nav__entrar {
      margin-right: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .create-account-header {
    background-color: ${({ theme }) => theme.blue};
    padding: 10px 40px;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    white-space: nowrap;
  }

`;
