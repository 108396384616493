import { REINICIALIZER_REGISTER, UPDATE_REGISTER } from "./actionsTypes";

export const updateRegister = (registerData) => ({
  type: UPDATE_REGISTER,
  registerData,
});

export const reinicializerRegister = () => ({
  type: REINICIALIZER_REGISTER,
});
