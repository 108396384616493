import React from "react";
import { Container, InputStyled } from "./style";
import { motion } from "framer-motion";
import InputMask from "react-input-mask";
import { itemVariants } from "../../styles/global";

export const PhoneNumberInput = ({
  title,
  name,
  register,
  errors,
  placeholder,
  mask,
  ...rest
}) => {
  return (
    <motion.div variants={itemVariants} {...rest}>
      <Container errorsName={errors[name]}>
        <label>{title}</label>
        <InputStyled
          as={InputMask}
          name={name}
          placeholder={placeholder}
          mask={mask}
          {...register(name)}
        />
        <span className="error">{errors[name]?.message}</span>
      </Container>
    </motion.div>
  );
};

export default PhoneNumberInput;
