export const logicaIIAulas = [
  {
    name: "",
    video: "",
    introduction: "",
    Content: () => {
      return <></>;
    },
  },
  {
    name: "Algoritmo",
    video: "https://youtu.be/7aUuo0Jbvi0",
    introduction: "Primeira aula do instrutor Danilo",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Exercício 01 - Lógica de Programação</h2>
            <p>
              Na Lenda, o problema das torres de Hanói foi proposto pelo
              matemático francês Edouard Lucas, em 1883. Lucas elaborou para seu
              “invento” uma lenda curiosa sobre uma torre muito grande. A “torre
              de Brama”, que foi criada “início dos tempos”, com três hastes
              contendo 64 discos concêntricos (mesmo centro). O “criador” do
              universo também criou uma comunidade de monges cuja única
              atividade seria mover os discos da haste original (“A”) para uma
              de destino (“C”). O “criador” estabeleceu que o mundo acabaria
              quando os monges terminassem sua tarefa. Porém, os monges deveriam
              respeitar três regras na sua execução: 1ª) pode‐se mover um único
              disco por vez; 2ª) um disco maior não pode ser colocado sobre um
              disco menor; 3ª) um disco deve estar sempre numa das três hastes,
              ou em movimento. Sua tarefa é encontrar a regra de movimentação
              ótima (que atinja o objetivo com um número mínimo de movimentos) e
              com isso estimar quanto tempo ainda nos resta! Suponha que cada
              disco leve 1 segundo para ser movido. Tente encontrar uma fórmula
              que, dado "n" devolva o número mínimo de movimentos para "n"
              discos. Questão 1: A partir do jogo interativo, tente preencher a
              tabela com o menor número de movimentos necessários para atingir o
              objetivo deste desafio
            </p>
            <table>
              <tr>
                <th>Número de Discos</th>
                <th>Número Mínimo de Movimentos</th>
              </tr>
              <tr>
                <td>1</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td></td>
              </tr>
              <tr>
                <td>3</td>
                <td></td>
              </tr>
              <tr>
                <td>4</td>
                <td></td>
              </tr>
              <tr>
                <td>5</td>
                <td></td>
              </tr>
              <tr>
                <td>6</td>
                <td></td>
              </tr>
              <tr>
                <td>7</td>
                <td></td>
              </tr>
            </table>
          </section>
        </>
      );
    },
  },
];
