import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (container, itemContainer) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
            width: calc(100vw - (560px + 3rem));
            h1 {
                display: none;
            }
            ${itemContainer} {
                margin: 1rem 0;
                .thumb-box {
                    display: none;
                }
            }
        `}

      ${media.tablet`
            max-height: unset;
            max-width: unset;
            overflow-y: visible;
            width: 95vw;
            h1 {
                display: block;
            }
            ${itemContainer} {
                .thumb-box {
                    display: block;
                }
            }
        `}
    }
  `;
  return mediaContainer;
};
