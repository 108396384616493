import { Container, InputStyled } from "./style";

export const DateInput = ({ title, name, register, errors, ...rest }) => {
  return (
    <Container {...rest}>
      <label>{title}</label>
      <InputStyled name={name} type="date" {...register(name)} />
      <span className="error">{errors[name]?.message}</span>
    </Container>
  );
};
