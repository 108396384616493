import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (Container, TextContainer, FormContainer )=>{
    return styled.div`
        ${Container}{
            ${media.desktop`
                main{
                    justify-content: center
                }

                .steps{
                    width: 100vw;
                }

                ${TextContainer}{
                    display: none
                    

                }

                ${FormContainer}{
                    width: 100vw;
                } 
            `}

            ${media.mobileGG`
                ${FormContainer}{
                    padding:40px 20px;
                    option{
                        font-size: 12px
                    }
                    .phone-box{
                        flex-direction: column;

                        .ddi, .phone{
                            width: 100%;
                        }

                     
                    }

                    .fieldset-options label{
                        font-size: 12px
                    }
                } 
            `}
        }

    `
}