// react
import { useState } from "react";

// router dom
import { Link } from "react-router-dom";

// components
import { Step1, Step2, Step3, Step4 } from "./forms";

// style
import logo from "../../imgs/fullLogo2.svg";
import { Container, TextContainer, FormContainer } from "./style";
import { motion, AnimatePresence } from "framer-motion";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShapes,
  faFilm,
  faPersonChalkboard,
} from "@fortawesome/free-solid-svg-icons";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, TextContainer, FormContainer )

export const Register = () => {
  const [step, setStep] = useState(1);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
    <MediaContainer>
      <Container>
        <main>
          <TextContainer>
            <div>
              <h1>
                Skcoders, plataforma de ensino de tecnologia. Aprenda
                programação do seu jeito, no seu tempo.
              </h1>
              <section>
                <FontAwesomeIcon icon={faShapes} />
                <p>
                  <strong>Formação básica.</strong> O programa abrange os
                  principais conceitos e habilidades necessárias para se tornar
                  um desenvolvedor fullstack.
                </p>
              </section>
              <section>
                <FontAwesomeIcon icon={faFilm} />
                <p>
                  <strong>Aulas gravadas.</strong> Os alunos têm acesso a aulas
                  gravadas, o que permite que estudem em seu próprio ritmo e
                  revisitem o conteúdo sempre que necessário.
                </p>
              </section>
              <section>
                <FontAwesomeIcon icon={faPersonChalkboard} />
                <p>
                  <strong>Aulas ao vivo.</strong> As aulas ao vivo oferecem
                  interação direta com instrutores especializados, permitindo
                  esclarecer dúvidas, obter feedback em tempo real e participar
                  de discussões com outros estudantes.
                </p>
              </section>
            </div>
          </TextContainer>
          <FormContainer step={step}>
            <div className="steps">
              <div className="bar step1-bar" />
              <div className="bar step2-bar" />
              <div className="bar step3-bar" />
              <div className="bar step4-bar" />
            </div>
            <Link to="/">
              <img src={logo} alt="" className="logo" />
            </Link>
            <h1>Crie uma conta</h1>
            <p className="form-container__subtitle">
              Entre na nossa plataforma de ensino de tecnologia
            </p>
            <AnimatePresence>
              {step === 1 && <Step1 setStep={setStep} />}
              {step === 2 && <Step2 setStep={setStep} />}
              {step === 3 && <Step3 setStep={setStep} />}
              {step === 4 && <Step4 setStep={setStep} />}
            </AnimatePresence>
            <div className="toLogin">
              <p className="toLogin__question">Já possui conta?</p>
              <Link to="/entrar" className="toLogin__link">
                Fazer login
              </Link>
            </div>
          </FormContainer>
        </main>
      </Container>
      </MediaContainer>
    </motion.div>
  );
};
