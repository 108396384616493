// redux
import { useNavigate } from "react-router";

// componentes e paginas
import { Header } from "../../components/header";
import { Button } from "../../components/button";

// style
import { motion } from "framer-motion";
import { Container } from "./style";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container);

export const PagNotFound = ({ ...rest }) => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header>
            <Button onClick={() => back()} className="header__back-button">
              Voltar
            </Button>
          </Header>
          <main>
            <div>
              <h1>Página não encontrada</h1>
              <p>404</p>
            </div>
          </main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
