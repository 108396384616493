import styled from "styled-components";

export const Container = styled.div`
  nav {
    display: flex;
    align-items: center;
    width: 250px;
  }
  .menu-list {
    list-style-type: none;
    z-index: 4;
    position: absolute;
    top: 4rem;
    right: 3rem;
    padding: 0;
    background-color: ${({ theme }) => theme.white};
    width: 200px;
  }
  .head__main-infos {
    font-size: 2rem;
    color: ${({ theme }) => theme.white};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .head__user-name {
      font-size: 1.1rem;
      margin-right: 20px;
    }
    .head__main-button {
      background-color: transparent;
      border: none;
      color: ${({ theme }) => theme.white};
      font-size: 1rem;
      cursor: pointer;
    }
    .head__user-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        min-height: 32px;
        object-fit: cover;
      }
    }
  }
  .icon {
    margin-right: 10px;
  }
`;
