// react
import { useEffect, useState } from "react";

// router dom
import { useParams, useNavigate } from "react-router-dom";

// style
import { motion } from "framer-motion";
import { Container } from "./style";

// components
import { Header } from "../../components/header";
import { Button } from "../../components/button";
import { Lesson } from "../../components/lesson";
import { LessonNavMobile } from "../../components/lesson_nav_mobile";
import { Rate } from "../../components/rating";
import { Textarea } from "../../components/textarea";

//icons
import { TiArrowLeftOutline as Arrows } from "react-icons/ti";

// mock
import { programs } from "../../service/mocks";

// others
import { addInDiscord } from "../../service/util/util";
// import * as yup from "yup";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";

//responsibility
import { mediaQueries } from "./media";
import { MenuTop } from "../../components/menu_top";
const MediaContainer = mediaQueries(Container);

export const PagLessonContent = ({ ...rest }) => {
  let { courseName, moduleName, lessonIndex } = useParams();
  const [lesson, setLesson] = useState(
    programs[courseName].modules[moduleName].classes[parseInt(lessonIndex)]
  );

  const navigate = useNavigate();

  const back = () => {
    navigate(`/${courseName}/${moduleName}/${lessonIndex}`);
  };

  useEffect(() => {
    setLesson(
      programs[courseName].modules[moduleName].classes[parseInt(lessonIndex)]
    );
  }, [courseName, moduleName, lessonIndex]);

  // const formSchema = yup.object().shape({
  //   rating: yup.string(),
  // });
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(formSchema),
  // });

  // const onSubmitFunction = ({ rating }) => {
  //   console.log({ rating });
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
      <MediaContainer>
        <Container>
          <Header hasPerfil>
            <Button onClick={() => back()} className="header__back-button">
              <Arrows /> Voltar
            </Button>
          </Header>
          <main>
            <LessonNavMobile className="lesson__nav-mobile-buttons" />
            <Lesson lesson={lesson} />
            {/* <div className="rating-box">
              <form onSubmit={handleSubmit(onSubmitFunction)}>
                <div  className="rating-area">
                  <Rate/>
                </div>
                
                <div className="rating-box_content">
                  <Textarea
                        name="rating"
                        register={register}
                        errors={errors}
                        placeholder="Deixe aqui seu comentário. (Ajude-nos a melhorar)"
                        className="description-input"
                      >

                  </Textarea>
                  <button type="button" className="send_button">
                    <FontAwesomeIcon icon={faPaperPlane} />
                    
                  </button>
                </div>
              </form>
            </div> */}
            <div className="helper-box">
              <p>
                Precisa de ajuda? Acesse nossa comunidade e fale com o instrutor
              </p>
              <Button onClick={() => addInDiscord()}>Ajuda</Button>
            </div>
          </main>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
