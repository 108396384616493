import { useState } from "react";
import { Container } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";



export const Download = ({title,urlToDownload}) => {

    const [download,setDownload]=useState('');
    const resetUrl =()=>{setTimeout(() => {setDownload('')},2000)};
    
    return(
        <Container>
                
                <p className="download_title">{title}</p>
               
                    <button onClick={() => {
                        setDownload(urlToDownload);
                        resetUrl()
                        }}>
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                    
                    {download && (
                        <iframe
                           src={download}>
                        </iframe>
                    )}
        </Container>
    )
    
}