import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0 0 4rem;

  main {
    padding: 0 2rem;
  }
  h1 {
    color: ${({ theme }) => theme.white};
    font-size: 2.5rem;
  }
  .main__title {
    padding: 0 0 2rem 1rem;
    text-align: center;
  }
`;
