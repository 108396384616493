import styled from "styled-components";

export const Container = styled.div`
  padding: 0 0 0 2rem;
  color: ${({ theme }) => theme.white};
  main {
    padding: 0 2rem;

    .main__title {
      font-size: 2rem;
      text-align: center;
      margin-top: 0;
    }
    .main_text {
      text-align: justify;
      font-size: 1.3rem;
      padding: 0 2rem;
    }
  }
  hr {
    background-color: #74e4e5;
    height: 0.1rem;
    width: 80%;
    margin: 2rem auto;
  }
  .main_cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin: 2rem;
  }
`;
