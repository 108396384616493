import styled from "styled-components";



export const Container = styled.div`
  padding:0;
 
  
  main {
    
    padding: 1rem;
    display:flex;
    flex-direction:column;
    justify-content:center;
    
    
  }
  .buttons{
    margin-bottom:6rem;
    justify-content:space-around;
   
  }
  
  .star{
    font-size:3rem;
    
  }
  textarea{
    margin-top:2rem;
    padding:4rem 1rem 2rem 1rem;
    width:90%;
    height:25vh;
    text-align:center;

    ::placeholder{
      font-size:0.9rem;
      color:#D2D0D0;
      
    }
  }
  .send-button button{
    background-color:#74E4E5;
    
    }
 
  
  
`;