import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkBlue};
  padding-bottom: 16px;
  header {
    padding: 0 2rem;
  }
  .header__back-button {
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      background-color: ${({ theme }) => theme.blue};
      color: ${({ theme }) => theme.white};
      &:hover {
        background-color: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.blue};
      }
    }
  }
  .lesson__nav-mobile-buttons {
    display: none;
    width: 90vw;
  }
  main {
    padding: 0 2rem;
  }
  section {
    padding: 2rem;
    margin-top: 2rem;
  }
  .helper-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.darkGray};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    padding: 0 1rem 0 2rem;
    margin: 4rem 0 0;
    p {
      color: ${({ theme }) => theme.white};
      font-weight: bold;
    }
  }
  li {
    margin: 0.5rem 0;
  }
  table,
  td,
  th {
    border: 2px solid ${({ theme }) => theme.darkBlue};
    border-collapse: collapse;
    text-align: center;
    padding: 0.1rem 1rem;
  }
  table {
    margin: 1rem;
  }
  th {
    background-color: ${({ theme }) => theme.darkBlue};
  }
  .red {
    color: red;
  }
  .lightblue {
    color: lightblue;
  }
  .bold {
    font-weight: bold;
  }

  .rating-box {
    width: 100%;
    height: 15vh;
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    form {
      height: 8rem;
      display: flex;
      justify-content: center;
    }
    textarea {
      max-height: 5rem;
      width: 30rem;
      margin-top: 2rem;
      margin-bottom: 0;
      padding: 1rem;
      text-align: center;

      ::placeholder {
        padding-top: 1.5rem;
        font-size: 0.8rem;
        color: #d2d0d0;
      }
    }

    .rating-area {
      background-color: #1b1b1b;
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      position: absolute;
      padding: 0 1rem;
      max-width: 20rem;
      div {
        height: fit-content;
      }
    }
    .star {
      font-size: 1.7rem;
      padding: 0 0.5rem;
    }
  }
  .rating-box_content {
    display: flex;
    align-items: end;
  }
  .send_button {
    background: none;
    border: none;
    color: #74e4e5;
    font-size: 1.2rem;
    padding: 0.5rem;
    position: relative;
    left: -3rem;
    cursor: pointer;
  }
`;
