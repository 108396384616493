import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from "redux";

import ReduxThunk from "redux-thunk";

import { studentActivityReducer } from "./modules/student_activity/reducer";
import { currentLessonReducer } from "./modules/current_lesson/reducer";
import { userReducer } from "./modules/user/reducer";
import { registerReducer } from "./modules/register/reducer";

const reducers = combineReducers({
  studentActivity: studentActivityReducer,
  currentLesson: currentLessonReducer,
  user: userReducer,
  register: registerReducer,
});

function returnStore() {
  if (process.env.REACT_APP_NODE_ENV === "development") {
    return createStore(
      reducers,
      compose(
        applyMiddleware(ReduxThunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    );
  }
  return createStore(reducers, applyMiddleware(ReduxThunk));
}

const store = returnStore();

export default store;
