import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (Container, TextContainer, FormContainer)=>{
    return styled.div`
        ${Container}{
            ${media.desktop`
                ${TextContainer}{
                    display: none
                }

                ${FormContainer}{
                    width: 100vw;
                } 
            `}


            ${media.mobileGG`
                ${FormContainer}{
                    padding:40px 20px;
                }
            
            `}
        }
    
    `
}