import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  position: relative;
  label {
    font-weight: bold;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.darkBlue};
  }
  .arrow {
    position: absolute;
    top: 20px;
    right: 32px;
    width: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    pointer-events: none;
    color: ${({ theme }) => theme.gray};
    &:hover {
      cursor: pointer;
    }
  }
  .error {
    color: ${({ theme }) => theme.errorMsg};
    font-size: 0.8rem;
  }
`;

export const InputStyled = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: ${({ theme }) => theme.defaultBorderRadius};
  width: 100%;
  height: 2.5rem;
  color: ${({ theme }) => theme.darkBlue};
  font-size: 1rem;
  padding: 0 0 0 16px;
  margin: 0.5rem 0;
  &:hover {
    cursor: pointer;
  }
  &:focus-visible {
    border: 1px solid ${({ theme }) => theme.blue};
    outline: 1px solid ${({ theme }) => theme.blue};
  }
`;
