import styled from "styled-components";
import womanBackground from "../../imgs/woman_background.png";

export const Container = styled.div`
  background: linear-gradient(to right, black, 70%, transparent),
    url(${womanBackground});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 
  .blue {
    color: ${({ theme }) => theme.blue};
  }
  .btn-account{
    padding: 10px 40px;
    border: 1px solid transparent;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    background-color: ${({ theme }) => theme.blue};
  }

  .btn-theme-white{
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.white};
    display: none;
  }
  p{
    line-height: 1.5;
    word-spacing: 1.5px;
  }
`;

export const FirstSection = styled.section`
  padding: 200px 50px;
  h1 {
    font-size: clamp(1.2rem, 4vw, 3rem);
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    font-family: "Poppins", sans-serif;
    margin: 12px 0;
  }

  p {
    color: ${({ theme }) => theme.white};
    font-size:  clamp(1rem, 4vw, 1.4rem);
    font-weight: lighter;
    max-width: 540px;
    margin: 0 0 50px;
  }
`;

export const Techs = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px;
  background-color: ${({ theme }) => theme.white};
  .tech {
    svg {
      font-size: clamp(1.6rem, 6vw, 4rem);
      margin: 0 1rem 0 0;
    }
    margin: 0;
    color: ${({ theme }) => theme.black};
  }
`;

export const AboutProgram = styled.section`
  background-color: ${({ theme }) => theme.white};
  padding: 0 120px;
  text-align: center;
  h1 {
    margin: 0;
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
  }
  p {
    font-size: 1.2rem;
    margin: 120px 0 0;
  }

`;

export const Advantages = styled.section`
  background-color: ${({ theme }) => theme.white};
  padding: 150px 40px 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  article {
    margin: 0 0 120px;
    svg {
      color: ${({ theme }) => theme.blue};
      font-size: 2rem;
    }
    h2 {
      font-family: "Poppins", sans-serif;
      margin: 10px 0px;
      font-weight: 600;
    }
    p {
      max-width: 334px;
      font-weight: lighter;
    }
  }
`;

export const Participate = styled.section`
  background-color: rgb(7, 2, 54);
  padding: 0px 40px 0px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  article {
    min-width: 600px;
    h1 {
      color: ${({ theme }) => theme.white};
      font-size: 3rem;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
    }
    p {
      color: ${({ theme }) => theme.white};
      font-size: 1.2rem;
      font-weight: lighter;
      font-family: "Poppins", sans-serif;
      margin-bottom: 40px;
      width: 500px;
      .bold {
        font-weight: normal;
      }
    }

  }
  .models {
    display: flex;
    img {
      border: 4px solid ${({ theme }) => theme.white};
      border-radius: ${({ theme }) => theme.defaultBorderRadius};
      box-sizing: border-box;
    }
    .models-left {
      position: relative;
      top: 150px;
      margin-right: 10px;
      img:first-child {
        margin-bottom: 10px;
      }
    }
    .models-right {
      position: relative;
      top: -150px;
      img:first-child {
        margin-bottom: 10px;
      }
    }
  }
`;

export const Motivations = styled.section`
  background-color: ${({ theme }) => theme.white};
  padding: 300px 100px 0px;
  font-family: "Poppins", sans-serif;
  h1 {
    text-align: center;
    font-size: 3rem;
    max-width: 750px;
    margin: 0 auto 150px;
  }
  .cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  article {
    background-color: rgb(7, 2, 54);
    padding: 60px 0 45px 15px;
    width: 525px;
    margin: 0 0 120px 0;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    .cards__header {
      display: flex;
      align-items: center;
      svg {
        margin: 40px 40px 40px 30px;
        font-size: 2rem;
        color: ${({ theme }) => theme.blue};
      }
      h2 {
        color: ${({ theme }) => theme.white};
        font-size: 2.3rem;
        font-weight: 600;
        width: 385px;
      }
    }
    .cards__content {
      display: flex;
      justify-content: space-between;
      align-items: end;
      p {
        color: ${({ theme }) => theme.white};
        font-weight: 200;
        font-size: 1rem;
      }
      img {
        border: 4px solid ${({ theme }) => theme.white};
        border-radius: ${({ theme }) =>
        theme.defaultBorderRadius +
        " " +
        0 +
        " " +
        0 +
        " " +
        theme.defaultBorderRadius};
        border-right: none;
      }
    }
  }
`;

export const Community = styled.section`
  background-color: ${({ theme }) => theme.lighterBlue};
  margin: -80px 0 0;
  padding: 180px 90px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  .in-call {
    border: 4px solid ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
  }
  .content {
    max-width: 100%;
    margin-left: 40px;
    width: 500px
  }
  h1 {
    font-size: 2.7rem;
    margin: 0;
    font-weight: 600;
  }
  p {
    font-size: 1.1rem;
    margin: 0;
    font-weight: 200;
    line-height: 2;
  }
  nav {
    margin: 0 0 40px 0;
  }
  ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    padding: 0;
    li {
      font-size: 1.7rem;
      margin-right: 16px;
    }
    li:nth-child(1) {
      a {
        color: #0077b5;
      }
    }
    li:nth-child(2) {
      a {
        color: #738adb;
      }
    }
    li:nth-child(3) {
      img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    li:nth-child(4) {
      a {
        color: #cd201f;
      }
    }
  }
  .enter-discord {
    padding: 10px 80px;
    border: 1px solid ${({ theme }) => theme.black};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    color: ${({ theme }) => theme.black};
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const LetsGo = styled.section`
  background-color: ${({ theme }) => theme.white};
  padding: 90px 0;
  text-align: center;
  font-family: "Poppins", sans-serif;
  h1 {
    font-size: 3rem;
    font-weight: 600;
    margin: 0;
  }
  P {
    margin: 16px 0 36px;
    font-weight: 200;
    font-size: 1.1rem;
  }
`;

export const Footer = styled.footer`
  background-color: rgb(7, 2, 54);
  padding: 60px 0px 5px 60px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    max-width: 230px;
  }
  p {
    color: ${({ theme }) => theme.white};
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    font-size: 0.9rem;
    text-align: center;
  }
`;
