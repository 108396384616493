import { Container } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faGithub } from "@fortawesome/free-brands-svg-icons";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container);

export const CardTeam = ({title,description,linkedin,github,showIcon,...rest}) => {
  
  return (
    <MediaContainer>
      <Container>
          <div className="card_description">
              <h3>{title}</h3>
              <p>{description}</p>
              <div className="card_description_links">
                <a target="_blank" rel="noreferrer" href={linkedin}>
                  <FontAwesomeIcon id="linkedin_icon" icon={faLinkedinIn} />
                </a>
                
                <a target="_blank" rel="noreferrer" href={github} className={showIcon}>
                  <FontAwesomeIcon id="github_icon" icon={faGithub} />
                </a>
              </div>
          </div>
        
      </Container>
    </MediaContainer>
  );
};
