import styled from "styled-components";

export const Container = styled.article`
  section {
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.darkGray};
    padding: 2rem;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    margin: 0 auto;
  }
  h2 {
    font-size: ${({ theme }) => theme.title.fontSize};
    margin: 0 0 1rem;
  }
  p {
    font-size: 1rem;
  }
`;
