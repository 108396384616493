import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Container } from "./style";

// responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container);

export const Modal = ({ isOpen, setModalOpen, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ width: "100%", opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.5, ease: "linear" }}
          style={{ position: "fixed", top: 0, left: 0, zIndex: 20 }}
        >
          <MediaContainer>
            <Container>
              <div className="close" onClick={setModalOpen}></div>
              <div className="modal_content">
                <button
                  type="button"
                  onClick={setModalOpen}
                  className="close-button"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
                <div>{children}</div>
              </div>
            </Container>
          </MediaContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
