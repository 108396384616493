import { SIGN_IN, SET_USER, SIGN_OUT, SET_IS_LOADING } from "./actionsTypes";

const defaultState = {
  token: false,
  userIsLoading: true,
};

export const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SIGN_IN:
      const { token } = action;

      return { ...state, token };

    case SIGN_OUT:
      return defaultState;

    case SET_USER:
      const { userData } = action;

      return { ...state, ...userData };

    case SET_IS_LOADING:
      const { userIsLoading } = action;

      return { ...state, userIsLoading };

    default:
      return state;
  }
};
