// react
import { useState, useEffect } from "react";

// react redux
import { useDispatch } from "react-redux";
import { updateLessonThunk } from "../../store/modules/current_lesson/thunks";

// react dom
import { useParams, useNavigate, Link } from "react-router-dom";

// style
import { Container, ItemContainer } from "./style";
import { motion } from "framer-motion";
import { containerVariants, itemVariants } from "../../styles/global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { BiRightArrow } from "react-icons/bi";

//others
import { takeVideoYoutubeId } from "../../service/util/util";

//responsibility
import { mediaQueries } from "./media";
const MediaContainer = mediaQueries(Container, ItemContainer);

export const CourseNav = ({ classes, ...rest }) => {
  let { courseName, moduleName, lessonIndex } = useParams();
  const [currentLessonIndex, setCurrentLessonIndex] = useState(
    parseInt(lessonIndex)
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateLessonThunk({ courseName, moduleName, lessonIndex }));
    setCurrentLessonIndex(parseInt(lessonIndex));
  }, [setCurrentLessonIndex, courseName, moduleName, lessonIndex, dispatch]);

  return (
    <motion.div variants={containerVariants} initial="hidden" animate="visible">
      <MediaContainer>
        <Container {...rest}>
          <motion.h1 variants={itemVariants}>Conteúdo do curso</motion.h1>
          <nav>
            <ul>
              {classes.map(
                (lesson, index) =>
                  index !== 0 && (
                    <ItemContainer
                      key={index}
                      selected={currentLessonIndex === index}
                      isAvaliable={lesson.isAvailable}
                    >
                      <img
                        src={`https://img.youtube.com/vi/${takeVideoYoutubeId(
                          lesson.video
                        )}/mqdefault.jpg`}
                        alt=""
                        className="thumb-box"
                      ></img>
                      <div className="text-box">
                        <p>{lesson.name}</p>
                        <Link to={`/${courseName}/${moduleName}/${index}`}>
                          <BiRightArrow /> Reproduzir vídeo
                        </Link>
                      </div>
                    </ItemContainer>
                  )
              )}
            </ul>
          </nav>
        </Container>
      </MediaContainer>
    </motion.div>
  );
};
