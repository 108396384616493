import styled from "styled-components";
import { media } from "../../service/util/util";

export const mediaQueries = (
  container,
  introductionSection,
  cardNameStudent,
  cardAboutMe,
  sectionInfo
) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.desktop`
            .profile {
              padding-left: 0px;
              width: 100%;
              ${introductionSection}{
                .cover {
                  left: 0;
                }
                ${cardNameStudent}{
                  padding: 0 0 0 20px;
                }
              }
            }
            .geral-infos {
                flex-direction: column;
                align-items: center;
                ${cardAboutMe}{
                  width: 80%;
                  margin: 0px 0 20px 0px;
                }
                ${sectionInfo}{
                  width: 80%;
                  margin: 0px 0 20px 0px;
                }
            }
        `}
      ${media.tablet`
            ${cardNameStudent}{
                  bottom: 0px;
                  height: 40px;
                  margin: 0 0 10px 0;
                  .card-name__photo {
                    display: none;
                  }
                  .card-name__text {
                    display: none;
                  }
                  .continue-studies {
                    top: 5px;
                    right: 15px;
                    font-size: .8rem;
                  }
            }
        `}
    }
  `;
  return mediaContainer;
};
