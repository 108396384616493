import styled from "styled-components";
import { theme } from "../../styles/global";

export const Container = styled.textarea`
  background-color: ${({ theme }) => theme.white};
  border: none;
  border-radius: ${theme.defaultBorderRadius};
  color: ${({ theme }) => theme.darkBlue};
  padding: 1rem 0 0 2rem;
  margin-bottom: 0.5rem;
  width: calc(100% - 2rem);
  font-family: sans-serif;

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
  }

  &::placeholder {
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
  }
`;
