// style
import { Content } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import { itemVariants } from "../../styles/global";

export const TopMenuItem = ({ children, icon, to, ...rest }) => {
  return (
    <motion.li whileTap={{ scale: 0.97 }} {...rest}>
      <div>
        {!!to ? (
          <Link to={to}>
            <Content>{children}</Content>
          </Link>
        ) : (
          <Content>{children}</Content>
        )}
      </div>
    </motion.li>
  );
};
