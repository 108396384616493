import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;

  label {
    font-weight: bold;
    font-size: 0.8rem;
    color: ${({ theme }) => theme.darkBlue};
  }

  .error {
    color: ${({ theme }) => theme.errorMsg};
    font-size: 0.8rem;
  }

  .btn-eye {
    position: absolute;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.6rem;
    top: 0.3rem;
    z-index: 1;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.gray};
    margin: 0;
    cursor: pointer;
  }
  .input-box {
    margin: 8px 0;
    position: relative;
    border: 1px solid ${({ theme }) => theme.gray};
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
    width: 100%;
    height: 2.5rem;
    background-color: ${({ theme }) => theme.white};
    overflow: hidden;
    &:focus-visible {
      border: 1px solid ${({ theme }) => theme.blue};
      outline: 1px solid ${({ theme }) => theme.blue};
    }
  }
`;

export const InputStyled = styled.input`
  background-color: transparent;
  border: none;
  width: 20rem;
  height: 2.5rem;
  color: ${({ theme }) => theme.darkBlue};
  padding: 0 0 0 16px;

  &:focus-visible {
    border: none;
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }

  &::placeholder {
    color: ${({ theme }) => theme.gray};
    font-size: 1rem;
  }
`;
