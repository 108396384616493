import styled, { keyframes } from "styled-components";
import { media } from "../../service/util/util";
const moveDown = keyframes`
  from {
    transform: translateY(-2rem)
  }

  to {
    transform: translateY(0)
  }
`;

export const mediaQueries = (container, PerformanceContainer) => {
  const mediaContainer = styled.div`
    ${container} {
      ${media.tablet`
            padding:0;
                main{
                    width:90%;
                    padding: 0 1.2rem;
                }
                h1{
                    font-size:2rem;
                }
                ${PerformanceContainer}{
                    animation: ${moveDown} 1s linear;
                    width:100%;
                    padding:0;
                    margin-top:1rem;
                }
                ul{
                    padding:0.4rem;
                    section{
                        width:15rem;
                    }
                }
                
                
                .module-nav__top {
                    width:100%;
                    height:0.8rem;
                    font-size:1.5rem;
                    padding:0 0.5rem;
                    
                    
                    h2{
                        font-size:1.1rem;
                    }
                    button{
                        font-size:1.5rem;
                        margin-right:0.5rem;
                        
                    }
                }
                .progress{
                    padding:0.2rem;
                    justify-content:center;
                    
                }
                .progress__current-module{
                    height:0.8rem;
                    font-size:1.5rem;
                    margin:0 0.5rem;
                    width:2.5rem;
                    
                }

            `}

      ${media.mobile`
                padding:0;
                main{
                    width:90%;
                    padding: 0 1.2rem;
                }
                h1{
                    font-size:1.8rem;
                }
                ${PerformanceContainer}{
                    animation: ${moveDown} 1s linear;
                    width:100%;
                    padding:0;
                    margin-top:1rem;
                }
                ul{
                    padding:0.4rem;
                    section{
                        width:15rem;
                    }
                }
                
                
                .module-nav__top {
                    width:100%;
                    height:0.8rem;
                    font-size:1.5rem;
                    padding:0 0.5rem;
                    
                    
                    h2{
                        font-size:1.1rem;
                    }
                    button{
                        font-size:1.5rem;
                        margin-right:0.5rem;
                        
                    }
                }
                .progress{
                    padding:0.2rem;
                    justify-content:space-between;
                    
                }
                .progress__current-module{
                    height:0.8rem;
                    font-size:1.5rem;
                    margin:0 0.5rem;
                    width:2.5rem;
                    
                }
            
       `}
    }
  `;
  return mediaContainer;
};
