// component
import { Header } from "../../components/header";
import { BackButton } from "../../components/back_button";

// style
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Container, ProfileHeader, ProfileContent } from "./style";
import { motion } from "framer-motion";
import { FormPassword } from "../../components/form_password";
import { FormEmail } from "../../components/form_email";
import { FormPhone } from "../../components/form_phone";

//responsibility
import { mediaQueries } from "./media";
import { useSelector } from "react-redux";
import { MenuTop } from "../../components/menu_top";
const MediaContainer = mediaQueries(Container, ProfileHeader, ProfileContent);

export const Profile = ({ ...rest }) => {
  const user = useSelector((state) => state.user);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
        <Container>
          <Header>
            <MenuTop />
          </Header>
          <main>
            <ProfileHeader>
              <div className="profile__circle">
                <FontAwesomeIcon icon={faCircleUser} />
              </div>
              <div className="profile__header__name-bond">
                <h1>{user.name}</h1>
                <span className="profile__header__bond">Aluno</span>
              </div>
              <div className="profile__header__infs-box">
                <div className="profile__header__infs">
                  <div className="infs__email">
                    <p className="infs__field-name">E-mail: </p>
                    <p> {user.email}</p>
                  </div>
                  <div className="infs__number">
                    <p className="infs__field-name">Número: </p>
                    <p> {user.phone}</p>
                  </div>
                </div>
              </div>
            </ProfileHeader>

            <h2 className="main_title">Configuração da conta</h2>
            <ProfileContent>
              <h2 className="content_title">
                Preencha os dados que deseja alterar
              </h2>
              <div className="content_grid">
                <FormPassword></FormPassword>
                <FormEmail></FormEmail>
                <FormPhone></FormPhone>
              </div>
            </ProfileContent>
          </main>
        </Container>
    </motion.div>
  );
};
