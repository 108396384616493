

// components
import { Header } from "../../components/header";
import { BackButton } from "../../components/back_button";
import { Download } from "../../components/download";

// style

import { Container } from "./style";
import { motion } from "framer-motion";






export const PagDownloads = ({ ...rest }) => {



  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
      {...rest}
    >
        
      <Container>
        <Header hasPerfil>
          <BackButton />
        </Header>
        <main>
          <h1 className="main__title">Downloads</h1>
          <Download
            title='Curso FrontEnd'
            urlToDownload='https://drive.google.com/uc?export=download&id=1Hn6TixwdKxZJ-FMt93BLI8pOwEnzM_lM'
          />
            
          
        </main>
      </Container>
      
    </motion.div>
  );
};
