// style
import { Container, InputStyled } from "./style";
import { itemVariants } from "../../styles/global";
import { motion } from "framer-motion";

// components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

export const SelectInput = ({
  name,
  title,
  placeholder,
  options,
  register,
  errors,
  onChange,
  value,
  ...rest
}) => {
  return (
    <motion.div variants={itemVariants} {...rest}>
      <Container errorsName={errors[name]}>
        <label for="select">{title}</label>
        <InputStyled
          id="select"
          name={name}
          value={value}
          onChange={onChange}
          {...register(name)}
        >
          <option value="" disabled selected>
            {placeholder}
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </InputStyled>
        <div className="arrow">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <span className="error">{errors[name]?.message}</span>
      </Container>
    </motion.div>
  );
};
