import styled from "styled-components";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.darkBlue};
  padding: 0 2.5vw;
  header {
    padding: 0;
  }
  .header__back-button {
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      background-color: ${({ theme }) => theme.blue};
      color: ${({ theme }) => theme.white};
      &:hover {
        background-color: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.blue};
      }
    }
  }
  main {
    padding: 0;
    display: flex;
    justify-content: space-between;
  }
  .lesson {
    width: 640px;
  }
  .video-container__header {
    display: flex;
    justify-content: space-between;
  }
  h1 {
    font-size: ${({ theme }) => theme.title.fontSize};
    color: ${({ theme }) => theme.white};
    margin: 0.5rem 0 0.5rem;
  }
  .lesson__download-button-stars-box {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 6rem;
  }
  .lesson__grade {
    color: ${({ theme }) => theme.white};
  }
  .lesson__nav-mobile-buttons {
    display: none;
  }
  .lesson__video-info-box {
    display: flex;
    justify-content: space-between;
    width: 768px;
    padding: 8px 0;
    button {
      width: 100%;
    }
  }
  .lesson__intro {
    color: ${({ theme }) => theme.white};
    max-width: 590px;
    margin: 0;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .lesson__material-link {
    color: ${({ theme }) => theme.white};
  }
  .lesson__move {
    display: none;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0 3rem;
  }
  .pag-lesson__course-nav {
    margin: 3rem 0 0 0.5rem;
  }
`;
