export const matematicaDiscretaAulas = [
  {
    name: "",
    video: "",
    introduction: "",
    Content: () => {
      return <></>;
    },
  },
  {
    name: "Lógica Proposicional - parte 1",
    video: "https://youtu.be/pg7x4PHbbY4",
    introduction: "Introduzindo lógica proposicional com o professor Caio",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Livro citado</h2>
            <p>
              <a
                href="https://www.amazon.com.br/Introdu%C3%A7%C3%A3o-%C3%A0-l%C3%B3gica-Cezar-Mortari/dp/8539306301"
                target="_blank"
                rel="noreferrer"
              >
                Introdução à lógica - 2ª edição
              </a>
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Lógica Proposicional - parte 2",
    video: "https://youtu.be/bEsuc2QAeSk",
    introduction: "Introduzindo lógica proposicional com o professor Caio",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Livro citado</h2>
            <p>
              <a
                href="https://www.amazon.com.br/Introdu%C3%A7%C3%A3o-%C3%A0-l%C3%B3gica-Cezar-Mortari/dp/8539306301"
                target="_blank"
                rel="noreferrer"
              >
                Introdução à lógica - 2ª edição
              </a>
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Lógica Proposicional - parte 3",
    video: "https://youtu.be/ubfCEvPi9WQ",
    introduction: "Introduzindo lógica proposicional com o professor Caio",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Livro citado</h2>
            <p>
              <a
                href="https://www.amazon.com.br/Introdu%C3%A7%C3%A3o-%C3%A0-l%C3%B3gica-Cezar-Mortari/dp/8539306301"
                target="_blank"
                rel="noreferrer"
              >
                Introdução à lógica - 2ª edição
              </a>
            </p>
          </section>
        </>
      );
    },
  },
  {
    name: "Lógica Proposicional - Implicação material",
    video: "https://youtu.be/RDdVBtUCxio",
    introduction: "Introduzindo lógica proposicional com o professor Caio",
    isAvailable: true,
    Content: () => {
      return (
        <>
          <section>
            <h2>Livro citado</h2>
            <p>
              <a
                href="https://www.amazon.com.br/Introdu%C3%A7%C3%A3o-%C3%A0-l%C3%B3gica-Cezar-Mortari/dp/8539306301"
                target="_blank"
                rel="noreferrer"
              >
                Introdução à lógica - 2ª edição
              </a>
            </p>
          </section>
        </>
      );
    },
  },
];
