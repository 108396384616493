// import api from "../../../service/api";
import { signIn, setUser, signOut, setUserIsLoading } from "./actions";
import {
  loginWithEmail,
  getUserData,
  logout,
} from "../../../service/userManagement";
import { toast } from "react-toastify";

export const signInThunk = (userData) => (dispatch) => {
  loginWithEmail(userData.email, userData.password)
    .then((userCredential) => {
      toast("Você entrou", { theme: "dark" });
      dispatch(signIn(userCredential.user.accessToken));
    })
    .catch((error) => {
      console.log(error);
      toast("Usuário e/ou senha errado(s)", { theme: "dark" });
    });
};

export const signOutThunk = () => (dispatch) => {
  localStorage.removeItem("actualModule");
  localStorage.removeItem("actualCourse");
  localStorage.removeItem("actualLesson");
  localStorage.removeItem("studentActivity");

  logout()
    .then(() => {
      dispatch(signOut());
      const userIsLoading = false;
      dispatch(setUserIsLoading({ userIsLoading }));
      toast("Deslogado", { theme: "dark" });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const setUserThunk = () => (dispatch) => {
  getUserData()
    .then((response) => {
      dispatch(setUser(response));
    })
    .catch((error) => {
      console.log(error);
    });
};
