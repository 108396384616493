import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95vw;
  max-width: 450px;
  button {
    background: #74e4e5;
    border-radius: ${({ theme }) => theme.defaultBorderRadius};
  }
  .button-comment {
    button {
      background-color: ${({ theme }) => theme.darkGray};
      color: ${({ theme }) => theme.white};
    }
  }
`;
